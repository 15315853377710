import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Banner,
    Button,
    FormLayout,
    InlineError,
    TextField,
    TextStyle,
    Loading,
    Modal,
    Form,
    Stack
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from 'ApiUrl';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
          color: "#DE3618"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
      },
    focused: {},
    notchedOutline: {}
}));

export default function Loginwithpasswordcomponent() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#DE3618",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#DE3618"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                },
              },
            },
          },

    });

    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [email, setEmail] = useState('');
    const [errormail, setErrorMail] = useState('');
    const [errorpassword, setErrorPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const [modalActive, setModalActive] = useState(false);
    const [supportEmail, setSupportEmail] = useState('');
    const [forgotpasswordRes, setForgotpasswordRes] = useState('');
    const handleEmailChange = useCallback((value) => setEmail(value), []);
    const [errorsupportemail, setErrorsupportemail] = useState('');


    const handleMessageChange = useCallback(
        (value) => setSupportEmail(value),
        [],
    );

    const closeBanner = () => {
        setError('');
        setForgotpasswordRes('');
    }

    const errorSupportemail = errorsupportemail
        ? <TextStyle variation="negative">{errorsupportemail}</TextStyle>
        : '';

    const ForgotpasswordRes = forgotpasswordRes
        ? <Banner status="success" onDismiss={closeBanner}>{forgotpasswordRes}</Banner>
        : '';

    const errorEmail = errormail
        ? <TextStyle variation="negative">{errormail}</TextStyle>
        : '';

    const errorPassword = errorpassword
        ? <TextStyle variation="negative">{errorpassword}</TextStyle>
        : '';



    const errorDiv = error
        ? <Banner status="critical" onDismiss={closeBanner}><p>{error}</p></Banner>
        : '';

    const forgetPwd = useCallback(
        () => setModalActive((modalActive) => !modalActive),
        [],
    );

    interface State {
        password: string;
        showPassword: boolean;
    }

    const [password, setPass] = React.useState<State>({
        password: '',
        showPassword: false,
    });
    console.log("Password", password);

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPass({ ...password, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setPass({ ...password, showPassword: !password.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const loadingMarkup = isLoading ? <Loading /> : null;

    const body = JSON.stringify({
        email_or_username: email,
        password: password.password
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    //handle button click of login form Tab-1
    const handleLogin = () => {
        if (email === '' && password.password === "") {
            console.log("")
            setErrorMail("Please enter email or phone number");
            setErrorPassword("Please enter password");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email)) === false) {
            setErrorMail("Please enter valid email id or phone number");
        }
        else if (email === '' && password.password != "") {
            setErrorMail("Please enter email or phone number");
            setErrorPassword("");
            return;
        }
        else if (email != '' && password.password === "") {
            setErrorMail('');
            setErrorPassword("Please enter password");
            return;
        }
        else {
            setIsLoading(true);
            setErrorPassword('');
            setErrorMail('');
            axios.post(baseUrl.baseUrl + '/doctorapp/email-password-auth-login',
                body, { headers })
                .then(result => {
                    setIsLoading(false);
                    localStorage.setItem("token", result.data.token);
                    localStorage.setItem("email", result.data.user.email);
                    localStorage.setItem("name", result.data.user.username);
                    localStorage.setItem("firstname", result.data.user.first_name);
                    localStorage.setItem("lastname", result.data.user.last_name);
                    localStorage.setItem("id", result.data.user.id);
                    localStorage.setItem("onBoarding", result.data.onboardingstatus);
                    localStorage.setItem("onboardingrequestId", result.data.onboardingrequestId);
                    localStorage.setItem("onboardingrequestStatus", result.data.onboardingstatus);
                    localStorage.setItem("doctorID", result.data.doctorId);
                    if (result.data.onboardingstatus === 'incomplete') {
                        history.push("/userprofile");
                        window.location.reload();
                    }
                    else {
                        history.push("/dashboard");
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message || error.response.data.non_field_errors[0]);
                    setIsLoading(false);
                })
        }
    }
    const forgetPasswordApicall = () => {
        setIsLoading(true);
        if (supportEmail === "") {
            setErrorsupportemail("Please enter email id or phone number");
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(supportEmail)) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(supportEmail)) === false) {
            setErrorsupportemail("Please enter valid email id or phone number");
        }
        else {
            console.log("FORGOT_PADDWORD_API_CALL")
            axios.get(baseUrl.baseUrl + '/profileapp/forgot-password/' + supportEmail + '/', {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            })
                .then((res) => {
                    setErrorsupportemail('');
                    setForgotpasswordRes(res.data.message);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setForgotpasswordRes(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }

    const forgetPwdddd = () => {
        setSupportEmail('');
        setForgotpasswordRes('');
        setModalActive((modalActive) => !modalActive)
    }
    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={forgetPwdddd}
            title="Forgot Password"
            footer={ForgotpasswordRes}
            primaryAction={{
                content: 'Send',
                onAction: forgetPasswordApicall,
            }}
        >
            <Modal.Section>
                <FormLayout>
                    {loadingMarkup}

                    <TextField
                        label="Email"
                        value={supportEmail}
                        onChange={handleMessageChange}
                    />
                    <InlineError message={errorSupportemail} fieldID="supportemail" />
                </FormLayout>
            </Modal.Section>
        </Modal>
    );

    const actualPageMarkup = (
        <FormLayout>
            {loadingMarkup}
            <Form onSubmit={handleLogin}>
                <FormLayout>
                    <TextField
                        value={email}
                        label="Email or Phone Number"
                        placeholder="Enter your email or phone number"
                        id="emailFieldID"
                        onChange={handleEmailChange}
                    />
                    <InlineError message={errorEmail} fieldID="emailFieldID" />
                    <Stack>
                        <Stack.Item fill>
                            <label>Password</label>
                        </Stack.Item>
                        <Stack.Item>
                            <Button plain onClick={forgetPwd}>Forgot Password?</Button>
                        </Stack.Item>
                    </Stack>

                    <ThemeProvider theme={theme}>
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            placeholder="Enter your password"
                            type={password.showPassword ? 'text' : 'password'}
                            value={password.password}
                            onChange={handleChange('password')}
                            fullWidth
                            classes={outlinedInputClasses}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                        />
                    </ThemeProvider>
                    <InlineError message={errorPassword} fieldID="passwordFieldID" />
                    <Button fullWidth primary submit>Log In</Button>
                    <div>{errorDiv}</div>
                </FormLayout>
            </Form>
        </FormLayout>
    );
    return (
        <div>
            {actualPageMarkup}
            {modalMarkup}
        </div>

    );
}