import React from 'react';
import {Card} from '@shopify/polaris';

export default function HelpCenter() {
    return (
        <div>
            <Card title="Security and Personal Data">
                <Card.Section title="Is my data shared with third parties?">
                    <p>AIMS2Health does not share your data with anyone unless you explicitly tell us to do so. You can review our Privacy Policy here.</p>
                </Card.Section>
                <Card.Section subdued title="Can I have multiple family members on one account?">
                    <p>Yes! Simply begin another symptom assessment. The first question AIMS2Health will ask is who the assessment is for and you can add further members by tapping ‘Someone else’.</p>
                </Card.Section>
            </Card>
            <Card title="My AIMS2Health Account">
                <Card.Section title="What happens if I forget my password or want to change it?">
                    <p>Next to the password entry field is a button that says ‘I forgot my password’. Tapping this sends a password reset email to the same address that you used to create your AIMS2Health account! If you change your mind you can safely ignore the email and your password won’t be reset.</p>
                </Card.Section>
                <Card.Section subdued title="How do I delete my account?">
                    <p>You can find the delete account button inside the 'Settings' section of AIMS2Health's main menu. Deleting your account erases all of your personal data from our servers permanently.</p>
                </Card.Section>
            </Card>
        </div>

    );
}
