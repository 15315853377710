import React, { useState, useCallback } from 'react';
import {
    Page,
    Layout,
    Tabs,
    Card,
} from '@shopify/polaris';
import Telehealthcredentials from './Telehealthcredentials';
import TelehealthMeeting from './TelehealthMeeting';

export default function DoctorTeleHealth() {

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );
    const tabs = [
        {
            id: 'telehealth-meeting',
            content: 'Telehealth Meeting',
            body:(
                <TelehealthMeeting />
            ),
            panelID: 'telehealth-meeting'
        },
        {
            id: 'telehealth-credentials',
            content: 'Telehealth Credentials',
            body:(
                <Telehealthcredentials />
            ),
            panelID: 'telehealth-credentials'
        },
    ];
    const actualPageMarkup = (
        <Layout>
            <Layout.Section>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Card.Section title={tabs[selected].body}>
                    </Card.Section>
                </Tabs>
            </Layout.Section>
        </Layout>
    )

    return (
        <Page fullWidth>
            {actualPageMarkup}
        </Page>
    );
}