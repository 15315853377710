import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    Stack,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    Thumbnail,
    Button,
    Loading,
    Page,
    Scrollable,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    ExceptionList
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, SelectPicker, Uploader } from 'rsuite';

import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});
initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function DoctorEducationalDetails() {
    const [uploading] = React.useState(false);
    const [awardName, setAwardname] = useState('');
    const [open, setOpen] = useState(false);
    console.log(open);
    const handleAwardChange = useCallback((value) => setAwardname(value), [],);
    const [imageInfo, setImageInfo] = useState('');
    const [imageID, setImageID] = useState('');
    const [selected, setSelected] = useState("");
    const [awardTypeValues, setAwardTypeValues] = useState([]);
    const handleSelectChange = useCallback((value) => {
        setSelected(value)
        // selected ? '': setSelectedError('')
    }, []);

    const [editAwardId, setEditAwardId] = useState('');

    const [modalActive, setModalActive] = useState(false);
    const handleToggle = () => {
        setSelectedError("");
        setEditAwardId("");
        setModalActive((modalActive) => !modalActive);
        setSelected("");
        setImageInfo("");
        setAwardname("");
        setSelectedDate(new Date(),);
        setAddAwardSuccess("");
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);


    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [selectedError, setSelectedError] = useState('');
    const selectedErrordiv = selectedError
        ? <InlineError message={selectedError} fieldID="awardId" />
        : '';

    const closeerror = () => {
        setwarddError('')
    }

    const [awardError, setwarddError] = useState('');
    const awardErrorDiv = awardError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{awardError}</p>
        </Banner>
        : '';

    const [addAwardSuccess, setAddAwardSuccess] = useState('');

    const closeSuccess = () => {
        setAddAwardSuccess('');
    }
    const successDiv = addAwardSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addAwardSuccess}</p>
        </Banner>
        : '';


    function editProfessionalDetails(response: any) {
        setOpen(true);
        setSelectedError("");
        setModalActive((modalActive) => !modalActive);
        setAddAwardSuccess("");
        setwarddError("");
        setSelected((response.type == null) ? '' : response.type.value);
        setEditAwardId(response.id);
        let docURL = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.document : 'document.png';
        let imgId = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.id : '';
        console.log("DOC URL = ", docURL);
        setImageInfo(docURL);
        setImageID(imgId);
        setAwardname(response.description);
        setSelectedDate(response.fromDate);
    }

    const [awardListValue, setAwardListValue] = useState<any[]>([]);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const uploadbody = {
        'typeName': 'award-supporting-docs'
    };

    // used to get doctor personal details
    const addAwardBody = {
        awardtypeId: selected,
        description: awardName,
        fromDate: selectedDate,
        supportdocId: imageID

    }

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const getAwardList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_awards_list_by_doctor', { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.awardList;
                let awardListConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    console.log(selectedResponse);
                    let awardType = (resData[i].type === null) ? "" : resData[i].type.label;
                    console.log("Award Name " + awardType);
                    let date = (resData[i].fromDate === null) ? "" : (new Date(resData[i].fromDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    console.log("Award Date " + date);
                    let awardName = (resData[i].description === null || resData[i].description === "") ? "" : truncate(resData[i].description);
                    console.log("desc==> ", awardName);
                    let supportingDocuments = (resData[i].supportingDocuments.length > 0) ? resData[i].supportingDocuments[0].userFile.document : '';
                    console.log("supportingDocuments==> ", supportingDocuments);
                    awardListConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        awardType,
                        awardName,
                        date,
                        <Button icon={EditMinor} size="slim" onClick={() => editProfessionalDetails(selectedResponse)}></Button>
                    ]);
                    console.log("Award List = ", awardListConfig);
                }
                setAwardListValue(awardListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    const getAwardTypesApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/award_type_list',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setAwardTypeValues(res.data.awardTypeList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
                setwarddError(error.data.message);
            })
    }

    const awardsubmit = () => {
        console.log(addAwardBody);
        if (selected === '' || selected === null) {
            setSelectedError("Please choose an award");
        }
        else {
            let url, newAwardBody;
            if (editAwardId) {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_awards';
                newAwardBody = Object.assign(addAwardBody, { awardId: editAwardId })
            }
            else {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/add_awards';

            }
            console.log(newAwardBody);
            axios.post(url, JSON.stringify(addAwardBody), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setSelectedError('');
                    setAddAwardSuccess(result.data.message);
                    getAwardList();
                }
            }).catch((error) => {
                setIsLoading(false);
                setwarddError(error.data.message);
                console.log("error record====" + error);
            });
        }
    }

    useEffect(() => {
        getAwardTypesApi();
        getAwardList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const getAwardtableList = (
        <Card.Section>
            { awardListValue && awardListValue.length ? (
                <Scrollable shadow focusable>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Document',
                            'Award Type',
                            'Award Name',
                            'Presented Date',
                            'Edit'
                        ]}
                        rows={awardListValue}
                    />
                </Scrollable>
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Award Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const styles = {
        width: 65,
        height: 65,
        borderRadius: 30
    };

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Award Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const actualPageMarkup = (
        <Modal
            large
            open={modalActive}
            onClose={handleToggle}
            title={editAwardId ? "Edit Award Details" : "Add Award Details"}
            primaryAction={{
                content: 'Submit',
                onAction: awardsubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <Box>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Award Type</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectChange}
                                        data={awardTypeValues}
                                        value={selected}
                                        id="awardId"
                                        menuStyle={{ color: "rgb(76, 2, 2) !important", zIndex: 1000 }}
                                    />
                                    <div>{selectedErrordiv}</div>
                                    <br />
                                </div>
                                <TextField
                                    value={awardName}
                                    onChange={handleAwardChange}
                                    label="Award Name"
                                    type="text"
                                    placeholder="Please enter award name"
                                    id="awardDescription"
                                />
                            </Box>
                            <Box>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={2}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageID(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </Box>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Presented Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{awardErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    )

    const doctorAwardInfo = (
        <div>
            <Card>
                <Card.Header title="Award Details">
                    <Button primary={true} onClick={handleToggle}>Add Award Details</Button>
                </Card.Header>
                {getAwardtableList}
            </Card>
            {actualPageMarkup}
        </div >
    )

    return (
        <Page>
            {loadingMarkup}
            {doctorAwardInfo}
            {docViewerComponent}
        </Page>
    );
}