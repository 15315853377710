import React, { useState, useEffect } from 'react';
import {
    Page,
    ResourceList,
    ResourceItem,
    Loading,
    MediaCard,
} from '@shopify/polaris';
import axios from 'axios';


export default function DoctorList() {
    const [isLoading, setIsLoading] = useState(false);
    const [doctorList, setDoctorList] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        let token = localStorage.getItem("token");
        axios.get('./doctors.json', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                let values = res.data.results;
                setDoctorList(values);
                setIsLoading(false);

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    }, []);

    const loadingMarkup = isLoading ? <Loading /> : null;
    

    return (
        <Page title="Doctor's list" fullWidth>
            {loadingMarkup}

            <ResourceList
                items={doctorList}
                renderItem={(item) => {
                    const { id, url, name, description, img } = item;

                    return (
                        <div style={{ display: 'inline-flex', position: 'relative', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', width: '488px', margin: '8px'}}>
                            <ResourceItem
                                id={id}
                                url={url}
                                accessibilityLabel={`View details for ${name}`}
                            >
                            </ResourceItem>

                            <MediaCard
                                    title={name}
                                    // primaryAction={{
                                    //     content: 'Learn about getting started',
                                    //     onAction: () => { },
                                    // }}

                                    description={description}
                                    popoverActions={[{ content: 'Dismiss', onAction: () => { } }]}

                                >
                                    <img
                                        alt=""
                                        width="150"
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            padding: '10px'
                                        }}
                                        src={img}
                                    />
                                </MediaCard>
                        </div>
                    );
                }}
            />
        </Page>
    )
}
