import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    Page,
    Button,
    Loading,
    Banner,
    TextStyle,
    DataTable,
    Modal,
    TextContainer,
    Toast,
    ExceptionList
} from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { SelectPicker } from 'rsuite';

export default function Agreement() {

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [docInfo, setDocInfo] = useState('');


    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [successModalactive, setSuccessModalActive] = useState(false);
    const toggleSuccessModalActive = useCallback(() => setSuccessModalActive((successModalactive) => !successModalactive), []);
    const toastSuccessMarkup = successModalactive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleSuccessModalActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [errorModalactive, setErrorModalActive] = useState(false);
    const toggleErrorModalActive = useCallback(() => setErrorModalActive((errorModalactive) => !errorModalactive), []);
    const toastErrorMarkup = errorModalactive ? (
        <Toast content={toastErrorMessage} error onDismiss={toggleErrorModalActive} />
    ) : null;

    const [addModalActive, setAddModalActive] = useState(false);

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const onboardingRequestId = localStorage.getItem("onboardingrequestId");
    const userToken = localStorage.getItem("token");

    const [selected, setSelected] = useState("");
    const handleSelectChange = useCallback((value) => setSelected(value), []);
    const [agreementTypeValues, setAgreementTypeValues] = useState<any[]>([]);

    const handleToggle = () => {
        setAddModalActive((addModalActive) => !addModalActive);
        setGenerateAgreementSuccess("");
        setError("");
    }

    const closeerror = () => {
        setError('')
    }

    const [generateAgreementSuccess, setGenerateAgreementSuccess] = useState('');
    const closeSuccess = () => {
        setGenerateAgreementSuccess('');
    }
    const successDiv = generateAgreementSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{generateAgreementSuccess}</p>
        </Banner>
        : '';

    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const body = JSON.stringify({
        onboardReqId: onboardingRequestId,
        templateId: selected
    });

    const getAwardTypesApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/agreement', { headers })
            .then((res) => {
                setIsLoading(false);
                console.log("===Award Types List===");
                let response = res.data.results;
                console.log(response)
                let professionList = [];
                for (var i = 0; i < response.length; i++) {
                    professionList.push({
                        "value": response[i].id,
                        "label": response[i].name,
                    });
                }
                setAgreementTypeValues(professionList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
                setError(error.data.message);
            })
    }

    const [agreementValueList, setAgreementValueValues] = useState<any[]>([]);
    const getAgreementList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/agreement/list_agreementtemplate', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.agreementDetails;
                let agreementValueList = [];
                for (var i = 0; i < response.length; i++) {
                    let educationName = response[i].template.name;
                    let agreementFile = '';
                    (response[i].agreementFile) ? agreementFile = response[i].agreementFile.document : agreementFile = 'document.png';
                    agreementValueList.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setDocInfo(agreementFile);
                        }}></Button>,
                        <TextStyle variation="strong" key="agreement-name">{educationName}</TextStyle>,
                    ]);

                }
                setAgreementValueValues(agreementValueList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    // Agreement Submit API
    const agreementSubmit = () => {
        console.log(body);

        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/generate-agreement/',
            body, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((response) => {
                setIsLoading(false);
                setGenerateAgreementSuccess(response.data.message);
                getAgreementList();
            })
            .catch((error) => {
                setIsLoading(false);
                setError('');
                console.log("error record====" + error);
            })
    }

    const signAgreementBody = {
        onboardReqId: onboardingRequestId,
    }
    // Sign Agreement API
    const signAgreementSubmit = () => {
        console.log(signAgreementBody);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/sign/sign_agreement',
            signAgreementBody, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((response) => {
                setIsLoading(false);
                setToastSuccessMessage(response.data.message);
                toggleSuccessModalActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setToastErrorMessage(error.response.data.message);
                toggleErrorModalActive();
            })
    }

    useEffect(() => {
        getAwardTypesApi();
        getAgreementList();
    }, []);

    const doctorAgreementListView = (
        <Card.Section>
            {agreementValueList && agreementValueList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                    ]}
                    headings={[
                        'Document',
                        'Agreement Name',
                    ]}
                    rows={agreementValueList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Agreement Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={docInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const actualPageMarkup = (
        <div>
            <Card>
                <Card.Header
                    title="Agreement List"
                >
                    <Button primary={true} onClick={handleToggle}>Add Agreement Details</Button>
                    <Button primary={false} onClick={signAgreementSubmit}>Sign Agreement</Button>
                </Card.Header>
                {doctorAgreementListView}
            </Card>
            <Modal
                open={addModalActive}
                onClose={handleToggle}
                title="Add Agreement"
                primaryAction={{
                    content: 'Generate Agreement',
                    onAction: agreementSubmit,
                }}
            >
                <Modal.Section>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Select Agreement Type</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectChange}
                                    data={agreementTypeValues}
                                    value={selected}
                                    menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                                    id="awardId"
                                />
                            </div>
                            {/* <div>&nbsp;</div> */}
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                    </FormLayout>
                </Modal.Section>
            </Modal>
        </div>
    )

    return (
        <Page >
            {loadingMarkup}
            {actualPageMarkup}
            {docViewerComponent}
            {toastSuccessMarkup}
            {toastErrorMarkup}
        </Page>

    );
}