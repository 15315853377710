import React, { useEffect } from 'react';
import {
    Loading,
    Card,
    Button,
    DataTable,
    ExceptionList,
    Page,
} from '@shopify/polaris';
import { NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import { getDoctorAppointmentList } from 'redux/effects/ApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store/Store';
import { useHistory } from 'react-router-dom';

export default function DoctorAppointment() {
    const loadingMarkup = <Loading />;
    const dispatch = useDispatch();

    const selectionTypeBody = {
        "appointmentStatus": ""
    };

    const history = useHistory();
    const gotoPatientCaseDetails = (index:any, patientId:any, caseid:any ) => {
        history.push(`/appointmentdetails/${patientId}/${caseid}/${index}`);
    }

    // call Store
    const allAppointmentValues = useSelector((state: AppState) => state.appointmentValue);
    console.log("ALLCASELISTREDUX==>", allAppointmentValues);
    const allAppointmentListValues = allAppointmentValues.appointmentValue;

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 30) + "..." : str;
    }
    // Load Appointment table data from store value
    let allAppointmentListConfig: any = [];
    for (var i = 0; i < allAppointmentListValues.length; i++) {
        let index: number = i;
        let caseid = (allAppointmentListValues[i].caseId == null) ? "" : allAppointmentListValues[i].caseId;
        console.log("AppointmentCaseID====",caseid);
        let patientId = (allAppointmentListValues[i].patientId == null) ? "" : allAppointmentListValues[i].patientId;
        let name = (allAppointmentListValues[i].name == "" || allAppointmentListValues[i].name == null) ? "No Details Available" : truncate(allAppointmentListValues[i].name);
        let patientName = (allAppointmentListValues[i].patientName == "" || allAppointmentListValues[i].patientName == null) ? "No Details Available" : allAppointmentListValues[i].patientName;
        let createdDate = (allAppointmentListValues[i].createDate == null) ? "" : (new Date(allAppointmentListValues[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let endDate = (allAppointmentListValues[i].end == null) ? "" : (new Date(allAppointmentListValues[i].end)).toLocaleDateString('en-US', DATE_OPTIONS);
        let modifiedDate = (allAppointmentListValues[i].modifiedDate == null) ? "" : (new Date(allAppointmentListValues[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let appoinmentStatus = (allAppointmentListValues[i].hasOwnProperty('status')) ? (allAppointmentListValues[i].status === null || allAppointmentListValues[i].status === "") ? "" : allAppointmentListValues[i].status : "";
        allAppointmentListConfig.push([
            <Button primary={false} onClick={()=> gotoPatientCaseDetails(index, patientId, caseid )}>View</Button>,
            name,
            patientName,
            createdDate,
            endDate,
            modifiedDate,
            appoinmentStatus
        ]);
    }
    const appointmenttableValue: any[] = allAppointmentListConfig;
    console.log("AllAppointmentListArray", appointmenttableValue);

    const reloadAppointList = () => {
        dispatch(getDoctorAppointmentList(selectionTypeBody));
    }

    useEffect(() => {
        dispatch(getDoctorAppointmentList(selectionTypeBody));
    }, [dispatch]);


    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Existing Appointment Details">
                    <Button icon={RefreshMinor} primary={false} onClick={reloadAppointList}>Reload Appointment List</Button>
                </Card.Header>
                <Card.Section>
                    {appointmenttableValue && appointmenttableValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Patient Case Details',
                                'Appointment Name',
                                'Patient Name',
                                'Create Date',
                                'End Date',
                                'Modified Date',
                                'Status',
                            ]}
                            rows={appointmenttableValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Appointment Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    return (
        <Page fullWidth>
            { allAppointmentValues.loading ? loadingMarkup : ''}
            {appointmentTableBody}
        </Page>
    );
}