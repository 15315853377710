import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Stack,
    FormLayout,
    Banner,
    Loading,
    Page,
    Scrollable,
    Layout,
    Heading,
    ExceptionList,
    Button,
    DataTable,
    Modal,
    TextContainer,
    Subheading,
    Toast
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiUrl'
import { Box } from '@primer/components';
import { NoteMinor, RefreshMinor, DeleteMinor, MobileCancelMajor, MobileAcceptMajor } from '@shopify/polaris-icons';
import { Redirect, useParams } from 'react-router-dom';


export default function AddDoctorTeam() {
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [toSignin, setToSignin] = useState(false);
    const [selectedDoctorId, setSelectedDoctorId] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [modalActive, setModalActive] = useState(false);
    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
    }
    function deleteDoctor(doctorId: any, doctorName: any) {
        setModalActive((modalActive) => !modalActive);
        setDoctorName(doctorName);
        setSelectedDoctorId(doctorId);
    }
    const closeDoctorDeleteSuccess = () => {
        setDoctorDeleteSuccess('');
        setDoctorDeleteError('');
    }
    const [doctorDeleteSuccess, setDoctorDeleteSuccess] = useState('');
    const deleteDoctorsuccessDiv = doctorDeleteSuccess
        ? <Banner
            onDismiss={closeDoctorDeleteSuccess}
            status="success">
            <p>{doctorDeleteSuccess}</p>
        </Banner>
        : '';

    const [doctorDeleteError, setDoctorDeleteError] = useState('');
    const deleteDoctorErrorDiv = doctorDeleteError
        ? <Banner
            onDismiss={closeDoctorDeleteSuccess}
            status="critical">
            <p>{doctorDeleteError}</p>
        </Banner>
        : '';
    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [successToastactive, setSuccessToastactive] = useState(false);
    const successToasttoggleActive = useCallback(() => setSuccessToastactive((successToastactive) => !successToastactive), []);
    const successToastMarkup = successToastactive ? (
        <Toast content={successToastMessage} onDismiss={successToasttoggleActive} />
    ) : null;

    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [errorToastactive, setErrorToastactive] = useState(false);
    const errorToasttoggleActive = useCallback(() => setErrorToastactive((errorToastactive) => !errorToastactive), []);
    const errorToastMarkup = errorToastactive ? (
        <Toast content={errorToastMessage} error onDismiss={errorToasttoggleActive} />
    ) : null;

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const [doctorTeamType, setDoctorTeamType] = useState('');
    const selectionTypeBody = {
        "caseId": caseid
    };
    const [selectedDoctorListValue, setSelectedDoctorListValue] = useState<any[]>([]);
    console.log("DoctorList", selectedDoctorListValue);
    // Selection Type GET API
    const getDoctorSelectionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_link', selectionTypeBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data;
                let doctorSelect = (resData.doctorTeamType === "") ? "Not Selected" : resData.doctorTeamType;
                setDoctorTeamType(doctorSelect);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }
    // Get accept reject  API

    const acceptBooking = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment',
            { "caseId": caseid, "requestStatus": Number(1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
                getSelectedDoc();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
                getSelectedDoc();
            })
    }
    const rejectBooking = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment',
            { "caseId": caseid, "requestStatus": Number(-1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
                getSelectedDoc();
            })
            .catch((error) => {
                setIsLoading(false);
                getSelectedDoc();
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }

    const getSelectedDoc = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/doctorList_for_patientCase', {
            "caseId": caseid
        }, { headers }).then((res) => {
            setIsLoading(false);
            if (res.data.patientCaseDoctorlist.length > 0) {
                let doctorList = res.data.patientCaseDoctorlist;
                let doctorListConfig = [];
                for (var i = 0; i < doctorList.length; i++) {
                    let doctorId = doctorList[i].id;
                    let doctorName = ((doctorList[i].firstname === "") || (doctorList[i].firstname === null) && (doctorList[i].lastname === "") || (doctorList[i].lastname === null)) ? ' ' : doctorList[i].firstname + " "+ doctorList[i].lastname;
                    let qualification = (doctorList[i].qualification === "") || (doctorList[i].qualification === null) ? ' ' : doctorList[i].qualification;
                    let speciality = (doctorList[i].speciality === "") || (doctorList[i].speciality === null) ? ' ' : doctorList[i].speciality;
                    let doctorBookStatus = (doctorList[i].requestStatus === "") || (doctorList[i].requestStatus === null) ? ' ' : doctorList[i].requestStatus;
                    doctorListConfig.push([
                        doctorName,
                        speciality,
                        doctorBookStatus,
                        <Button icon={MobileAcceptMajor} size="slim" onClick={acceptBooking}>Accept</Button>,
                        <Button icon={MobileCancelMajor} destructive size="slim" onClick={rejectBooking}>Reject</Button>,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteDoctor(doctorId,
                            doctorName)}>
                        </Button>
                    ]);
                }
                setSelectedDoctorListValue(doctorListConfig);
            }
            else {
                //setPatientCaseList(res.data.patientName);
            }


        })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    // Delete Doctor Body
    const doctorDeleteBody = {
        "caseId": caseid,
        "doctorId": selectedDoctorId
    }

    // doctor Delete submit
    const deleteDoctorAPI = () => {
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_selected_doctor',
            doctorDeleteBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setDoctorDeleteError('');
                    setDoctorDeleteSuccess(result.data.message);
                    handleToggle();
                    getSelectedDoc();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setDoctorDeleteSuccess('');
                setDoctorDeleteError(error.response.data.message);
            })
    }

    useEffect(() => {
        getSelectedDoc();
        getDoctorSelectionList();
    }, []);

    const selectionOptionMarkup = (
        <Card >
            <Box p={2}>
                <FormLayout.Group>
                    <Layout>
                        <Layout.Section>
                            <Box pt={2} pl={2} pr={2}>
                                <Stack distribution="fill">
                                    <Stack.Item fill>
                                        <Heading>Doctor Selection Type</Heading>
                                        <hr />
                                        <Box mt={2}>
                                            <Banner>
                                                {doctorTeamType}
                                            </Banner>
                                        </Box>
                                    </Stack.Item>
                                </Stack>
                            </Box>
                        </Layout.Section>


                        <Layout.Section>
                            <Card>
                                <Card.Header title="Selected Doctor List">
                                    <Button icon={RefreshMinor} primary={false} onClick={getSelectedDoc}>Reload Doctor List</Button>
                                </Card.Header>
                                <Card.Section>
                                    <Scrollable shadow style={{ height: '200px' }} focusable horizontal={false}>
                                        {selectedDoctorListValue && selectedDoctorListValue.length ? (
                                            <DataTable
                                                columnContentTypes={[
                                                    'text',
                                                    'text',
                                                    'text',
                                                    'text',
                                                    'text'
                                                ]}
                                                headings={[
                                                    'Dr. Name',
                                                    'Speciality',
                                                    'Appoinment Status',
                                                    'Accept',
                                                    'Reject',
                                                    'Delete',
                                                ]}
                                                rows={selectedDoctorListValue}
                                            />
                                        ) : (
                                                <ExceptionList
                                                    items={[
                                                        {
                                                            icon: NoteMinor,
                                                            description: 'No Doctor Details is available!',
                                                        },
                                                    ]}
                                                />)}
                                    </Scrollable>
                                </Card.Section>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </FormLayout.Group>
            </Box>
        </Card >
    );
    const deleteDoctorModal = (
        <Modal
            open={modalActive}
            onClose={handleToggle}
            title="Delete Doctor"
            primaryAction={{
                content: "Yes",
                onAction: deleteDoctorAPI,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleToggle,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this "{doctorName}" from your doctor team ?
                    </Subheading>
                    <div><Box p={1}>{deleteDoctorsuccessDiv}</Box></div>
                    <div><Box p={1}>{deleteDoctorErrorDiv}</Box></div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    )

    return (
        <Page fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {selectionOptionMarkup}
            {deleteDoctorModal}
            {successToastMarkup}
            {errorToastMarkup}
        </Page>
    );
}