import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../../../ApiUrl';
import {
    Button,
    Loading,
    Page,
    Card,
    DataTable,
    ExceptionList,
} from '@shopify/polaris';
import { NoteMinor,AccessibilityMajor } from '@shopify/polaris-icons';
import { Redirect, useHistory } from 'react-router-dom';
export default function DoctorTeleHealth() {
    const history = useHistory();
    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const doctorId = localStorage.getItem("doctorID");
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [meetingListValue, setMeetingListValue] = useState<any[]>([]);
    function gotoCaseDetails(index: number,patientId: number,caseId: number) {
        history.push(`/appointmentdetails/${patientId}/${caseId}`);
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }


    // Meeting List Body
    const meetingListBody = {
        "doctorId": doctorId
    };
    // Meeting List GET API
    const getActiveMeetingList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/doctortelehealth/all_active_meeting_list', meetingListBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let meetingData = res.data.meetingList;
                console.log("AppoinmentLIST==>", meetingData);
                let meetingValueList = [];
                for (var i = 0; i < meetingData.length; i++) {
                    let meetingSeqNo: number = i;
                    let caseId: number = meetingData[i].caseId;
                    let patientId: number = meetingData[i].patientId;
                    let displayName = (meetingData[i].hasOwnProperty('displayName') ? (meetingData[i].displayName == "" || meetingData[i].displayName == null) ? "No Details Available" : meetingData[i].displayName : "");
                    let meetingId = (meetingData[i].hasOwnProperty('id') ? meetingData[i].id == null ? "" : meetingData[i].id : "");
                    let appointmentDate = (meetingData[i].hasOwnProperty('appointmentDate') ? (meetingData[i].appointmentDate == null) ? "" : (new Date(meetingData[i].appointmentDate)).toLocaleDateString('en-US', DATE_OPTIONS) + " at " + (new Date(meetingData[i].appointmentDate)).getHours() + ":" + (new Date(meetingData[i].appointmentDate)).getMinutes() : "");
                    let createdDate = (meetingData[i].dateCreated == null) ? "" : (new Date(meetingData[i].dateCreated)).toLocaleDateString('en-US', DATE_OPTIONS);
                    meetingValueList.push([
                        <Button icon={AccessibilityMajor} size="slim" onClick={() => gotoCaseDetails(meetingSeqNo,patientId,caseId)}></Button>,
                        meetingId,
                        displayName,
                        appointmentDate,
                        createdDate,
                    ]);
                }
                setMeetingListValue(meetingValueList);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }
    const reloadTeleHealthList = () => {
        getActiveMeetingList();
    }

    useEffect(() => {
        getActiveMeetingList();
    }, []);
    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Active Meeting List">
                    <Button primary={false} onClick={reloadTeleHealthList}>Reload Meeting List</Button>
                </Card.Header>
                <Card.Section>
                    {meetingListValue && meetingListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'View',
                                'Meeting Id',
                                'Appointment Name',
                                'Appointment Date',
                                'Create Date',
                            ]}
                            rows={meetingListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Meeting Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )
    return (
        <Page fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {appointmentTableBody}
        </Page>

    );
}