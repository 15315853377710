import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@primer/components';
import {
    Layout,
    Link,
    Button,
    FormLayout,
    DisplayText,
    Caption,
    Page,
    Loading,
    Stack,
    TextStyle,
    ChoiceList,
    Banner,
    Form
} from '@shopify/polaris';
import FeatureImg from 'components/image/featureimage-doctor.png';
import AimsLogo from 'components/image/logo/aims2health-with-caption.png';


export default function Experience() {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const closeerror = () => {
        setError('')
    }

    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const [selected, setSelected] = useState([]);

    const handleChange = useCallback((value) => setSelected(value), []);

    const handleproceed = () => {
        setIsLoading(true);
        if (selected.length === 0) {
            setIsLoading(false);
            setError("You should satisfy either one of the above conditions.");
        }
        else {
            setIsLoading(true);
            history.push('/signup');
            setIsLoading(false);
        }
    }

    const actualPageMarkup = (
        <Layout>
            <Layout.Section oneHalf>
                <Flex style={{ height: "100vh" }} alignItems="center" justifyContent="center">
                    <div>
                        <Link url="https://aims2health.com"><img src={AimsLogo} alt="Logo" height="64" /></Link>
                        <Box my={3}>
                            <DisplayText size="large">Before applying for Doctor's Team at AIMS2Health, please select your AIIMS Experience</DisplayText>
                        </Box>
                        <br />
                        <Box my={3}>
                            <Form onSubmit={handleproceed}>
                                <FormLayout>
                                    <ChoiceList
                                        allowMultiple
                                        title=""
                                        choices={[
                                            {
                                                label: 'Medical graduates (MBBS) of any of the AIIMS who have done MD, MS, DM, Mch, DNB or FNB from AIIMS or another reputed medical institution in India or equivalent degree/qualification from abroad; or',
                                                value: '0',
                                            },
                                            {
                                                label: "Medical graduates (MBBS)from anywhere (India or abroad) who have done MD, MS,DM, Mch, DNB or FNB from any of the AIIMS in India; or",
                                                value: '1',
                                            },
                                            {
                                                label: "An MD, MS, DM, Mch, DNB or FNB who has at least three years of work experience at any of the AIIMS in India.",
                                                value: '2',
                                            },
                                        ]}
                                        selected={selected}
                                        onChange={handleChange}
                                    />
                                    <Button fullWidth primary onClick={handleproceed}>Proceed</Button>
                                    <div>{errorDiv}</div>

                                    <div><TextStyle variation="strong">Already have an account?{' '}</TextStyle><Button primary url="/signin">Sign In</Button></div>
                                </FormLayout>
                            </Form>

                        </Box>
                    </div>
                </Flex>
            </Layout.Section>
            <Layout.Section oneHalf>
                <Box pt={5} mt={5}>
                    <div><img
                        alt=""
                        width="100%"
                        height="100%"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }} src={FeatureImg} /></div>
                </Box>
            </Layout.Section>
            <Layout.Section>
                <Stack>
                    <Stack.Item fill>
                        <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
                    </Stack.Item>
                    <Stack.Item>
                        <Caption>
                            <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;
                            <Link url="https://aims2health.com/privacystatement.html" external>Privacy & Cookies</Link>
                        </Caption>
                    </Stack.Item>
                </Stack>
            </Layout.Section>
        </Layout>
    )

    const loadingMarkup = isLoading ? <Loading /> : null;

    return (
        <Page fullWidth>
            {loadingMarkup}
            {actualPageMarkup}
        </Page>
    );
}