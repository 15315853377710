import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Loading,
    Caption,
    Page,
    DataTable,
    Modal,
    Button,
    TextContainer,
    Subheading,
    ExceptionList,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ViewMinor, NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import baseUrl from '../../../ApiUrl';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});
initializeIcons();

export default function MedicationPrescriptions(props: any) {
    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [toSignin, setToSignin] = useState(false);
    const [imageId, setImageId] = useState('');

    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [textValue, setTextValue] = useState('');
    const handleDesChange = useCallback((newValue) => {
        setTextValue(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );

    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeerror = () => {
        setError('')
    }

    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const closemanualerror = () => {
        setManualError('')
    }

    const [manualerror, setManualError] = useState('');
    const manualerrorDiv = manualerror
        ? <Banner
            onDismiss={closemanualerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const [symptomSuccess, setSymptomSuccess] = useState('');
    const closeSuccess = () => {
        setSymptomSuccess('');
        setDeletePastHistoryModalSuccess('');
    }

    const successDiv = symptomSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{symptomSuccess}</p>
        </Banner>
        : '';

    const [medicalHistoryDescriptionError, setMedicalHistoryDescriptionError] = useState('');
    const medicalHistoryDescriptionErrorDiv = medicalHistoryDescriptionError
        ? <TextStyle variation="negative">{medicalHistoryDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [modalActive, setModalActive] = useState(false);

    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
    }

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const handleDescriptionToggle = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setTextValue("");
        setMedicalHistoryDescriptionError("");
        setImageInfo("");
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setSymptomSuccess("");
        setError("");
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);
    const [patientDetailsId, setPatientDetailsId] = useState('');
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteIdeaConcernModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);
    const [deletePastHistoryModalSuccess, setDeletePastHistoryModalSuccess] = useState('');
    const successPastHistoryModalDiv = deletePastHistoryModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deletePastHistoryModalSuccess}</p>
        </Banner>
        : '';
    // Summary section start
    const [summary, setSummary] = useState('');
    const handleSummaryChange = useCallback((value) => {
        setSummary(value);
    }, []);

    const [summaryFieldError, setSummaryFieldError] = useState('');
    const summaryFieldErrordiv = summaryFieldError
        ? <TextStyle variation="negative">{summaryFieldError}</TextStyle>
        : '';

    const [summaryID, setSummaryID] = useState('');

    const [summaryModalActive, setSummaryModalActive] = useState(false);
    const handleSummaryModalToggle = () => {
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
        setSummary('');
        setSummaryFieldError('');
        setSummarySuccess('');
        setErrorSummary('');
    }
    const closeSummarySuccess = () => {
        setSummarySuccess('');
    }

    const [summarySuccess, setSummarySuccess] = useState('');
    const successSummaryDiv = summarySuccess
        ? <Banner
            onDismiss={closeSummarySuccess}
            status="success">
            <p>{summarySuccess}</p>
        </Banner>
        : '';

    const closeChiefSummaryError = () => {
        setErrorSummary('');
    }
    const [errorSummary, setErrorSummary] = useState('');
    const errorSummaryDiv = errorSummary
        ? <Banner
            onDismiss={closeChiefSummaryError}
            status="critical">
            <p>{errorSummary}</p>
        </Banner>
        : '';
    // View submited Summary
    const viewSummary = (summary: any, summaryId: any) => {
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
        setSummary(summary);
        setSummaryID(summaryId);
        setSummarySuccess('');
        setErrorSummary('');
    }
    const [viewDescriptionModalActive, setViewDescriptionModalActive] = useState(false);
    const viewDescription = (selectedResponse: any, description: any, dateStart: any, dateEnd: any, supportingDocuments: any) => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setTextValue(description);
        setMedicalHistoryDescriptionError('');
        setSelectedDate(dateStart);
        setSelectedEndDate(dateEnd);
        setImageInfo(supportingDocuments);
    }
    const handleViewDescriptionToggle = () => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setTextValue('');
        setMedicalHistoryDescriptionError("");
        setSelectedDate(new Date());
        handleEndDateChange(null);
        setImageInfo('');
    }
    const [summaryId, setSummaryId] = useState("");
    const [deleteSummaryModalActive, setDeleteSummaryModalActive] = useState(false);
    const deleteSummary = (summaryId: any) => {
        setDeleteSummaryModalActive((deleteSummaryModalActive) => !deleteSummaryModalActive);
        setSummaryId(summaryId);
        setDeleteSummaySuccess("");
        setDeleteSummaryError("");
    }
    const handleDeleteSummaryToggle = () => {
        setDeleteSummaryModalActive((deleteSummaryModalActive) => !deleteSummaryModalActive);
        setSummaryId("");
        setDeleteSummaySuccess("");
        setDeleteSummaryError("");
    }
    const closeSummaryBanner = () => {
        setDeleteSummaySuccess('');
        setDeleteSummaryError('');
    }
    const [deleteSummarySuccess, setDeleteSummaySuccess] = useState('');
    const successDeleteSummaryDiv = deleteSummarySuccess
        ? <Banner
            onDismiss={closeSummaryBanner}
            status="success">
            <p>{deleteSummarySuccess}</p>
        </Banner>
        : '';
    const [deleteSummaryError, setDeleteSummaryError] = useState('');
    const deleteSummaryErrorDiv = deleteSummaryError
        ? <Banner
            onDismiss={closeSummaryBanner}
            status="critical">
            <p>{deleteSummarySuccess}</p>
        </Banner> : "";
    // Summary sec end

    // Medication / Drug History Submit Body
    const submitMedicationDrug = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "medication/DrugHistory",
        "description": textValue,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [medicationListValue, setMedicationListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const getMedicationHistoryBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "medication/DrugHistory"
    }

    const [questionListValue, setQuestionListValue] = useState<any[]>([]);
    // Get questionnair list
    const getQuestionnaitList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientQuestionnaireList',
            getMedicationHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resultData = res.data.patientQuestionnaireList;

                console.log("SymptomList=>", resultData);
                console.log("question ", resultData[0].questionnaireType.displayValue);
                let questionConfig = [];
                for (var i = 0; i < resultData.length; i++) {
                    let questionName = resultData[i].questionnaireType.displayValue;
                    let supportingDocuments = resultData[i].templateUrl;
                    questionConfig.push([
                        questionName,
                        <Button onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}>View Question</Button>,
                    ]);
                }

                setQuestionListValue(questionConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // get Document List
    const getMedicationHistoryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getMedicationHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                let medicationConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : resData[i].description;
                    let tableDescription = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    let dateStart = (resData[i].startDate == null) ? null : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? null : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    medicationConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        <Button icon={ViewMinor} onClick={() => {
                            viewDescription(selectedResponse, description, dateStart!, dateEnd!, supportingDocuments);
                        }} />,
                        tableDescription,
                        dateStart,
                        dateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteIdeaConcernData(patientDetailsID)}></Button>
                    ]);
                }
                setMedicationListValue(medicationConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    const addMedication = () => {
        if (textValue === '') {
            setMedicalHistoryDescriptionError("Please add description");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                submitMedicationDrug, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setSymptomSuccess(result.data.message);
                        handleDescriptionToggle();
                        getMedicationHistoryList();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setIsLoading(false);
                })
        }
    }

    const deleteIdeaConcernData = (response: any) => {
        setPatientDetailsId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeletePastHistoryModalSuccess('');
    }
    // Delete body....
    const deleteMedicationHistoryBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    const deleteMedicationHistorySubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteMedicationHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeletePastHistoryModalSuccess(res.data.message);
                getMedicationHistoryList();
                setDeleteModalActive((deleteModalActive) => !deleteModalActive);
            })

            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    // Get Medication / Drug Summary List
    const [summaryValue, setSummaryValue] = useState<any[]>([]);
    const getSummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            getMedicationHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let summaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summaryId = resData[i].id;
                    let tableSummary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : truncate(resData[i].summaryText);
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    summaryConfig.push([
                        <Button icon={ViewMinor} onClick={() => {
                            viewSummary(summary, summaryId);
                        }} />,
                        tableSummary,
                        <Button icon={DeleteMinor} onClick={() => {
                            deleteSummary(summaryId);
                        }} />,
                    ]);
                }
                setSummaryValue(summaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // submit SUmmary Body
    const submitSummaryBody = {
        "caseId": caseid,
        "doctorId": String(localStorage.getItem("doctorID")),
        "secondOpinionUiSection": "medication/DrugHistory",
        "summaryText": summary
    }

    // Submit summary
    const summarySubmit = () => {
        console.log(submitSummaryBody);
        if (summary === '') {
            setSummaryFieldError("Please add history of presenting chief complaint summary");
        }
        else {
            setSummaryFieldError('');
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientSectionSummaryByDoctor',
                submitSummaryBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setErrorSummary('');
                    setSummarySuccess(result.data.message);
                    getSummaryList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setSummarySuccess('');
                    setErrorSummary(error.response.data.message);
                })
        }
    }
    // Delete body....
    const deleteSummaryBody = {
        "caseId": caseid,
        "patientSectionSummaryId": summaryId
    }
    // delete api call here ....
    const deleteDetails = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientSectionSummary',
            deleteSummaryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteSummaySuccess(res.data.message);
                getSummaryList();
                handleDeleteSummaryToggle();
            })

            .catch((error) => {
                setIsLoading(false);
                setDeleteSummaryError(error.response.data.message);
            })
    }
    useEffect(() => {
        getSummaryList();
        getQuestionnaitList();
        getMedicationHistoryList();
    }, []);

    // Idea Concern Summary Table 
    const summaryTableMarkup = (
        <div>
            <Card>
                <Card.Header title="Summary List">
                    <Button primary={true} onClick={handleSummaryModalToggle}>Add Summary</Button>
                </Card.Header>
                <Card.Section>
                    {summaryValue && summaryValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'View',
                                'Summary',
                                'Delete'
                            ]}
                            rows={summaryValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Medication / Prescriptions Summary Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div>
    )

    const summaryMarkup = (
        <Modal
            large
            open={summaryModalActive}
            onClose={handleSummaryModalToggle}
            title={summaryID ? "View Medication / Prescriptions Summary" : "Add Medication / Prescriptions Summary"}
            primaryAction={{
                content: "Submit",
                onAction: summarySubmit,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleSummaryModalToggle,
                },
            ]}
        >
            <Modal.Section>
                <div>
                    <TextField
                        label="Medication / Prescriptions Summary"
                        type="text"
                        value={summary}
                        onChange={handleSummaryChange}
                        multiline={4}
                        placeholder="Please enter medication / prescriptions history summary"
                        id="summaryID"
                    />
                    <InlineError message={summaryFieldErrordiv} fieldID="summaryID" />
                </div>
                <Box py={2}>{successSummaryDiv}</Box>
                <Box py={2}>{errorSummaryDiv}</Box>
            </Modal.Section>
        </Modal>
    )

    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire">
                </Card.Header>
                <Card.Section>
                    {questionListValue && questionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Question Name',
                                'View',
                                'Delete'
                            ]}
                            rows={questionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Questionnaire Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const MedicationDrugHistoryAddInfo = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    <Button primary={true} onClick={handleDescriptionToggle}>Upload Document</Button>
                </Card.Header>
                <Card.Section>
                    {medicationListValue && medicationListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'View',
                                'Description',
                                'Start Date',
                                'End Date',
                                'Delete'
                            ]}
                            rows={medicationListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Medication / Prescriptions Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    const deletemedicationHistoryComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteIdeaConcernModalChange}
            title={"Delete Medication / Prescriptions"}
            primaryAction={{
                content: "Yes",
                onAction: deleteMedicationHistorySubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteIdeaConcernModalChange,
                },
            ]}
            footer={successPastHistoryModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Medication / Prescriptions ?
                    </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const addMedicationHistoryDetails = (
        <Modal
            large
            open={modalActive}
            onClose={handleToggle}
            title={"Add Medication / Prescriptions"}
        >
            <Modal.Section>
                <Page>
                    <Box style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: "25px",
                        height: "0"
                    }}>
                        <iframe style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            border: "0px"
                        }} src="https://virtualsathi-technicise-ehr.herokuapp.com/survey/7" title='Small document'></iframe>
                    </Box>
                </Page>
            </Modal.Section>

        </Modal >
    );

    const uploadDescriptionBody = (
        <Modal
            large
            open={descriptionModalActive}
            onClose={handleDescriptionToggle}
            title={"Upload Documents of Add Medication / Prescriptions Details"}
            primaryAction={{
                content: "Submit",
                onAction: addMedication,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Medication / Prescriptions Document Description"
                                    value={textValue}
                                    onChange={handleDesChange}
                                    placeholder="Please enter medication / prescritions history document description"
                                    multiline={4}
                                    id="medicalHistoryDescriptionID"
                                />
                                <InlineError message={medicalHistoryDescriptionErrorDiv} fieldID="medicalHistoryDescriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                            console.log(imageId);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                        <div>{manualerrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    )

    // View details markup
    const viewDocumentBody = (
        <Modal
            large
            open={viewDescriptionModalActive}
            onClose={handleViewDescriptionToggle}
            title={"View Medication / Prescriptions Details "}
            primaryAction={{
                content: "Close",
                onAction: handleViewDescriptionToggle,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Medication / Prescriptions Document Description"
                                    value={textValue}
                                    onChange={handleDesChange}
                                    placeholder="Please enter Medication/Drug history document description"
                                    multiline={4}
                                    id="medicalHistoryDescriptionID"
                                />
                                <InlineError message={medicalHistoryDescriptionErrorDiv} fieldID="medicalHistoryDescriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );
    // Delete Summary modal section ....
    const deletechiefComponentSummary = (
        <Modal
            open={deleteSummaryModalActive}
            onClose={handleDeleteSummaryToggle}
            title={"Delete Summary"}
            primaryAction={{
                content: "Yes",
                onAction: deleteDetails,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteSummaryToggle,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Summary Details?
                    </Subheading>
                    <div>{successDeleteSummaryDiv}</div>
                    <div>{deleteSummaryErrorDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <Page title="Medication / Prescriptions" fullWidth
        >
            {loadingMarkup}
            {summaryTableMarkup}
            {summaryMarkup}
            {questionBody}
            {MedicationDrugHistoryAddInfo}
            {addMedicationHistoryDetails}
            {uploadDescriptionBody}
            {docViewerComponent}
            {deletemedicationHistoryComponent}
            {viewDocumentBody}
            {deletechiefComponentSummary}
        </Page>
    );
}