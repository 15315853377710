import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    Banner,
    Loading,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { SelectPicker } from 'rsuite';

export default function DoctorEducationalSummaryPage() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);

    const [eductionDropdownList, setEductionDropdownList] = useState<any[]>([]);

    const [selected1, setSelected1] = useState();
    const handleSelectDegreeListChange = useCallback((value) => setSelected1(value), []);
   
    const [selected2, setSelected2] = useState();
    const handleSelectDegreeListChange1 = useCallback((value) => setSelected2(value), []);

    const [selected3, setSelected3] = useState();
    const handleSelectDegreeListChange2 = useCallback((value) => setSelected3(value), []);

    // Add Degree List API
    const getEducationDropdownList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/education_list_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setEductionDropdownList(res.data.degreeList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const loadDataOnSelectPickerOpen = () => {
        getEducationDropdownList();
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const professionSummabody = JSON.stringify({
        highestQualification: selected1,
        secondHighestQualification: selected2,
        mbbsDegree: selected3
    });


    // Get Education Summary
    const getEducationSummary = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_education_summary', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                if (res.data.educationSummary.highestQualification !== null) {
                    setSelected1(res.data.educationSummary.highestQualification.value);
                }
                if (res.data.educationSummary.secondHighestQualification !== null) {
                    setSelected2(res.data.educationSummary.secondHighestQualification.value);
                }
                if (res.data.educationSummary.mbbsDegree !== null) {
                    setSelected3(res.data.educationSummary.mbbsDegree.value);
                }

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    const [educationSummarySuccess, setEducationSummarySuccess] = useState('');
    const closeEducationSummarySuccess = () => {
        setEducationSummarySuccess('');
    }
    const educationSummarySuccessDiv = educationSummarySuccess
        ? <Banner
            onDismiss={closeEducationSummarySuccess}
            status="success">
            <p>{educationSummarySuccess}</p>
        </Banner>
        : '';

    // Education Summary Error
    const [educationSummarySubmitError, setEducationSummarySubmitError] = useState('');
    const closeEducationSummarySubmitError = () => {
        setEducationSummarySuccess('');
    }
    const educationSummarySubmitErrorDiv = educationSummarySubmitError
        ? <Banner
            onDismiss={closeEducationSummarySubmitError}
            status="critical">
            <p>{educationSummarySubmitError}</p>
        </Banner>
        : '';

    // Add Education Summary
    const educationSummarySubmit = () => {
        //console.log(professionSummabody)
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_education_summary',
            professionSummabody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setEducationSummarySuccess(result.data.message);
                    getEducationSummary();
                }
            })
            .catch((error) => {
                console.log(error.response);
                setEducationSummarySubmitError(error.response.data.message);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getEducationSummary();
        getEducationDropdownList();
    }, []);

    const doctorEducationSummary = (
        <div>
            {loadingMarkup}
            <Card primaryFooterAction={{ content: 'Submit Details', onAction: educationSummarySubmit, disabled: isEnableSubmit }}>
                <Card.Header
                    actions={[
                        {
                        content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                            setIsEdit((isEdit) => !isEdit);
                            setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                        }
                    }
                    ]}
                    title="Educational Summary"
                >
                </Card.Header>
                <Box p={3}>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Highest Qualification</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectDegreeListChange}
                                    onOpen={loadDataOnSelectPickerOpen}
                                    data={eductionDropdownList}
                                    value={selected1}
                                    disabled={isEdit}
                                />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>2nd Highest Qualification</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectDegreeListChange1}
                                    onOpen={loadDataOnSelectPickerOpen}
                                    data={eductionDropdownList}
                                    value={selected2}
                                    disabled={isEdit}
                                />
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Degree Qualification</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectDegreeListChange2}
                                    onOpen={loadDataOnSelectPickerOpen}
                                    data={eductionDropdownList}
                                    value={selected3}
                                    disabled={isEdit}
                                />
                            </div>
                                &nbsp;
                            </FormLayout.Group>
                        <div>{educationSummarySuccessDiv}</div>
                        <div>{educationSummarySubmitErrorDiv}</div>
                    </FormLayout>
                </Box>
            </Card>
        </div >
    )
    return (
        <div>
            {doctorEducationSummary}
        </div>
    );
}