import React, { useCallback, useEffect, useState } from 'react';
import {
    Banner,
    Button,
    Caption,
    Card,
    DataTable,
    FormLayout,
    Loading,
    Modal,
    Page,
    Stack,
    TextContainer,
    TextField,
    Thumbnail,
    Subheading,
    TextStyle,
    InlineError,
    ExceptionList,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ViewMinor, NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from "@material-ui/styles";
import axios from 'axios';
import baseUrl from '../../../ApiUrl';
import { Redirect, useParams } from 'react-router-dom';

export default function PresentingComplaints(props: any) {

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#DE3618',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            }
        },
        typography: {
            fontSize: 18,
        }
    });
    initializeIcons();

    const [toSignin, setToSignin] = useState(false);

    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [chiefSummary, setChiefSummary] = useState('');
    const handleChiefSummaryChange = useCallback((value) => {
        setChiefSummary(value);
    }, []);

    const [chiefDescription, setChiefDescription] = useState('');
    const handleChiefDescriptionChange = useCallback((value) => {
        setChiefDescription(value);
    }, []);

    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');
    const [imageId, setImageId] = useState('');

    const [patientDetailsId, setPatientDetailsId] = useState('');

    // Start Date
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    // End Date
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (enddate: Date | null) => {
        setSelectedEndDate(enddate);
    };

    // Image upload Body
    const uploadbody = {
        'typeName': 'patient-image'
    };

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const [summaryID, setSummaryID] = useState('');

    const [summaryModalActive, setSummaryModalActive] = useState(false);
    const handleSummaryModalToggle = () => {
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
        setChiefSummary('');
        setChiefSummaryFieldError('');
        setChiefSummarySuccess('');
        setErrorChiefSummary('');
    }
    const [viewSummaryModalActive, setViewSummaryModalActive] = useState(false);
    const handleViewSummaryModalToggle = () => {
        setViewSummaryModalActive((viewSummaryModalActive) => !viewSummaryModalActive);
        setChiefSummary('');
    }

    const [chiefSummaryFieldError, setChiefSummaryFieldError] = useState('');
    const chiefSummaryFieldErrordiv = chiefSummaryFieldError
        ? <TextStyle variation="negative">{chiefSummaryFieldError}</TextStyle>
        : '';

    const closeChiefSummarySuccess = () => {
        setChiefSummarySuccess('');
    }

    const [chiefSummarySuccess, setChiefSummarySuccess] = useState('');
    const successChifSummarylDiv = chiefSummarySuccess
        ? <Banner
            onDismiss={closeChiefSummarySuccess}
            status="success">
            <p>{chiefSummarySuccess}</p>
        </Banner>
        : '';

    const closeChiefSummaryError = () => {
        setErrorChiefSummary('');
    }
    const [errorchiefSummary, setErrorChiefSummary] = useState('');
    const ErrorChifSummarylDiv = errorchiefSummary
        ? <Banner
            onDismiss={closeChiefSummaryError}
            status="critical">
            <p>{errorchiefSummary}</p>
        </Banner>
        : '';

    const viewSummary = (summary: any, summaryId: any) => {
        setViewSummaryModalActive((viewSummaryModalActive) => !viewSummaryModalActive);
        setChiefSummary(summary);
        setChiefSummaryFieldError('');
        setSummaryID(summaryId);
        setChiefSummarySuccess('');
        setErrorChiefSummary('');
    }

    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setChiefDescription('');
        setSelectedDate(new Date(),);
        setSelectedEndDate(null);
        setImageInfo('');
        setAddEncounterModalSuccess('');
        setChiefError('');
        getQuestionnaitList();
    }

    const closeUpload = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
    }

    const closeSuccess = () => {
        setAddEncounterModalSuccess('');
        setDeleteChifModalSuccess('');
        setChiefDeleteError('');
    }
    const [addEncounterModalSuccess, setAddEncounterModalSuccess] = useState('');
    const successModalDiv = addEncounterModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addEncounterModalSuccess}</p>
        </Banner>
        : '';
    const [chiefError, setChiefError] = useState('');
    const chiefErrorDiv = chiefError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{chiefError}</p>
        </Banner>
        : '';

    const [deleteChifModalSuccess, setDeleteChifModalSuccess] = useState('');
    const successDelChifModalDiv = deleteChifModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deleteChifModalSuccess}</p>
        </Banner>
        : '';

    const [chiefDescriptionError, setChiefDescriptionError] = useState('');
    const chiefDescriptionErrordiv = chiefDescriptionError
        ? <TextStyle variation="negative">{chiefDescriptionError}</TextStyle>
        : '';

    const [chiefDeleteError, setChiefDeleteError] = useState('');
    const chiefDeleteErrorDiv = chiefDeleteError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{chiefDeleteError}</p>
        </Banner>
        : '';

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteChifModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    const [viewDescriptionModalActive, setViewDescriptionModalActive] = useState(false);

    const [fieldDisable, setFieldDisable] = useState(false);
    const viewDescription = (description: any, dateStart: any, dateEnd: any, supportingDocuments: any) => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setChiefDescription(description);
        setChiefDescriptionError('');
        setSelectedDate(dateStart);
        setSelectedEndDate(dateEnd);
        setImageInfo(supportingDocuments);
        setFieldDisable(true);
    }
    const handleViewDescriptionToggle = () => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setChiefDescriptionError('');
        setChiefDescription("");
        setSelectedDate(new Date());
        handleEndDateChange(null);
        setImageInfo('');
    }

    const [summaryId, setSummaryId] = useState("");
    const [deleteSummaryModalActive, setDeleteSummaryModalActive] = useState(false);
    const deleteSummary = (summaryId: any) => {
        setDeleteSummaryModalActive((deleteSummaryModalActive) => !deleteSummaryModalActive);
        setSummaryId(summaryId);
        setDeleteSummaySuccess("");
        setDeleteSummaryError("");
    }
    const handleDeleteSummaryToggle = () => {
        setDeleteSummaryModalActive((deleteSummaryModalActive) => !deleteSummaryModalActive);
        setSummaryId("");
        setDeleteSummaySuccess("");
        setDeleteSummaryError("");
    }
    const [deleteSummarySuccess, setDeleteSummaySuccess] = useState('');
    const successDeleteSummaryDiv = deleteSummarySuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deleteSummarySuccess}</p>
        </Banner>
        : '';

    const [deleteSummaryError, setDeleteSummaryError] = useState('');
    const deleteSummaryErrorDiv = deleteSummaryError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{deleteSummarySuccess}</p>
        </Banner> : "";
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const body = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "chiefCompliants",
        "description": chiefDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };


    const ChiefComplaintSubmit = () => {
        console.log(body);
        if (chiefDescription === '') {
            setChiefDescriptionError("Please add description");
        }
        else {
            setChiefDescriptionError('');
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                body, { headers })
                .then(result => {
                    setIsLoading(false);
                    setChiefError('');
                    setAddEncounterModalSuccess(result.data.message);
                    getChiefComplaintList();
                    uploadhandleToggle();
                })
                .catch((error) => {
                    setChiefError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }
    // Delete body....
    const deleteChiefComplaintBody = {
        "caseId": caseid,
        "patientSectionSummaryId": summaryId
    }
    // delete api call here ....
    const deleteDetails = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientSectionSummary',
            deleteChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteSummaySuccess(res.data.message);
                getChiefComplaintSummaryList();
                handleDeleteSummaryToggle();
            })

            .catch((error) => {
                setIsLoading(false);
                setDeleteSummaryError(error.response.data.message);
            })
    }

    // Delete body....
    const deleteSummaryBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteChiefComplaintSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteSummaryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteModalActive((deleteModalActive) => !deleteModalActive);
                setDeleteChifModalSuccess(res.data.message);
                getChiefComplaintList();
                handleDeleteChifModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                setChiefDeleteError(error.response.data.message);
            })
    }

    const [chiefComplaintSummaryValue, setChiefComplaintSummaryValue] = useState<any[]>([]);

    const [questionListValue, setQuestionListValue] = useState<any[]>([]);

    const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }

    const getChiefComplaintBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "chiefCompliants"
    }

    // Delete popup open ........   
    function deleteChiefComplaints(response: any) {
        setPatientDetailsId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteChifModalSuccess('');
    }

    const getChiefComplaintSummaryBody = {
        "caseId": caseid,
        "doctorId": String(localStorage.getItem("doctorID")),
        "secondOpinionUiSection": "chiefCompliants",
        "summaryText": chiefSummary
    }

    // Submit Chief complaint summary
    const ChiefComplaintSummarySubmit = () => {
        console.log(getChiefComplaintSummaryBody);
        if (chiefSummary === '') {
            setChiefSummaryFieldError("Please add presenting chief complaint summary");
        }
        else {
            setChiefDescriptionError('');
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientSectionSummaryByDoctor',
                getChiefComplaintSummaryBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setChiefSummaryFieldError('');
                    setChiefSummarySuccess(result.data.message);
                    getChiefComplaintSummaryList();
                })
                .catch((error) => {
                    setErrorChiefSummary(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }

    // Get Presenting Chief Complaint Document List
    const getChiefComplaintSummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            getChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let presentingChiefComplaintSummaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summaryId = resData[i].id;
                    let tableSummary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : truncate(resData[i].summaryText);
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    presentingChiefComplaintSummaryConfig.push([
                        <Button icon={ViewMinor} onClick={() => {
                            viewSummary(summary, summaryId);
                        }} />,
                        tableSummary,
                        <Button icon={DeleteMinor} onClick={() => {
                            deleteSummary(summaryId);
                        }} />,
                    ]);
                }
                setChiefComplaintSummaryValue(presentingChiefComplaintSummaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Get Presenting Chief Complaint Document List
    const getChiefComplaintList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                let presentingChiefComplaintConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : resData[i].description;
                    let tableDescription = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    let dateStart = (resData[i].startDate == null) ? null : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? null : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    setPatientDetailsId(patientDetailsID);
                    presentingChiefComplaintConfig.push([
                        <Button icon={NoteMinor} size="large" disabled={supportingDocuments === '' ? true : false} onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        <Button icon={ViewMinor} onClick={() => {
                            viewDescription(description, dateStart!, dateEnd!, supportingDocuments);
                        }} />,
                        tableDescription,
                        dateStart,
                        dateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteChiefComplaints(patientDetailsID)}></Button>
                    ]);
                }
                setChiefComplaintListValue(presentingChiefComplaintConfig);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Get questionnair list
    const getQuestionnaitList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientQuestionnaireList',
            getChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resultData = res.data.patientQuestionnaireList;
                let questionConfig = [];
                for (var i = 0; i < resultData.length; i++) {
                    let questionName = resultData[i].questionnaireType.displayValue;
                    let supportingDocuments = resultData[i].templateUrl;
                    questionConfig.push([
                        questionName,
                        <Button onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}>View Question</Button>,
                    ]);
                }
                setQuestionListValue(questionConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }


    useEffect(() => {
        getChiefComplaintSummaryList();
        getQuestionnaitList();
        getChiefComplaintList();
    }, []);

    const summaryTableMarkup = (
        <div>
            <Card>
                <Card.Header title="Summary List">
                    <Button primary={true} onClick={handleSummaryModalToggle}>Add Summary</Button>
                </Card.Header>
                <Card.Section>
                    {chiefComplaintSummaryValue && chiefComplaintSummaryValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'View',
                                'Summary',
                                'Delete'
                            ]}
                            rows={chiefComplaintSummaryValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Presenting Chief Complaints Summary Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div>
    )

    const chiefComplaintSummary = (
        <Modal
            large
            open={summaryModalActive}
            onClose={handleSummaryModalToggle}
            title={"Add Presenting Complaint(s) Summary"}
            primaryAction={{
                content: "Submit",
                onAction: ChiefComplaintSummarySubmit,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleSummaryModalToggle,
                },
            ]}
        >
            <Modal.Section>
                <div>
                    <TextField
                        label="Presenting Complaint(s) Summary"
                        type="text"
                        value={chiefSummary}
                        onChange={handleChiefSummaryChange}
                        multiline={4}
                        placeholder="Please enter presenting complaint(s) summary"
                        id="summaryID"
                    />
                    <InlineError message={chiefSummaryFieldErrordiv} fieldID="summaryID" />
                </div>
                <Box py={2}>{successChifSummarylDiv}</Box>
                <Box py={2}>{ErrorChifSummarylDiv}</Box>
            </Modal.Section>
        </Modal>
    );
    const viewChiefSummaryModal = (
        <Modal
            large
            open={viewSummaryModalActive}
            onClose={handleViewSummaryModalToggle}
            title={"View Presenting Complaint(s) Summary"}
            primaryAction={{
                content: "Close",
                onAction: handleViewSummaryModalToggle,
            }}
        >
            <Modal.Section>
                <div>
                    <TextField
                        label="Presenting Complaint(s) Summary"
                        type="text"
                        value={chiefSummary}
                        onChange={handleChiefSummaryChange}
                        multiline={4}
                        placeholder="Please enter presenting complaint(s) summary"
                        readOnly={true}
                        id="summaryID"
                    />
                </div>
            </Modal.Section>
        </Modal>
    )

    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire"></Card.Header>
                <Card.Section>
                    {questionListValue && questionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Question Name',
                                'View'
                            ]}
                            rows={questionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Questionnaire Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const tableMarkup = (
        <Card>
            <Card.Header title="Documents">
                <Button primary={true} onClick={uploadhandleToggle}>Upload Data</Button>
            </Card.Header>
            <Card.Section>
                {questionListValue && questionListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Document',
                            'View',
                            'Description',
                            'Start Date',
                            'End Date',
                            'Delete'
                        ]}
                        rows={chiefComplaintListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Presenting Chief Complaints Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // Delete modal section ....
    const deletechiefComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteChifModalChange}
            title={"Delete Presenting Complaint(s)"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteChiefComplaintSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteChifModalChange,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Presenting Complaint(s) ?
                    </Subheading>
                    <div>{successDelChifModalDiv}</div>
                    <div>{chiefDeleteErrorDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const uploadChiefComplaintDetails = (
        <Modal
            large
            open={uploadmodalActive}
            onClose={closeUpload}
            title={"Upload Data for Presenting Complaint(s)"}
            primaryAction={{
                content: "Submit",
                onAction: ChiefComplaintSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Presenting Complaint(s) Description"
                                    type="text"
                                    value={chiefDescription}
                                    onChange={handleChiefDescriptionChange}
                                    multiline={4}
                                    placeholder="Please enter presenting complaint(s) description"
                                    id="descriptionID"
                                />
                                <InlineError message={chiefDescriptionErrordiv} fieldID="descriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload supporting document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Stack>
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                                id='imageUpload'
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} alt='' width="100%" height="100%" />
                                                    ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                    )}
                                                </button>
                                            </Uploader>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Please upload profile picture of 200px (Width) X 200px (Height)</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p>Start date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p>End date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successModalDiv}</div>
                        <div>{chiefErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    // View details markup
    const viewDocumentBody = (
        <Modal
            large
            open={viewDescriptionModalActive}
            onClose={handleViewDescriptionToggle}
            title={"View Presenting Chief Complaints Details"}
            primaryAction={{
                content: "Close",
                onAction: handleViewDescriptionToggle,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Mention your health problem"
                                    type="text"
                                    value={chiefDescription}
                                    onChange={handleChiefDescriptionChange}
                                    multiline={4}
                                    readOnly={fieldDisable}
                                    placeholder="Please enter presenting chief complaint description"
                                    id="descriptionID"
                                />
                                <InlineError message={chiefDescriptionErrordiv} fieldID="descriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                        }}
                                        disabled={fieldDisable}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            readOnly={fieldDisable}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"

                                            readOnly={fieldDisable}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    // Delete Summary modal section ....
    const deletechiefComponentSummary = (
        <Modal
            open={deleteSummaryModalActive}
            onClose={handleDeleteSummaryToggle}
            title={"Delete Summary"}
            primaryAction={{
                content: "Yes",
                onAction: deleteDetails,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteSummaryToggle,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Summary Details?
                    </Subheading>
                    <div>{successDeleteSummaryDiv}</div>
                    <div>{deleteSummaryErrorDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    return (
        <Page title="Presenting Complaint(s)" fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {summaryTableMarkup}
            {chiefComplaintSummary}
            {viewChiefSummaryModal}
            {questionBody}
            {tableMarkup}
            {docViewerComponent}
            {deletechiefComponent}
            {uploadChiefComplaintDetails}
            {viewDocumentBody}
            {deletechiefComponentSummary}
        </Page>
    );
}
