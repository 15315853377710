import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    Banner,
    Loading,
    Page
}
    from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
interface AccountProps {
    onAction(): void;
}

export default function DoctorComments() {
    const [coverLetter, setCoverLetter] = useState('');
    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);
    const handleCoverLetterChange = useCallback((value) => setCoverLetter(value), [],);
   

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const body = JSON.stringify({
        coverLetter: coverLetter
    });
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const [coverLetterSubmit, setCoverLetterSubmit] = useState('');
    const closeCoverLetterSubmit = () => {
        setCoverLetterSubmit('');
    }
    const closeerror = () => {
        setError('')
    }

    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';
    const successDiv = coverLetterSubmit
        ? <Banner
            onDismiss={closeCoverLetterSubmit}
            status="success">
            <p>{coverLetterSubmit}</p>
        </Banner>
        : '';

    const editCoverLetter = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_cover_letter',
            body, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setCoverLetterSubmit(result.data.message)
                    getCoverLetter();
                }
            })
            .catch((error) => {
                console.log(error.response.data.message);
                setError(error.response.data.message);
                setIsLoading(false);
            })
    }

    //Get Cover Letter Response
    const getCoverLetter = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_cover_letter',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setCoverLetter(res.data.coverLetter);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    useEffect(() => {
        getCoverLetter();
    }, []);


    const actualPageMarkup = (
        <div>
            <Card
                primaryFooterAction={{ content: 'Submit Details', onAction: editCoverLetter, disabled: isEnableSubmit }}
            >
                <Card.Header
                    actions={[{
                        content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                            setIsEdit((isEdit) => !isEdit);
                            setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                        }
                    }]}
                    title="Why do you want to join AIMS2health Team?"
                >
                </Card.Header>
                <Box p={3}>
                    <FormLayout>
                        <TextField
                            value={coverLetter}
                            onChange={handleCoverLetterChange}
                            label="Enter your text here"
                            placeholder="Please write within 1000 letter"
                            readOnly={isEdit}
                            showCharacterCount
                            maxLength={1000}
                            multiline={4}
                        />
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                    </FormLayout>
                </Box>
            </Card>
            <br />
        </div>
    )

    return (
        <Page>
            {loadingMarkup}
            {actualPageMarkup}
        </Page>

    );
}