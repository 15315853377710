import React from 'react';
import {    
    Page
} from '@shopify/polaris';
import DoctorEducationalSummary from './DoctorEducationalSummaryPage';
import DoctorEducationalPage from './DoctorEducationalPage';

export default function DoctorEducationalDetails() {
    
    return (
        <Page>
            <DoctorEducationalSummary />
            <br />
            <DoctorEducationalPage />
        </Page>
    );
}