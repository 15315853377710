import React, { useCallback, useEffect, useState } from 'react';
import {
    Banner,
    Card,
    FormLayout,
    TextField,
    TextStyle
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { SelectPicker } from 'rsuite';
import baseUrl from '../../../ApiUrl';
import axios from 'axios';

export default function ProfessionSummary() {
    const [isLoading, setIsLoading] = useState(false);
    const [isEnableSubmit, setEnableSubmit] = useState(true);
    const [isEdit, setIsEdit] = useState(true);

    const [highestQualification, setHighestQualification] = useState('0');
    const handleHighestQualificationChange = useCallback((value) => setHighestQualification(value), []);

    const [privatePractice, setPrivatePractice] = useState('0');
    const handlePrivatePracticeChange = useCallback(
        (value) => setPrivatePractice(value),
        [],
    );

    const [selected2, setSelected2] = useState();
    const handleSelectprofessionListChange2 = useCallback((value) => setSelected2(value), []);

    var specialityvalue = String(selected2);
    const [submitDegreeList, setsubmitDegreeListValues] = useState([]);
    const [professionSummarySuccess, setprofessionSummarySuccess] = useState('');
    const [addProfessionsuccess, setAddProfessionsuccess] = useState('');

    const closeSuccess = () => {
        setprofessionSummarySuccess('');
        setprofessionSummarySubmitError('');
        setAddProfessionsuccess('');
    }

    // professional summary edit error
    const [professionSummarySubmitError, setprofessionSummarySubmitError] = useState('');
    const professionSummarySubmitErrorDiv = professionSummarySubmitError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{professionSummarySubmitError}</p>
        </Banner>
        : '';
    // Profession summary edit success
    const professionsuccessDiv = professionSummarySuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{professionSummarySuccess}</p>
        </Banner>
        : '';

    const professionSummabody = JSON.stringify({
        totalYearsOfPracticeAfterHighestQualification: highestQualification,
        totalYearsOfPrivatePracticeAfterHighestQualification: privatePractice,
        doctorSpecialty: specialityvalue
    });

    const userToken = localStorage.getItem("token");

    // Get Education Summary
    const getprofessionSummary = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_work_summary', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                setHighestQualification(String(res.data.workSummaryDetails.totalYearsOfPracticeAfterHighestQualification));
                setPrivatePractice(String(res.data.workSummaryDetails.totalYearsOfPrivatePracticeAfterHighestQualification));
                setSelected2(res.data.workSummaryDetails.doctorSpecialty.value);
                console.log(res.data.workSummaryDetails.doctorSpecialty.value);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    // Speciality List dropdown.........
    const getSpecialityList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/speciality_list',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setsubmitDegreeListValues(res.data.doctorSpecialtyList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const professionSummarySubmit = () => {
        console.log(professionSummabody);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_work_summary',
            professionSummabody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    // setDoctorSummary(result.data);
                    setprofessionSummarySubmitError('');
                    setprofessionSummarySuccess(result.data.message);
                    getprofessionSummary();
                }
            })
            .catch((error) => {
                console.log(error.response);
                setprofessionSummarySuccess('');
                setprofessionSummarySubmitError(error.response.data.message);
                setIsLoading(false);
            })
    }

    const pagebody = (
            <Card primaryFooterAction={{ content: 'Submit', onAction: professionSummarySubmit, disabled: isEnableSubmit }}>
                <Card.Header
                    actions={[{
                        content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                            setIsEdit((isEdit) => !isEdit);
                            setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                        }
                    }]}
                    title="Professional Summary"
                >
                </Card.Header>
                    <Box p={3}>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <TextField
                                    label="Total years of Practice after highest Qualification"
                                    value={highestQualification}
                                    type="number"
                                    onChange={handleHighestQualificationChange}
                                    placeholder="Enter years of Practice after highest qualification"
                                    disabled={isEdit}
                                />
                                <TextField
                                    label="Total no of years in Private practice after highest qualification"
                                    value={privatePractice}
                                    type="number"
                                    onChange={handlePrivatePracticeChange}
                                    placeholder="Enter no of years in private practice after highest qualification"
                                    disabled={isEdit}
                                />
                            </FormLayout.Group>
                            <FormLayout.Group condensed>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Doctor Speciality</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectprofessionListChange2}
                                        data={submitDegreeList}
                                        value={selected2}
                                        disabled={isEdit}
                                    />
                                </div>
                                &nbsp;
                            </FormLayout.Group>
                            <div>{professionsuccessDiv}</div>
                            <div>{professionSummarySubmitErrorDiv}</div>
                        </FormLayout>
                    </Box>
            </Card>
    )
    useEffect(() => {
        getSpecialityList();
        getprofessionSummary();
    }, []);
    return (
        <Box py="3">
            {pagebody}
        </Box>
    );
}
