import * as React from 'react';
import { useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card,
    Page,
    Loading,
    DataTable,
    Button,
    ExceptionList,
} from '@shopify/polaris';
import { AccessibilityMajor, NoteMinor } from '@shopify/polaris-icons';
import { getDoctorTaskList } from 'redux/effects/ApiCall';
import { AppState } from 'redux/store/Store';
import { useDispatch, useSelector } from 'react-redux';

export default function PatientCaseList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const loadingMarkup = <Loading />;
    function editInsuranceDetails(index: number,patientId: number,caseId: number) {
        history.push(`/taskdetails/${patientId}/${caseId}/${index}`);
    }


    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }
    const taskReduxValue = useSelector((state: AppState) => state.taskValue);

    let taskList = taskReduxValue.taskValue;
    let taskListConfig: any = [];
    for (var i = 0; i < taskList.length; i++) {
        let taskSeqNo: number = i;
        let caseId: number = taskList[i].caseId;
        let patientId: number = taskList[i].patientId;
        let title = (taskList[i].title === "") || (taskList[i].title === null) ? '' : taskList[i].title;
        let patientName = (taskList[i].patientName === "") || (taskList[i].patientName === null) ? '' : taskList[i].patientName;
        let consultationtype = (taskList[i].consultationType === "") || (taskList[i].consultationType === null) ? '' : taskList[i].consultationType;
        let table_type = (taskList[i].secondOpinionReasons === "") || (taskList[i].secondOpinionReasons === null) ? '' : truncate(taskList[i].secondOpinionReasons);
        taskListConfig.push([
            <Button icon={AccessibilityMajor} size="slim" onClick={() => editInsuranceDetails(taskSeqNo,patientId,caseId)}></Button>,
            title,
            patientName,
            consultationtype,
            table_type,
        ]);
    }
    const taskListValue: any[] = taskListConfig;
    console.log("IndexArray",taskListValue);

    useEffect(() => {
        dispatch(getDoctorTaskList());
    }, [dispatch]);

    const taskTable = taskListValue ? (
        <Card>
            <Card.Header title="Task List" />
            <Card.Section>
                {taskListValue && taskListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'Title',
                            'Patient Name',
                            'Consultation Type',
                            'SecondOpinionReasons'
                        ]}
                        rows={taskListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Task Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    ) : (
        <p>No Details available</p>
    );

    return (
        <Page fullWidth>
            {taskTable}
            {taskReduxValue.loading ? loadingMarkup : ''}
        </Page>
    );
}
