import React, { useCallback, useState, useEffect } from 'react';
import { Card, DataTable, FormLayout, Page, TextField, Stack, Caption, Thumbnail, Loading, InlineError, Banner, TextStyle, Button, Modal, TextContainer, ExceptionList } from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Uploader, Loader, SelectPicker, } from 'rsuite';
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';
import 'react-dropzone-uploader/dist/styles.css';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, IconButton } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});
export default function IdentityProofs() {

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const [editIdentitylId, setEditIdentityId] = useState('');
    const [selectedGovtId, setSelectedGovtIdDocument] = useState("");
    const [reference, setReference] = useState('');
    const [imageId, setImageId] = useState('');
    const [imageInfo, setImageInfo] = useState('');
    const [uploading] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submitIdProofList, setSubmitIdProofListValues] = useState([]);
    const [identityModalActive, setIdentityModalActive] = useState(false);
    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );

    let numberFormatedselected = parseInt(selectedGovtId);

    const handleSelectChange = useCallback((value) => {
        setSelectedGovtIdDocument(value)
    }, []);
    const handleReferenceChange = useCallback((newValue) => {
        setReference(newValue)
    }, []);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };
    const loadingMarkup = isLoading ? <Loading /> : null;

    // img upload body......
    const uploadbody = {
        'typeName': 'doctor-govt-id-proof'
    };

    const handleToggle = () => {
        setIdentityModalActive((identityModalActive) => !identityModalActive);
        setEditIdentityId("");
        setAddIdentitySuccess("");
        setImageInfo("");
        setImageId("");
        setSelectedDate(new Date(),);
        setSelectedGovtIdDocument("");
        setReference("");
        setIdentityListSelectError("");
        setAddIdentityError("");
    }
    const [identityListSelectError, setIdentityListSelectError] = useState('');
    const iidentityListSelectErrordiv = identityListSelectError
        ? <InlineError message={identityListSelectError} fieldID="insuranceID" />
        : '';

    const [addIdentitySuccess, setAddIdentitySuccess] = useState('');
    const closeAddEducationSuccess = () => {
        setAddIdentitySuccess('');
    }
    const successAddEducationDiv = addIdentitySuccess
        ? <Banner
            onDismiss={closeAddEducationSuccess}
            status="success">
            <p>{addIdentitySuccess}</p>
        </Banner>
        : '';

    const [addIdentityError, setAddIdentityError] = useState('');
    const closeAddIdentityError = () => {
        setAddIdentityError('');
    }
    const errorAddEducationDetailsDiv = addIdentityError
        ? <Banner
            onDismiss={closeAddIdentityError}
            status="critical">
            <p>{addIdentityError}</p>
        </Banner>
        : '';

    // edit function.......
    function editIdentityProofs(response: any) {
        setAddIdentitySuccess("");
        setAddIdentityError("");
        setIdentityListSelectError("");
        setIdentityModalActive((identityModalActive) => !identityModalActive);
        setEditIdentityId(response.id);
        let docURL = (response.supportingDocuments) ? response.supportingDocuments.document : 'document.png';
        let imgId = (response.supportingDocuments) ? response.supportingDocuments.id : '';
        setImageInfo(docURL);
        setImageId(imgId);
        setSelectedDate(response.validityDate);
        setSelectedGovtIdDocument((response.idType == null) ? '' : response.idType.value);
        setReference(response.idNumber);
    }

    // Add Doctor ID List API ....
    const userToken = localStorage.getItem("token");
    const getDoctorIdProofList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/doctoridproof/doctor_govtidtype_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setSubmitIdProofListValues(res.data.idProofTypeList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const [identityList, setIdentityList] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const getIdentityProofByDoctorApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/doctoridproof/get_id_details_by_doctor', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.proofList;
                let identityList = [];
                for (var i = 0; i < response.length; i++) {
                    let selectedResponse = response[i];
                    let date = (response[i].validityDate === "" || response[i].validityDate === null) ? "" : (new Date(response[i].validityDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let referenceNumber = response[i].idNumber === "" ? "" : response[i].idNumber;
                    let docIDImg = (response[i].supportingDocuments) ? response[i].supportingDocuments.document : 'document.png';
                    identityList.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(docIDImg);
                        }}></Button>,
                        <TextStyle variation="strong" key="idType">{response[i].idType.label}</TextStyle>,
                        date,
                        referenceNumber,
                        <Button icon={EditMinor} size="slim" onClick={() => editIdentityProofs(selectedResponse)}></Button>
                    ]);
                    setIdentityList(identityList);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    // Page body
    const identityBody = {
        proofTypeId: numberFormatedselected,
        validityDate: selectedDate,
        supportdocId: imageId,
        idNumber: reference
    };

    const identityProofSubmit = () => {
        console.log(identityBody);
        if (selectedGovtId === "" || selectedGovtId === null) {
            setIdentityListSelectError("Please select an ID proof");
        }
        else {
            // add submit api call here ...
            setIsLoading(true);
            let url, govtIdSubmitBody;
            if (editIdentitylId) {
                url = baseUrl.baseUrl + '/doctorapp/api/doctoridproof/edit_doctor_idproof';
                govtIdSubmitBody = Object.assign(identityBody, { govtproofId: editIdentitylId })
                console.log("govtid===", govtIdSubmitBody);
            }
            else {
                url = baseUrl.baseUrl + '/doctorapp/api/doctoridproof/add_doctor_idproof';

            }
            console.log("BODY==>", govtIdSubmitBody);
            axios.post(url, JSON.stringify(identityBody), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setAddIdentityError("");
                    getIdentityProofByDoctorApi();
                    setAddIdentitySuccess(result.data.message);
                    setIdentityListSelectError("");
                }
            }).catch((error) => {
                setAddIdentitySuccess("");
                setAddIdentityError(error.response.data.message);
                setIdentityListSelectError("");
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        getDoctorIdProofList();
        getIdentityProofByDoctorApi();
    }, []);

    const doctorIdentityProofList = (
        <Card.Section>
            {identityList && identityList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'numeric',
                        'numeric',
                        'text'
                    ]}
                    headings={[
                        'Supportive Document',
                        'Identity Name',
                        'Valid Upto',
                        'Reference Number',
                        'Edit'
                    ]}
                    rows={identityList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Identity Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )
    const addIdentityDetails = (
        <Modal large
            open={identityModalActive}
            onClose={handleToggle}
            title={editIdentitylId ? "Edit Identity Details" : "Add Identity Details"}
            primaryAction={{
                content: "Submit",
                onAction: identityProofSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Select ID Proof</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectChange}
                                        data={submitIdProofList}
                                        value={selectedGovtId}
                                        menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                                    />
                                    <TextStyle variation="strong">Please upload your pan card and one address proof</TextStyle>
                                </div>
                                <div>{iidentityListSelectErrordiv}</div>
                                <br />
                                <div>
                                    <TextField
                                        label="Reference Number"
                                        value={reference}
                                        onChange={handleReferenceChange}
                                        placeholder="Please Enter Reference Number"
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageId(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Thumbnail source={NoteMinor} size="large" alt="Small document" />
                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <div style={{ color: 'gray' }}>Document size should be maximum 3MB.</div>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Valid Upto</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>&nbsp;</div>
                        </FormLayout.Group>
                        <div>{successAddEducationDiv}</div>
                        <div>{errorAddEducationDetailsDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );
    const actualPageMarkup = (
        <Box>
            <Card>
                <Card.Header
                    title="Identity Details"
                >
                    <Button primary={true} onClick={handleToggle}>Add Identity Details</Button>
                </Card.Header>
                {doctorIdentityProofList}
            </Card>
            {addIdentityDetails}
        </Box>
    );
    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title= "Identity Proof Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <Page>
            {loadingMarkup}
            {actualPageMarkup}
            {docViewerComponent}
        </Page>
    );
}
