import React, { useState, useCallback, useEffect } from 'react';
import {
    Page,
    Tabs,
} from '@shopify/polaris';
import DoctorPersonalDetails from './DoctorOnboarding/DoctorPersonalDetails';
import { Box } from '@primer/components';
import DoctorProfessionalDetails from './DoctorOnboarding/DoctorProfessionalDetails';
import DoctorEducationalDetails from './DoctorOnboarding/DoctorEducationalDetails';
import DoctorComments from './DoctorOnboarding/DoctorComments';
import Awards from './DoctorOnboarding/Award';
import LegalDetails from './DoctorOnboarding/LegalDetails';
import JoiningFee from './DoctorOnboarding/JoiningFee';
import Agreement from './DoctorOnboarding/Agreement';
import InsuranceDetails from './DoctorOnboarding/InsuranceDetails'
import Identity from './DoctorOnboarding/IdentityProofs'

export default function UserProfile() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedprofiletab", String(selectedTabIndex));
    }, []);

    const [pageTitle,setPageTitle] = useState("");
    const onboardingStatus = localStorage.getItem("onboardingrequestStatus");

    const tabs = [
        {
            id: 'personal-details',
            content: 'Personal Details',
            body: (
                <DoctorPersonalDetails />
            ),
        },
        {
            id: 'education-details',
            content: 'Educational Details',
            body: (
                <DoctorEducationalDetails />
            ),
        },
        {
            id: 'professional-details',
            content: 'Professional Details',
            body: (
                <DoctorProfessionalDetails />
            ),
        },
        {
            id: 'awards',
            content: 'Awards',
            body: (
                <Awards />
            ),
        },
        {
            id: 'Cover-Letter',
            content: 'Cover Letter',
            body: (
                <DoctorComments />
            ),
        },
        {
            id: 'legalDetails',
            content: 'Legal Details',
            body: (
                <LegalDetails />
            ),
        },
        {
            id: 'joiningFee',
            content: 'Joining Fee',
            body: (
                <JoiningFee />
            ),
        },
        {
            id: 'InsuranceDetails',
            content: 'Insurance Details',
            body: (
                <InsuranceDetails />
            ),
        },
        {
            id: 'IdentityProofs',
            content: 'Identity Proofs',
            body: (
                <Identity />
            ),
        },
        {
            id: 'agreement',
            content: 'Agreement',
            body: (
                <Agreement />
            ),
        },
    ];

    useEffect(() => {
        setSelected(Number(localStorage.getItem("selctedprofiletab")));
        if(onboardingStatus === "incomplete"){
            setPageTitle("Doctor Onboarding");
            console.log(pageTitle);
        }
        else{
            setPageTitle("Doctor Details");
        }
    },[]);
    
    return (
        <Page title={pageTitle} fullWidth>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </Page>
    );
}