import React, { useState, useEffect } from 'react';
import { } from 'react-router-dom';
import {
  Page, Card, Layout, ExceptionList, Button, DataTable, TextStyle, Loading
} from '@shopify/polaris';

// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box } from '@primer/components';
import { PaymentsMajor, AccessibilityMajor, NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../ApiUrl';
import { Redirect, useParams } from 'react-router-dom';
interface AccountProps {
  onAction(): void;
}

export default function Payment() {
  const [toSignin, setToSignin] = useState(false);
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;
  const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
  const userToken = localStorage.getItem("token");
  const [consultationType, setConsultationType] = useState('');
  const [doctorTeamType, setDoctorTeamType] = useState('');
  const [responseTimeType, setResponseTimeType] = useState('');
  const [toastSuccessMessage, setToastSuccessMessage] = useState('');
  const [toastErrorMessage, setToastErrorMessage] = useState('');
  const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  function paymentLink(response: any) {
    window.open(response);
  }
  const [paymentDetailsId, setPaymentDetailsId] = useState('');
  function checkPaymentStatusDetails(id: any) {
    setPaymentDetailsId(id);
    checkPaymentStatusDetailsApicall(id);
  }

  function checkPaymentStatusDetailsApicall(id: any) {
    console.log("PAYMENTID==>", id);
    axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_status',
      { "paymentId": id }, { headers })
      .then(result => {
        setIsLoading(false);
        if (result) {
          setToastSuccessMessage(result.data.message);
          getPaymentList();
        }
      })
      .catch((error) => {
        setToastErrorMessage(error.response.data.message);
        setIsLoading(false);
      })
  }
  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Authorization': `Token ${userToken}`
  };

  const body = {
    "caseId": caseid
  };

  const getPaymentList = () => {
    setIsLoading(true);
    axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_list_for_owner', body, { headers })
      .then((res) => {
        setIsLoading(false);
        let resData = res.data;
        let consultationValue = (resData.consultationType === "") ? "Not Selected" : resData.consultationType;
        let responseTimeValue = (resData.responseTimeType === "") ? "Not Selected" : resData.responseTimeType;
        let doctorSelect = (resData.doctorTeamType === "") ? "Not Selected" : resData.doctorTeamType;
        setConsultationType(consultationValue);
        setResponseTimeType(responseTimeValue);
        setDoctorTeamType(doctorSelect);
        let paymentresData = res.data.paymentList;
        console.log("PAYMENTLIST==>", paymentresData);
        let paymentsValueList = [];
        for (var i = 0; i < paymentresData.length; i++) {
          let paymentid = paymentresData[i].id;
          let selectedResponse = paymentresData[i].paymentLink;
          let description = (paymentresData[i].note == "") ? "No Details Available" : paymentresData[i].note;
          let amount = (paymentresData[i].amount == "") ? "Amount Not Available" : paymentresData[i].amount;
          let round_amount = amount / 100;
          let createDate = (paymentresData[i].create_date == null) ? "" : (new Date(paymentresData[i].create_date)).toLocaleDateString('en-US', DATE_OPTIONS);
          let paymentstatus = (paymentresData[i].status === null) ? "" : paymentresData[i].status;
          console.log("DATEDATA==>", createDate);
          paymentsValueList.push([
            description,
            createDate,
            "Rs. " + round_amount,
            <Button icon={PaymentsMajor} size="slim" onClick={() => paymentLink(selectedResponse)}> Pay Now</Button>,
            <TextStyle>{paymentstatus}</TextStyle>,
            <Button icon={AccessibilityMajor} size="slim" onClick={() => checkPaymentStatusDetails(paymentid)}></Button>
          ]);
        }
        setChiefComplaintListValue(paymentsValueList);

      })
      .catch((error) => {
        setIsLoading(false);
        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            setToSignin(true);
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  }
  useEffect(() => {
    // Do api call here.... 
    // getPaymentList();
  }, []);

  const pagebody = (
    <Card>
      <Card.Header >
        <Button icon={RefreshMinor} primary={false} onClick={getPaymentList}>Reload Payment List</Button>
      </Card.Header>
      <Card.Section>
        {chiefComplaintListValue && chiefComplaintListValue.length ? (
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text'
            ]}
            headings={[
              'Description',
              'Create Date',
              'Amount',
              'Pay',
              'Payment Status',
              'Check Status'
            ]}
            rows={chiefComplaintListValue}
          />
        ) : (
          <ExceptionList
            items={[
              {
                icon: NoteMinor,
                description: 'No Payment Details is available!',
              },
            ]}
          />)}
      </Card.Section>
    </Card>
  )

  return (
    <div>
      <Page fullWidth title="Payment List" >
        {loadingMarkup}
        {pagebody}
      </Page>
    </div>
  );
}