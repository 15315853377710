import React, { useState } from "react";
interface IPlayer {
    name: string;
    yearsPro: number;
    position: string;
}
// Destructuring props in the function arguments.
export default function Player({
    player
  }: {
    player: IPlayer
  }) {
  return (
    <span>
      {player.name} ({player.yearsPro}) | Years pro: {player.position}
    </span>
  );
};
