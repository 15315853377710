import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    Thumbnail,
    Button,
    Loading,
    Page,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    TextStyle,
    ExceptionList,
    Subheading,
    Stack,
    Form
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, Uploader, Divider, TagGroup, Tag } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor, DeleteMinor } from '@shopify/polaris-icons';
import { useParams } from 'react-router-dom';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function FinalOpinion() {
    // Medicine list
    const [medVal, setMedVal] = useState<string>('');
    const [medicines, setMedicines] = useState<string[]>([]);
    const handleMedChange = useCallback((newValue) => setMedVal(newValue), []);

    const handleMedSubmit = (): void => {
        addMedicine(medVal);
        setMedVal('');
    }

    const addMedicine = (text: string): void => {
        const newMedicines: string[] = [...medicines, text];
        setMedicines(newMedicines);
    }

    const removeMedicine = (index: number): void => {
        setMedVal(medicines[index] + " is removed");
        const newMedicines: string[] = medicines;
        newMedicines.splice(index, 1);
        setMedicines(newMedicines);
    }
    // End of medicine list

    const doctorID = localStorage.getItem("doctorID");

    const { caseid } = useParams() as {
        caseid: string;
    };
    const { patientid } = useParams() as {
        patientid: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [patientname, setPatientname] = useState("");
    const handlePatientNameChange = useCallback((newValue) => setPatientname(newValue), []);

    const [address, setAddress] = useState("");
    const handleAddressChange = useCallback((newValue) => setAddress(newValue), []);

    const [age, setAge] = useState("");
    const handleAgeChange = useCallback((newValue) => setAge(newValue), []);

    //const [lmpDisable, setLMPDisable] = useState(false);

    const [gender, setGender] = useState("Male");
    const handleGenderChange = useCallback((newValue) => setGender(newValue), []);

    const [height, setHeight] = useState("");
    const handleHeightChange = useCallback((newValue) => setHeight(newValue), []);

    const [weight, setWeight] = useState("");
    const handleWeightChange = useCallback((newValue) => setWeight(newValue), []);

    const [lmp, setLMP] = useState("");
    const handleLMPChange = useCallback((newValue) => setLMP(newValue), []);

    const [spo2, setSPO2] = useState("");
    const handleSPO2Change = useCallback((newValue) => setSPO2(newValue), []);

    const [bpm, setBPM] = useState("");
    const handleBPMChange = useCallback((newValue) => setBPM(newValue), []);

    const [bloodPressure, setBloodPressure] = useState("");
    const handleBloodPressureChange = useCallback((newValue) => setBloodPressure(newValue), []);

    const [chiefcomplaint, setChiefcomplaint] = useState("");
    console.log("ChiefCOmplaintSummary", chiefcomplaint);
    const handleChiefcomplaintChange = useCallback((newValue) => setChiefcomplaint(newValue), []);

    const [relevanthistory, setRelevanthistory] = useState("");
    const handleRelevanthistoryChange = useCallback((newValue) => setRelevanthistory(newValue), []);

    const [examination, setExamination] = useState("");
    const handleExaminationChange = useCallback((newValue) => setExamination(newValue), []);

    const [diagnosis, setDiagnosis] = useState("");
    const handleDiagnosisChange = useCallback((newValue) => setDiagnosis(newValue), []);

    const [specialInstructions, setSpecialInstructions] = useState("");
    const handleSpecialInstructionsChange = useCallback((newValue) => setSpecialInstructions(newValue), []);

    const [investigation, setInvestigation] = useState("");
    const handleInvestigationChange = useCallback((newValue) => setInvestigation(newValue), []);

    const [nextVisitSchedule, setNextVisitSchedule] = useState("");
    const handleNextVisitScheduleChange = useCallback((newValue) => setNextVisitSchedule(newValue), []);

    const [prescriptionModalActive, setPrescriptionModalActive] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteprescriptionModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);
    const [deletePastHistoryModalSuccess, setDeletePrescriptionModalSuccess] = useState('');
    const closeSuccess = () => {
        setDeletePrescriptionModalSuccess('');
    }
    const successPastHistoryModalDiv = deletePastHistoryModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deletePastHistoryModalSuccess}</p>
        </Banner>
        : '';
    const [signetureImageInfo, setSignetureImageInfo] = useState('');
    const handlePrescriptionToggle = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setMedicines([]);
        setHeight("");
        setWeight("");
        setLMP("");
        setSPO2("");
        setBPM("");
        setBloodPressure("");
        setChiefcomplaint("");
        setRelevanthistory("");
        setExamination("");
        setDiagnosis("");
        setSpecialInstructions("");
        setInvestigation("");
        setGeneratePrescriptionSuccess("");
        setGeneratePrescriptionError("");
        setNextVisitSchedule("");
    }

    const [patientDetailsId, setPatientDetailsId] = useState('');
    const [finalOpinionListValue, setFinalOpinionListValue] = useState<any[]>([]);

    const [prescriptionListValue, setPrescriptionListValue] = useState<any[]>([]);

    const [toSignin, setToSignin] = useState(false);

    const [doctorName, setDoctorName] = useState("");
    const [doctorEmail, setDoctorEmail] = useState("");
    const [qualification, setQualification] = useState("");
    const [doctorAddress, setDoctorAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");

    const closeGeneratePrescriptionError = () => {
        setGeneratePrescriptionError('')
    }
    const [generatePrescriptionError, setGeneratePrescriptionError] = useState('');
    const generatePrescriptionErrorDiv = generatePrescriptionError
        ? <Banner
            onDismiss={closeGeneratePrescriptionError}
            status="critical">
            <p>{generatePrescriptionError}</p>
        </Banner>
        : '';

    const closeGeneratePrescriptionSuccess = () => {
        setGeneratePrescriptionSuccess('');
    }
    const [generatePrescriptionSuccess, setGeneratePrescriptionSuccess] = useState('');
    const generatePrescriptionSuccessDiv = generatePrescriptionSuccess
        ? <Banner
            onDismiss={closeGeneratePrescriptionSuccess}
            status="success">
            <p>{generatePrescriptionSuccess}</p>
        </Banner>
        : '';

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [finalOpinionDescription, setFinalOpinionDescription] = useState('');
    const handleFinalOpinionDescriptionChange = useCallback((newValue) => {
        setFinalOpinionDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const [finalOpinionDescriptionError, setFinalOpinionDescriptionError] = useState('');
    const finalOpinionDescriptionErrordiv = finalOpinionDescriptionError
        ? <TextStyle variation="negative">{finalOpinionDescriptionError}</TextStyle>
        : '';

    const closeFinalOpinionSuccess = () => {
        setFinalOpinionSuccess('');
    }
    const [finalOpinionSuccess, setFinalOpinionSuccess] = useState('');
    const finalOpinionSuccessDiv = finalOpinionSuccess
        ? <Banner
            onDismiss={closeFinalOpinionSuccess}
            status="success">
            <p>{finalOpinionSuccess}</p>
        </Banner>
        : '';

    const closeFinalOpinionError = () => {
        setFinalOpinionError('')
    }
    const [finalOpinionError, setFinalOpinionError] = useState('');
    const finalOpinionErrorDiv = finalOpinionError
        ? <Banner
            onDismiss={closeFinalOpinionError}
            status="critical">
            <p>{finalOpinionError}</p>
        </Banner>
        : '';

    const [finalOpinionModalActive, setModalActive] = useState(false);
    const handleFinalOpinionToggle = () => {
        setModalActive((finalOpinionModalActive) => !finalOpinionModalActive);
        setFinalOpinionDescription("");
        setFinalOpinionDescriptionError("");
        setImageInfo("");
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setFinalOpinionSuccess("");
        setFinalOpinionError("");
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Get Preloaded details API Body
    let getPrescriptionDetailsBody = {
        "caseId": caseid,
        "doctorId": doctorID
    }

    // Get Preloaded Prescription Details
    const getPrescriptionDetails = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/prescriptiongenerate/get_details_for_creating_prescription',
            getPrescriptionDetailsBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDoctorName(res.data.doctorname);
                setDoctorEmail(res.data.doctoremail);
                setPhoneNumber(res.data.phoneNumber);
                setQualification(res.data.qualification);
                setDoctorAddress(res.data.doctorAddress);
                setRegistrationNumber(res.data.registrationNumber);
                setPatientname(res.data.patientName);
                setAddress(res.data.patientAdress);
                setAge(res.data.patientAgeInyears);
                setGender(res.data.patientGender);
                setSignetureImageInfo(res.data.doctorSignature.doctorSignature.document);
            })
            .catch((error) => {
                setIsLoading(false);

            })
    }

    // Generate Prescription Body
    const generatePrescriptionBody = {
        "doctorId": doctorID,
        "caseId": caseid,
        "templateId": "1",
        "diagnosis": diagnosis,
        "height": height,
        "weight": weight,
        "LMP": lmp,
        "spO2": spo2,
        "BPM": bpm,
        "bloodPressure": bloodPressure,
        "chiefCompliants": chiefcomplaint,
        "relevantPointsFromHistory": relevanthistory,
        "examinationsLabFindings": examination,
        "suggestedInvestigations": investigation,
        "medicines": medicines,
        "specialInstructions": specialInstructions,
        "nextVisitSchedule": nextVisitSchedule,
        "signatureData": signetureImageInfo,
    }

    // Generate Prescription API
    const generatePrescription = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/generate-prescription/', generatePrescriptionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setGeneratePrescriptionSuccess(res.data.message);
                handlePrescriptionToggle();
                getFinalOpinionList();
                getPrescriptionList();
            })
            .catch((error) => {
                setIsLoading(false);
                setGeneratePrescriptionError(error.response.data.message);
            })
    }
    // Generate Prescription API
    const saveAsDraftPrescription = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/save-prescription/', generatePrescriptionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setGeneratePrescriptionSuccess(res.data.message);
                handlePrescriptionToggle();
                getPrescriptionList();
            })
            .catch((error) => {
                setIsLoading(false);
                setGeneratePrescriptionError(error.response.data.message);
            })
    }
    // Edit Prescription
    function editPrescription(response: any) {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/prescriptiongenerate/get_details_for_editing_prescription',
            { "caseId": caseid, "doctorId": doctorID, prescriptionId: response }, { headers })
            .then((res) => {
                setIsLoading(false);
                setHeight(res.data.prescription.height);
                setSPO2(res.data.prescription.spO2);
                setWeight(res.data.prescription.weight);
                setBPM(res.data.prescription.BPM);
                setLMP(res.data.prescription.LMP);
                setBloodPressure(res.data.prescription.bloodPressure);
                setChiefcomplaint(res.data.prescription.chiefCompliants);
                setRelevanthistory(res.data.prescription.relevantPointsFromHistory);
                setDiagnosis(res.data.prescription.diagnosis);
                setExamination(res.data.prescription.examinationsLabFindings);
                setInvestigation(res.data.prescription.suggestedInvestigations);
                setNextVisitSchedule(res.data.prescription.nextVisitSchedule);
                setSpecialInstructions(res.data.prescription.specialInstructions);
                setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
                let medicineArray: any = [];
                for (var i = 0; i < res.data.prescription.medicineList.length; i++) {
                    medicineArray.push([res.data.prescription.medicineList[i].filling_pharmacy_fullname]);
                }
                setMedicines(medicineArray);
            })
            .catch((error) => {
                setIsLoading(false);
                setGeneratePrescriptionError(error.response.data.message);
            })
    }
    function deletePrescription(response: any) {
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeletePrescriptionModalSuccess('');
    }

    // Open Chief complaint summary modal
    const [summaryModalActive, setSummaryModalActive] = useState(false);
    const openSummary = () => {
        getSummaryList();
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
    }
    const closeSummary = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
    }
    const selectedSummay = (summary: any) => {
        if (chiefcomplaint === "") {
            setChiefcomplaint(String(chiefcomplaint.concat(summary)));
        }
        else {
            setChiefcomplaint(String(chiefcomplaint.concat(" \n" + summary)));
        }
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setSummaryModalActive((summaryModalActive) => !summaryModalActive);
    }
    // Open Chief complaint summary modal
    const [diagnosisModalActive, setDiagnosisModalActive] = useState(false);
    const openDiagnosisSummary = () => {
        getDiagnosisSummaryList();
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setDiagnosisModalActive((diagnosisModalActive) => !diagnosisModalActive);
    }
    const closeDiagnosisSummary = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setDiagnosisModalActive((diagnosisModalActive) => !diagnosisModalActive);
    }
    const selectedDiagnosisSummay = (summary: any) => {
        if (diagnosis === "") {
            setDiagnosis(String(diagnosis.concat(summary)));
        }
        else {
            setDiagnosis(String(diagnosis.concat(" \n" + summary)));
        }
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setDiagnosisModalActive((diagnosisModalActive) => !diagnosisModalActive);
    }
    // Open Past History summary modal
    const [pastHistorySummaryModalActive, setPastHistorySummaryModalActive] = useState(false);
    const openPastHistorySummary = () => {
        getPastHistorySummaryList();
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setPastHistorySummaryModalActive((pastHistorySummaryModalActive) => !pastHistorySummaryModalActive);
    }
    const closePastHistorySummary = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setPastHistorySummaryModalActive((pastHistorySummaryModalActive) => !pastHistorySummaryModalActive);
    }
    const selectedPastHistorySummay = (summary: any) => {
        if (relevanthistory === "") {
            setRelevanthistory(String(relevanthistory.concat(summary)));
        }
        else {
            setRelevanthistory(String(relevanthistory.concat(" \n" + summary)));
        }
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setPastHistorySummaryModalActive((pastHistorySummaryModalActive) => !pastHistorySummaryModalActive);
    }
    // Open Test summary modal
    const [testSummaryModalActive, setTestSummaryModalActive] = useState(false);
    const openTestSummary = () => {
        getTestSummaryList();
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setTestSummaryModalActive((testSummaryModalActive) => !testSummaryModalActive);
    }
    const closeTestSummary = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setTestSummaryModalActive((testSummaryModalActive) => !testSummaryModalActive);
    }
    const selectedTestSummay = (summary: any) => {
        if (examination === "") {
            setExamination(String(examination.concat(summary)));
        }
        else {
            setExamination(String(examination.concat(" \n" + summary)));
        }
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setTestSummaryModalActive((testSummaryModalActive) => !testSummaryModalActive);
    }

    // GET Final Opinion List API Body
    const getSecondOpinionBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion"
    }

    // Get Final Opinion List API
    const getFinalOpinionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getSecondOpinionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                let FinalOpinionConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    setPatientDetailsId(patientDetailsID);
                    FinalOpinionConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deletePrescription(selectedResponse)}></Button>,
                    ]);
                    console.log("PatientDetailsList ====> ", FinalOpinionConfig);
                }
                setFinalOpinionListValue(FinalOpinionConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    // Final Opinion Body
    const secondOpinionBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion",
        "description": finalOpinionDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    // Final Opinion Image upload body
    const uploadbody = {
        'typeName': 'patient-image'
    };

    // Final Opinion Submit
    const secondOpinionSubmit = () => {
        console.log(secondOpinionBody);
        if (finalOpinionDescription === '') {
            setFinalOpinionDescriptionError("Please add description");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                secondOpinionBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setFinalOpinionError('');
                        setFinalOpinionDescriptionError('');
                        setFinalOpinionSuccess(result.data.message);
                        getFinalOpinionList();
                        handleFinalOpinionToggle();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setFinalOpinionDescriptionError('');
                    setFinalOpinionSuccess('');
                    setFinalOpinionError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }

    const deletePrescriptionBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    const DeletePrescriptionSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deletePrescriptionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeletePrescriptionModalSuccess(res.data.message);
                setDeleteModalActive((deleteModalActive) => !deleteModalActive);
                getFinalOpinionList();
            })

            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    // Get Chief Comlaint Summary List
    const [summaryValue, setSummaryValue] = useState<any[]>([]);
    const getSummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            { "caseId": caseid, "secondOpinionUiSection": "chiefCompliants" }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let summaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summaryId = resData[i].id;
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    summaryConfig.push([
                        <Button onClick={() => selectedSummay(summary)}>Select</Button>,
                        summary
                    ]);
                }
                setSummaryValue(summaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }
    // Get Diagnosis Summary List
    const [diagnosisSummaryValue, setDiagnosisSummaryValue] = useState<any[]>([]);
    const getDiagnosisSummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            { "caseId": caseid, "secondOpinionUiSection": "diagnosis" }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let diagnosisSummaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    diagnosisSummaryConfig.push([
                        <Button onClick={() => selectedDiagnosisSummay(summary)}>Select</Button>,
                        summary
                    ]);
                }
                setDiagnosisSummaryValue(diagnosisSummaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }
    // Get Past History Summary List
    const [pastHistorySummaryValue, setPastHistorySummaryValue] = useState<any[]>([]);
    const getPastHistorySummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            { "caseId": caseid, "secondOpinionUiSection": "pastHistory" }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let pastHistorySummaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summaryId = resData[i].id;
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    pastHistorySummaryConfig.push([
                        <Button onClick={() => selectedPastHistorySummay(summary)}>Select</Button>,
                        summary
                    ]);
                }
                setPastHistorySummaryValue(pastHistorySummaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }
    // Get Test Summary List
    const [testSummaryValue, setTestSummaryValue] = useState<any[]>([]);
    const getTestSummaryList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientSectionSummaryListByDoctor',
            { "caseId": caseid, "secondOpinionUiSection": "testsProceduresandInvestigations" }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.filePreparationSectionSummaryList;
                let testSummaryConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let summary = (resData[i].summaryText == null && resData[i].summaryText == "") ? "" : resData[i].summaryText;
                    testSummaryConfig.push([
                        <Button onClick={() => selectedTestSummay(summary)}>Select</Button>,
                        summary
                    ]);
                }
                setTestSummaryValue(testSummaryConfig);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Get Prescription List API
    const getPrescriptionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/prescriptionTemplate/list_prescription',
            { "caseId": caseid }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.prescriptionDetails;
                let prescriptionConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let dateStart = (resData[i].createDate === null) ? "" : (new Date(resData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].prescriptionFile === null) ? '' : resData[i].prescriptionFile.document;
                    setPatientDetailsId(patientDetailsID);
                    prescriptionConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        dateStart,
                        <Button icon={EditMinor} size="slim" onClick={() => editPrescription(patientDetailsID)}></Button>,
                        // <Button icon={DeleteMinor} size="slim" onClick={() => deletePrescription(selectedResponse)}></Button>,
                    ]);
                }
                setPrescriptionListValue(prescriptionConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }
    useEffect(() => {
        getPrescriptionDetails();
        getFinalOpinionList();
        getPrescriptionList();
    }, []);


    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const styles = {
        width: 65,
        height: 65,
        borderRadius: 30
    };

    // Chief Complaint Summary List Modal
    const chiefComplaintSummaryTable = (
        <Modal
            large
            open={summaryModalActive}
            onClose={closeSummary}
            title="View Chief Complaint Summary"
            // primaryAction={{
            //     content: "Submit",
            //     onAction: summarySubmit,
            // }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: closeSummary,
                },
            ]}
        >
            <Modal.Section>
                {summaryValue && summaryValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Select',
                            'Summary',
                        ]}
                        rows={summaryValue}
                    />

                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Chief Complaint Summary Details is available!',
                            },
                        ]}
                    />)}
            </Modal.Section>
        </Modal>
    )
    // Diagnosis Summary List Modal
    const diagnosisSummaryTable = (
        <Modal
            large
            open={diagnosisModalActive}
            onClose={closeDiagnosisSummary}
            title="View Diagnosis Summary"
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: closeDiagnosisSummary,
                },
            ]}
        >
            <Modal.Section>
                {diagnosisSummaryValue && diagnosisSummaryValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Select',
                            'Summary',
                        ]}
                        rows={diagnosisSummaryValue}
                    />

                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Diagnosis Summary Details is available!',
                            },
                        ]}
                    />)}
            </Modal.Section>
        </Modal>
    )
    // Past History Summary List Modal
    const pastHistorySummaryTable = (
        <Modal
            large
            open={pastHistorySummaryModalActive}
            onClose={closePastHistorySummary}
            title="View Past History Summary"
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: closePastHistorySummary,
                },
            ]}
        >
            <Modal.Section>
                {pastHistorySummaryValue && pastHistorySummaryValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Select',
                            'Summary',
                        ]}
                        rows={pastHistorySummaryValue}
                    />

                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Past History Summary Details is available!',
                            },
                        ]}
                    />)}
            </Modal.Section>
        </Modal>
    )
    // Test Summary List Modal
    const testSummaryTable = (
        <Modal
            large
            open={testSummaryModalActive}
            onClose={closeTestSummary}
            title="View Test Procedure Summary"
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: closeTestSummary,
                },
            ]}
        >
            <Modal.Section>
                {testSummaryValue && testSummaryValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Select',
                            'Summary',
                        ]}
                        rows={testSummaryValue}
                    />

                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Past History Summary Details is available!',
                            },
                        ]}
                    />)}
            </Modal.Section>
        </Modal>
    )
    // Final Opinion & Prescription Genrate Table
    const finalOpinionTable = (
        <div>
            <Card >
                <Card.Header title="Final opinion">
                    <Button primary={false} onClick={getFinalOpinionList}>Reload</Button>
                    <Button primary={false} onClick={handlePrescriptionToggle}>Add Prescription</Button>
                    <Button primary={true} onClick={handleFinalOpinionToggle}>Add Final Opinion Details</Button>
                </Card.Header>
                <Card.Section>
                    {finalOpinionListValue && finalOpinionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Prescribed Date',
                                'Delete'
                            ]}
                            rows={finalOpinionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Final Opinion Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )
    // Prescription Table
    const prescriptionTable = (
        <div>
            <Card >
                <Card.Header title="Prescription Table">
                    <Button primary={false} onClick={getPrescriptionList}>Reload</Button>
                </Card.Header>
                <Card.Section>
                    {prescriptionListValue && prescriptionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Document',
                                'Prescribed Date',
                                'Edit',
                            ]}
                            rows={prescriptionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Prescription Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    // Final Opinion Modal Body
    const finalOpinionPageMarkup = (
        <Modal
            large
            open={finalOpinionModalActive}
            onClose={handleFinalOpinionToggle}
            title={"Upload Document Of Final Opinion"}
            primaryAction={{
                content: 'Submit',
                onAction: secondOpinionSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Final Opinion Description"
                                        value={finalOpinionDescription}
                                        onChange={handleFinalOpinionDescriptionChange}
                                        placeholder="Please describe reason for final opinion"
                                        multiline={4}
                                        id="finalOpinionDescriptionID"
                                    />
                                    <InlineError message={finalOpinionDescriptionErrordiv} fieldID="finalOpinionDescriptionID" />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                            console.log(imageId);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{finalOpinionSuccessDiv}</div>
                        <div>{finalOpinionErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    )

    // Final Opinion & Generate Prescription Document Viewer Body
    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // Prescription Modal Body
    const prescriptionPageMarkup = (
        <Modal
            large
            open={prescriptionModalActive}
            onClose={handlePrescriptionToggle}
            title={"Add Prescription"}
            primaryAction={{
                content: 'Signed Prescription',
                onAction: generatePrescription,
            }}
            secondaryActions={[
                {
                    content: 'Save as Draft ',
                    onAction: saveAsDraftPrescription,
                },
            ]}
        >
            <Modal.Section>
                <Banner
                    title={doctorName}
                    status="info"
                >
                    <TextStyle variation="strong">Qualification: {qualification}</TextStyle><br />
                    <TextStyle variation="strong">Registration Number: {registrationNumber}</TextStyle><br />
                    <TextStyle variation="strong">Address: {doctorAddress}</TextStyle><br />
                    <TextStyle variation="strong">Phone Number: {phoneNumber}</TextStyle><br />
                    <TextStyle variation="strong">Dr. Email: {doctorEmail}</TextStyle>
                </Banner>
                <FormLayout>
                    <Card>
                        <Box p={2}>
                            <FormLayout.Group>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Prescribe Date</p>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                inputVariant="outlined"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={selectedDate}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={date => handleDateChange(date)}
                                                size="small"
                                                focused
                                                id="startDate"
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleDateChange(null)}>
                                                            <Icon icon="close" />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <TextField
                                        label="Age"
                                        value={age}
                                        onChange={handleAgeChange}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Gender"
                                        value={gender}
                                        onChange={handleGenderChange}
                                    />
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div>
                                    <TextField
                                        label="Name of Patient"
                                        value={patientname}
                                        onChange={handlePatientNameChange}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Height"
                                        value={height}
                                        onChange={handleHeightChange}
                                    />
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div>
                                    <TextField
                                        label="Address"
                                        value={address}
                                        onChange={handleAddressChange}
                                        multiline={2}
                                    />
                                    <p style={{ marginTop: "1rem" }}>SPO2</p>
                                    <TextField
                                        label=""
                                        value={spo2}
                                        onChange={handleSPO2Change}
                                    />
                                    <p style={{ marginTop: "1rem" }}>Blood Pressure</p>
                                    <TextField
                                        label=""
                                        value={bloodPressure}
                                        onChange={handleBloodPressureChange}
                                    />
                                </div>
                                {
                                    gender === "Female" ?
                                        <div>
                                            <TextField
                                                label="Weight"
                                                value={weight}
                                                onChange={handleWeightChange}
                                            />
                                            <p style={{ marginTop: "2.5rem" }}>LMP</p>
                                            <TextField
                                                label=""
                                                value={lmp}
                                                onChange={handleLMPChange}
                                            />
                                            <p style={{ marginTop: "1.5rem" }}>BPM</p>
                                            <TextField
                                                label=""
                                                value={bpm}
                                                onChange={handleBPMChange}
                                            />
                                        </div> :
                                        <div>
                                            <TextField
                                                label="Weight"
                                                value={weight}
                                                onChange={handleWeightChange}
                                            />
                                            <p style={{ marginTop: "1.5rem" }}>BPM</p>
                                            <TextField
                                                label=""
                                                value={bpm}
                                                onChange={handleBPMChange}
                                            />
                                        </div>
                                }
                            </FormLayout.Group>
                            <br />
                        </Box>
                    </Card>
                    <Divider />
                    <Card>
                        <Box p={2}>
                            <FormLayout.Group>
                                <div>
                                    <Box py={2}>
                                        <Stack>
                                            <Stack.Item fill>
                                                <label>Chief Complaints</label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button size="slim" onClick={() => openSummary()}>Get Summary</Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Box>
                                    <TextField
                                        label=""
                                        value={chiefcomplaint}
                                        onChange={handleChiefcomplaintChange}
                                        multiline={2}
                                    />
                                    <Box py={2}>
                                        <Stack>
                                            <Stack.Item fill>
                                                <label>Relevant Point of History</label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button size="slim" onClick={() => openPastHistorySummary()}>Get Summary</Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Box>
                                    <TextField
                                        label=""
                                        value={relevanthistory}
                                        onChange={handleRelevanthistoryChange}
                                        multiline={2}
                                    />
                                </div>
                                <div>
                                    <Box py={2}>
                                        <Stack>
                                            <Stack.Item fill>
                                                <label>Diagnosis or Provisional Diagnosis</label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button size="slim" onClick={() => openDiagnosisSummary()}>Get Summary</Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Box>
                                    <TextField
                                        label=""
                                        value={diagnosis}
                                        onChange={handleDiagnosisChange}
                                        multiline={7}
                                    />
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div>
                                    <Box py={2}>
                                        <Stack>
                                            <Stack.Item fill>
                                                <label>Examination / Lab Findings</label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button size="slim" onClick={() => openTestSummary()}>Get Summary</Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Box>
                                    <TextField
                                        label=""
                                        value={examination}
                                        onChange={handleExaminationChange}
                                        multiline={2}
                                    />
                                    <p style={{ marginTop: "1rem" }}>Suggested Investigations</p>
                                    <TextField
                                        label=""
                                        value={investigation}
                                        onChange={handleInvestigationChange}
                                        multiline={2}
                                    />
                                    <p style={{ marginTop: "1rem" }}>Next Visit Schedule</p>
                                    <TextField
                                        label=""
                                        value={nextVisitSchedule}
                                        onChange={handleNextVisitScheduleChange}
                                    />
                                </div>
                                <Stack>
                                    <Stack.Item fill>
                                        <Form onSubmit={handleMedSubmit}>
                                            <TextField
                                                label="Medicine"
                                                value={medVal}
                                                onChange={handleMedChange}
                                                connectedRight={<Button submit={true}>Add Medicine</Button>}
                                            />
                                        </Form>
                                        <br />
                                        <TagGroup>
                                            {medicines.map((medicine: string, index: number) => (
                                                <Tag
                                                    key={index}
                                                    closable
                                                    onClose={() => removeMedicine(index)}
                                                >
                                                    {medicine}
                                                </Tag>
                                            ))}
                                        </TagGroup>
                                    </Stack.Item>
                                </Stack>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <TextField
                                    label="Special Instructions"
                                    value={specialInstructions}
                                    onChange={handleSpecialInstructionsChange}
                                    multiline={2}
                                />
                            </FormLayout.Group>
                            <hr />
                            <FormLayout.Group>
                                <div>
                                    &nbsp;
                                </div>
                                <p>Doctor Signature :
                                    <img src={signetureImageInfo} alt="" width="100%" height="100%" /></p>
                            </FormLayout.Group>
                        </Box>
                    </Card>
                    <div>
                        <Box pt={2}>{generatePrescriptionSuccessDiv}</Box>
                        <Box pt={2}>{generatePrescriptionErrorDiv}</Box>
                    </div>
                </FormLayout>
            </Modal.Section>
        </Modal>
    );
    const deletePrescriptionComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteprescriptionModalChange}
            title={"Delete Prescription"}
            primaryAction={{
                content: "Yes",
                onAction: DeletePrescriptionSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteprescriptionModalChange,
                },
            ]}
            footer={successPastHistoryModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Prescription?
                    </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    return (
        <Page fullWidth>
            {loadingMarkup}
            {chiefComplaintSummaryTable}
            {diagnosisSummaryTable}
            {pastHistorySummaryTable}
            {testSummaryTable}
            {docViewerComponent}
            {prescriptionPageMarkup}
            {finalOpinionTable}
            {prescriptionTable}
            {finalOpinionPageMarkup}
            {deletePrescriptionComponent}
        </Page>
    );
}