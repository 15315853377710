import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    Stack,
    FormLayout,
    TextField,
    Banner,
    Thumbnail,
    Button,
    Loading,
    Collapsible,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    InlineError,
    Page,
    ExceptionList
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, SelectPicker, Uploader } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function DoctorEducationalPage() {
    const [imageId, setImageId] = useState('');
    const [open, setOpen] = useState(false);

    const [editProfessionalId, setEditProfessionaId] = useState('');

    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [licenseNumber, setLicenseNumber] = useState('');
    const handleLicenseNumberChange = useCallback((value) => setLicenseNumber(value), []);

    const [submitDegreeList, setsubmitDegreeListValues] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const handleSelectDegreeListChange = useCallback((value) => setSelectedSpeciality(value), []);
    const [selectedSpecialityError, setSelectedSpecialityError] = useState('');
    const selectedSpecialityErrordiv = selectedSpecialityError
        ? <InlineError message={selectedSpecialityError} fieldID="awardId" />
        : '';

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date(),);
    const handleDateChange = (date: Date | null) => { setSelectedDate(date); };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date(),);
    const handleEndDateChange = (enddate: Date | null) => { setSelectedEndDate(enddate); };

    const [description, setDescription] = useState('');
    const handleDescriptionChange = useCallback((value) => setDescription(value), []);

    const [modalActive, setModalActive] = useState(false);
    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
        setSelectedSpecialityError("");
        setProfessionalLicenseSuccess("");
        setProfessionalLicenseError("");
        setSelectedSpeciality("");
        setLicenseNumber("");
        setSelectedDate(new Date(),);
        setSelectedEndDate(null);
        setDescription("");
        setEditProfessionaId("");
    }
    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    // Add License List API
    const getLicenseDropdownList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/doctorlicense/doctor_licensetype_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setsubmitDegreeListValues(res.data.licenseTypeList)
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const body = {
        licenseTypeId: selectedSpeciality,
        idNumber: licenseNumber,
        fromDate: selectedDate,
        toDate: selectedEndDate,
        supportdocId: imageId,
        description: description
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeProfessionalLicenseError = () => {
        setProfessionalLicenseError('')
    }

    const [professionalLicenseError, setProfessionalLicenseError] = useState('');
    const errorProfessionalLicenseErrorDiv = professionalLicenseError
        ? <Banner
            onDismiss={closeProfessionalLicenseError}
            status="critical">
            <p>{professionalLicenseError}</p>
        </Banner>
        : '';

    const [professionalLicenseSuccess, setProfessionalLicenseSuccess] = useState('');
    const closeProfessionalLicenseSuccess = () => {
        setProfessionalLicenseSuccess('');
    }
    const successProfessionalLicenseSuccessDiv = professionalLicenseSuccess
        ? <Banner
            onDismiss={closeProfessionalLicenseSuccess}
            status="success">
            <p>{professionalLicenseSuccess}</p>
        </Banner>
        : '';

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const uploadbody = {
        'typeName': 'doctor-license'
    };

    function editProfessionalDetails(response: any) {
        setOpen(true);
        setModalActive((modalActive) => !modalActive);
        setSelectedSpecialityError("");
        setProfessionalLicenseSuccess("");
        setProfessionalLicenseError("");
        setEditProfessionaId(response.id);
        setLicenseNumber(response.idNumber);
        setSelectedSpeciality((response.idType == null) ? '' : response.idType.value);
        setSelectedDate(response.fromDate);
        setSelectedEndDate(response.toDate);
        setDescription(response.description);
    }

    //Get professional list
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [getProfessionalLicense, setGetProfessionalLicense] = useState<any[]>([]);
    const getProfessionalList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/doctorlicense/get_license_details_by_doctor', { headers }).then((res) => {
            setIsLoading(false);
            let response = res.data.licenseList;
            let professionLicenseList = [];
            for (var i = 0; i < response.length; i++) {
                let selectedResponse = response[i];
                let medicalLicenseName = (response[i].idType.label === "") ? '' : response[i].idType.label;
                let medicalLicenseNumber = (response[i].idNumber === "") ? '' : response[i].idNumber;
                let fromDate = response[i].fromDate === null ? '' : (new Date(response[i].fromDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                let toDate = response[i].toDate === null ? '' : (new Date(response[i].toDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                let description = (response[i].description === "" || response[i].description === null) ? "" : response[i].description;
                console.log(medicalLicenseNumber);
                let supportingDocuments = (response[i].supportingDocuments.length > 0) ? response[i].supportingDocuments[0].userFile.document : 'document.png';
                professionLicenseList.push([
                    <Button icon={NoteMinor} size="large" onClick={() => {
                        setDocViwerActive(true);
                        setImageInfo(supportingDocuments);
                    }}></Button>,
                    medicalLicenseName,
                    medicalLicenseNumber,
                    fromDate,
                    toDate,
                    truncate(description),
                    <Button icon={EditMinor} size="slim" onClick={() => editProfessionalDetails(selectedResponse)}></Button>
                ]);
            }
            setGetProfessionalLicense(professionLicenseList);
        })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Add Education Details
    const addLicense = () => {
        if (selectedSpeciality === '' || selectedSpeciality === null) {
            setSelectedSpecialityError("License type should not be empty");
            console.log("License type should not be empty");
        }
        else {
            setIsLoading(true);
            let url, professionSubmitBody;
            if (editProfessionalId) {
                url = baseUrl.baseUrl + '/doctorapp/api/doctorlicense/edit_doctor_license';
                professionSubmitBody = Object.assign(body, { licenseId: editProfessionalId })
                console.log(professionSubmitBody);
            }
            else {
                url = baseUrl.baseUrl + '/doctorapp/api/doctorlicense/add_doctor_license';

            }
            console.log(professionSubmitBody);
            axios.post(url, JSON.stringify(body), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setProfessionalLicenseSuccess(result.data.message);
                    setSelectedSpecialityError("");
                    getLicenseDropdownList();
                    getProfessionalList();
                }
            })
                .catch((error) => {
                    setProfessionalLicenseError(error.response.data.error);
                    setSelectedSpecialityError("");
                    setIsLoading(false);
                })
        }
    }

    useEffect(() => {
        getLicenseDropdownList();
        getProfessionalList();
    }, []);

    const getLicenseTableList = (
        <Card.Section>
            {getProfessionalLicense && getProfessionalLicense.length ? (
        <DataTable
            columnContentTypes={[
                'text',
                'text',
                'text',
                'text',
                'text',
            ]}
            headings={[
                'Document',
                'Medical License Name',
                'License Number',
                'Start date',
                'End date',
                'Description',
                'Edit'
            ]}
            rows={getProfessionalLicense}
        />
    ) : (
        <ExceptionList
            items={[
                {
                    icon: NoteMinor,
                    description: 'No Professional License Details is available!',
                },
            ]}
        />)}
</Card.Section>
)

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };

    const addProfessionalLicense = (
        <Modal large
            open={modalActive}
            onClose={handleToggle}
            title={editProfessionalId ? "Edit Professional License Details" : "Add Professional License Details"}
            primaryAction={{
                content: 'Submit',
                onAction: addLicense,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Select License</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectDegreeListChange}
                                        data={submitDegreeList}
                                        value={selectedSpeciality}
                                        menuStyle={{ color: "rgb(76, 2, 2) !important", zIndex: 1000 }}
                                    />
                                    <div>{selectedSpecialityErrordiv}</div>
                                </div>
                                <div>
                                    <TextField
                                        label="License Number"
                                        value={licenseNumber}
                                        onChange={handleLicenseNumberChange}
                                        placeholder="Please enter license number "
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageId(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />
                                                    )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <div style={{ color: 'gray' }}>Document size should be maximum 3MB.</div>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                  <IconButton onClick={() => handleDateChange(null)}>
                                                    <Icon icon="close" />                                                                                                                                                                                                                                                                                                       
                                                  </IconButton>
                                                )
                                              }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                  <IconButton onClick={() => handleEndDateChange(null)}>
                                                    <Icon icon="close" />                                                                                                                                                                                                                                                                                                       
                                                  </IconButton>
                                                )
                                              }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Please enter license description"
                                    multiline={2}
                                />
                            </div>
                        </FormLayout.Group>
                        <div>{successProfessionalLicenseSuccessDiv}</div>
                        <div>{errorProfessionalLicenseErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal >
    );
    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Professional License Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    const doctorProfessionalLicense = (
        <Box mt={3}>
            <Card >
                <Card.Header title="Professional License Details">
                    <Button primary={false} onClick={handleToggle}>Add Professional License Details</Button>
                </Card.Header>
                {getLicenseTableList}
            </Card>
            <br />
            <br />
            <Collapsible
                open={open}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
            </Collapsible>
        </Box>
    );
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }
    return (
        <Box>
            {loadingMarkup}
            {doctorProfessionalLicense}
            {addProfessionalLicense}
            {docViewerComponent}
        </Box>
    );
}