import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../../../ApiUrl';
import {
    Button,
    FormLayout,
    TextField,
    Loading,
    Form,
    Page,
    Card,
    DataTable,
    ExceptionList,
} from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { Redirect, useParams } from 'react-router-dom';

export default function DoctorTeleHealth() {

    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [key, setKey] = useState('');
    const handleKeyChange = useCallback((value) => setKey(value), []);
    const [secretKey, setSecretKey] = useState('');
    const handlesecretKeyChange = useCallback((value) => setSecretKey(value), []);

    const doctorId = localStorage.getItem("doctorID");

    const [telehealthData, setTelehealthValues] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const getCredentialBody = JSON.stringify({
        doctorId: doctorId
    });

    // Telehealth Credentials Submit body
    const telehealthCredentialbody = JSON.stringify({
        key: key,
        secret: secretKey,
        doctorId: doctorId

    });
    //handle button click of login form Tab-1
    const telehealthCredentialSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/setTelehealth_credential',
            telehealthCredentialbody, { headers })
            .then(result => {
                setIsLoading(false);
                getTelehealthList();
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Get Telehealth details List API
    const getTelehealthList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/getTelehealth_credential', getCredentialBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.telehealthData;
                let telehealthList = [];
                for (var i = 0; i < response.length; i++) {
                    let name = response[i].displayName === "" ? "" : response[i].displayName;
                    let doctorName = response[i].doctor === "" ? "" : response[i].doctor;
                    let telehealthAppName = response[i].teleHealthApp === "" ? "" : response[i].teleHealthApp;
                    let modifiedDate = response[i].modifiedDate === null ? "" : (new Date(response[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    telehealthList.push([
                        name,
                        doctorName,
                        telehealthAppName,
                        modifiedDate
                    ]);

                }
                setTelehealthValues(telehealthList);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        getTelehealthList();
    }, []);

    // telehelth creentials card
    const telehealthCredentialMarkup = (
        <Card>
            <Card.Header title="TeleHealth Credential"></Card.Header>
            <Card.Section>
                <Form onSubmit={telehealthCredentialSubmit}>
                    <FormLayout>
                        <TextField
                            value={key}
                            label="User Key"
                            placeholder="Enter your key id"
                            onChange={handleKeyChange}
                        />
                        <TextField
                            value={secretKey}
                            label="Secret Key"
                            placeholder="Enter your secret Key"
                            onChange={handlesecretKeyChange}
                        />
                        <Button fullWidth primary submit>Save</Button>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
    );

    // Telehealth credentials table
    const getTeleHealthCredential = (
        <Card>
            <Card.Header title="Telehealth Details">
                <Button primary={false} onClick={getTelehealthList}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {telehealthData && telehealthData.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Name',
                            'Doctor Name',
                            'Telehealth App name',
                            'Modified date'
                        ]}
                        rows={telehealthData}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Telehealth Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    return (
        <Page fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {telehealthCredentialMarkup}
            <br />
            {getTeleHealthCredential}
        </Page>

    );
}