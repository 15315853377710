import React, { useState, useEffect } from 'react';
import {
    Card,
    Page,
    Loading
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { createMuiTheme } from "@material-ui/core";
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});


export default function ScheduleSetings() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    useEffect(() => {
    }, []);

    const scheduleDetails = (
        <Card.Section>
        </Card.Section>
    )

    const workScheduleMarkup = (
        <Box>
            <Card>
                <Card.Section>
                    {scheduleDetails}
                </Card.Section>
            </Card>
        </Box>
    );

    return (
        <Page title="Schedule Settings" fullWidth separator>
            {loadingMarkup}
            {workScheduleMarkup}
        </Page>
    );
}
