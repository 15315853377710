import React, { useState, useCallback } from 'react';
import { Box, Flex } from '@primer/components';
import {
    Tabs,
    Page,
    Layout,
    DisplayText,
    Caption,
    Stack,
    TextStyle,
    Link,
    Button
} from '@shopify/polaris';
import LoginWithOTP from 'components/pages/UserOnboarding/LoginWithOTP';
import LoginWithPassword from 'components/pages/UserOnboarding/LoginWithPassword';
import AimsLogo from 'components/image/logo/aims2health-with-caption.png';
import FeatureImg from 'components/image/featureimage-doctor.png';
import { Divider } from 'rsuite';
import Schedule from './Schedule';
import ScheduleSettings from './ScheduleSettings';

export default function WorkSchedule() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

    const tabs = [

        {
            id: 'schedule',
            content: 'Schedule',
            body: (
                <Schedule />
            )
            ,
            panelID: 'emaillogin-content',
        },
        {
            id: 'settings',
            content: 'Settings',
            body: (
                <ScheduleSettings />

            ),
            accessibilityLabel: 'All customers',
            panelID: 'passwordlogin-content',
        },
    ];

    const actualPageMarkup = (
        <Layout>
            <Layout.Section oneHalf>
                <Box my={3} style={{ height: "350px" }}>
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} >
                        <Box py={3}>{tabs[selected].body}</Box>
                    </Tabs>
                </Box>
            </Layout.Section>
        </Layout>
    );

    return (
        <Page fullWidth title="Doctor's Work Schedule">
            {actualPageMarkup}
        </Page>

    );
}