import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    Button,
    Loading,
    Banner,
    Thumbnail,
    Page,
    Stack,
    RadioButton,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    InlineError,
    ExceptionList
} from '@shopify/polaris';
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../../ApiUrl';
import { Box } from '@primer/components';
import { Icon, Loader, SelectPicker, Uploader } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ProfessionSummary from "./ProfessionSummary"
import ProfessionalLicense from './ProfessionalLicense';
import ProfessionalMembership from './ProfessionalMembership';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function DoctorProfessionalDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [open, setOpen] = useState(false);
    console.log(open);
    const [imageId, setImageId] = useState('');
    const [editProfessionalId, setEditProfessionaId] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const handleInstituteChange = useCallback((value) => setInstituteName(value), []);
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');
    const [modalActive, setModalActive] = useState(false);
    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);
    // Start Date
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date(),);
    const handleDateChange = (date: Date | null) => { setSelectedDate(date) };
    // End Date
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date(),);
    const handleEndDateChange = (enddate: Date | null) => { setSelectedEndDate(enddate) };
    const [isAIIMSValue, setIsAIIMSValue] = useState('AIIMS');
    const isAIIMSValueChange = useCallback((_checked, newValue) => setIsAIIMSValue(newValue), []);
    const [submitDegreeList, setsubmitDegreeListValues] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const handleSelectDegreeListChange = useCallback((value) => setSelectedSpeciality(value), []);

    const [selectedSpecialityError, setselectedSpecialityError] = useState('');
    const selectedSpecialityErrordiv = selectedSpecialityError
        ? <InlineError message={selectedSpecialityError} fieldID="awardId" />
        : '';

    //const handleChange = useCallback(() => setActive(!active), [active]);

    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
        setselectedSpecialityError("");
        setIsAIIMSValue("AIIMS");
        setInstituteName("");
        setSelectedSpeciality("");
        setImageInfo("");
        setSelectedDate(new Date(),);
        setSelectedEndDate(null);
        setAddProfessionsuccess("");
        setEditProfessionaId("");
    }
    const professionBody = {
        isAIIMS: (isAIIMSValue === 'AIIMS') ? "True" : "False",
        institutename: instituteName,
        specialityId: String(selectedSpeciality),
        fromDate: selectedDate,
        toDate: selectedEndDate,
        supportdocId: imageId
    };

    const closeerror = () => {
        setError('')
    }
    const closeSuccess = () => {
        setAddProfessionsuccess('');
    }

    // Add Professional details success
    const [addProfessionsuccess, setAddProfessionsuccess] = useState('');
    const addProfessionSuccessDiv = addProfessionsuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addProfessionsuccess}</p>
        </Banner>
        : '';
    // Add professional details error
    const [error, setError] = useState('');
    const addProfessionErrorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    function editProfessionalDetails(response: any) {
        setOpen(true);
        setselectedSpecialityError("");
        setModalActive((modalActive) => !modalActive);
        setAddProfessionsuccess("");
        console.log("Existing Values", response);
        setIsAIIMSValue(response.isAIIMS ? 'AIIMS' : 'NONAIIMS');
        setEditProfessionaId(response.id);
        let docURL = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.document : 'document.png';
        let imgId = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.id : '';
        console.log("DOC URL = ", docURL);
        setImageInfo(docURL);
        setImageId(imgId);
        setInstituteName(response.name);
        setSelectedSpeciality((response.doctorSpecialty == null) ? '' : response.doctorSpecialty.id);
        setSelectedDate(response.fromDate);
        setSelectedEndDate(response.toDate);
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Get professional list
    const [workExperienceList, setWorkExperienceList] = useState<any[]>([]);
    const getProfessionalList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/work_experience_list', { headers }).then((res) => {
            setIsLoading(false);
            let response = res.data.workExperienceList;
            let professionList = [];
            for (var i = 0; i < response.length; i++) {
                let selectedResponse = response[i];
                let specialist = (response[i].doctorSpecialty == null) ? '' : response[i].doctorSpecialty.displayValue;
                let instituteName = response[i].name === "" ? "" : response[i].name;
                let fromDate = response[i].fromDate === null ? '' : (new Date(response[i].fromDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                let toDate = response[i].toDate === null ? '' : (new Date(response[i].toDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                let supportingDocuments = (response[i].supportingDocuments.length > 0) ? response[i].supportingDocuments[0].userFile.document : 'document.png';
                let doctorsAiims = response[i].isAIIMS ? 'AIIMS' : 'NON AIIMS';
                professionList.push([
                    <Button icon={NoteMinor} size="large" onClick={() => {
                        setDocViwerActive(true);
                        setImageInfo(supportingDocuments);
                    }}></Button>,
                    instituteName,
                    specialist,
                    fromDate,
                    toDate,
                    doctorsAiims,
                    <Button icon={EditMinor} size="slim" onClick={() => editProfessionalDetails(selectedResponse)}></Button>
                ]);
            }
            setWorkExperienceList(professionList);
        })
            .catch((error) => {
                setIsLoading(false);

                setError(error);
            })
    }

    // Speciality List dropdown.........
    const getSpecialityList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/speciality_list',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setsubmitDegreeListValues(res.data.doctorSpecialtyList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }


    // Add professional details submit
    const professionSubmit = () => {
        if (selectedSpeciality === "" || selectedSpeciality === null) {
            setselectedSpecialityError("Speciality should not be empty");
        }
        else {
            setIsLoading(true);
            let url, professionSubmitBody;
            if (editProfessionalId) {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_work_experience';
                professionSubmitBody = Object.assign(professionBody, { workexperienceId: editProfessionalId })
            }
            else {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/add_work_experience';

            }
            console.log(professionSubmitBody);
            axios.post(url, JSON.stringify(professionBody), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setAddProfessionsuccess('');
                    setError('');
                    setselectedSpecialityError("");
                    setAddProfessionsuccess(result.data.message);
                    getProfessionalList();
                    setOpen((open) => !open);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
                setError(error.response.data.message);
            });
        }
    }

    useEffect(() => {
        getProfessionalList();
        getSpecialityList();
    }, []);

    const uploadbody = {
        'typeName': 'work-experience-supporting-docs'
    };

    // Professional Details summary List (Resource List Body)
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const docProfDetails = (
        <Card.Section>
            {workExperienceList && workExperienceList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'numeric',
                        'numeric',
                        'numeric',
                        'numeric',
                    ]}
                    headings={[
                        'Document',
                        'Institute Name',
                        'Specialist',
                        'Start date',
                        'End date',
                        'Category',
                        'Edit'
                    ]}
                    rows={workExperienceList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Professional Experience is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const addDoctorWorkDetails = (
        <Modal large
            open={modalActive}
            onClose={handleToggle}
            title={editProfessionalId ? "Edit Work Experiences" : "Add Work Experiences"}
            primaryAction={{
                content: "Submit",
                onAction: professionSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <Stack>
                            <RadioButton
                                label="AIIMS"
                                helpText="For those who are / were from AIIMS."
                                checked={isAIIMSValue === 'AIIMS'}
                                id="AIIMS"
                                name="AIIMS"
                                onChange={isAIIMSValueChange}
                            />
                            <RadioButton
                                label="Non-AIIMS"
                                helpText="For those who are from Other Institutes."
                                id="NONAIIMS"
                                name="NONAIIMS"
                                checked={isAIIMSValue === 'NONAIIMS'}
                                onChange={isAIIMSValueChange}
                            />
                        </Stack>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Institute Name"
                                    value={instituteName}
                                    onChange={handleInstituteChange}
                                    placeholder="Please enter institute name "
                                />
                                <br />
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Speciality</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectDegreeListChange}
                                        data={submitDegreeList}
                                        value={selectedSpeciality}
                                        menuStyle={{ color: "rgb(76, 2, 2) !important", zIndex: 1000 }}
                                    />
                                    <div>{selectedSpecialityErrordiv}</div>
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageId(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Thumbnail source={NoteMinor} size="large" alt="Small document" />
                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <div style={{ color: 'gray' }}>Document size should be maximum 3MB.</div>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            focused
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            focused
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{addProfessionSuccessDiv}</div>
                        <div>{addProfessionErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const actualPageMarkup = (
        <Box>
            <Card>
                <Card.Header
                    title="Work Experiences"
                >
                    <Button primary={true} onClick={handleToggle}>Add Work Experiences</Button>
                </Card.Header>
                {docProfDetails}
            </Card>
            {addDoctorWorkDetails}
        </Box>
    );
    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Work Experience Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    return (
        <Page>
            {loadingMarkup}
            <ProfessionSummary />
            {actualPageMarkup}
            <ProfessionalLicense />
            {docViewerComponent}
            <ProfessionalMembership />
        </Page>
    );
}