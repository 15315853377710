import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Button,
    Loading,
    Page,
    Stack,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    ExceptionList,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, SelectPicker, Uploader } from 'rsuite';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});
initializeIcons();

export default function DoctorEducationalDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);
    const [noOfNoticeReceived, seNoOfNoticeReceived] = useState('');
    const handleNoOfNoticeReceivedChange = useCallback((value) => seNoOfNoticeReceived(value), [],);

    const [noOfCasesPending, setNoOfCasesPending] = useState('');
    const handleNoOfCasesPendingChange = useCallback((value) => setNoOfCasesPending(value), [],);

    const [noOfDeathDueToNegligence, setNoOfDeathDueToNegligence] = useState('');
    const handleNoOfDeathDueToNegligenceChange = useCallback((value) => setNoOfDeathDueToNegligence(value), [],);

    const [value1, setValue1] = React.useState('False');

    const handleChange1 = useCallback((_checked, newValue) => setValue1(newValue), []);

    const [value2, setValue2] = React.useState('False');

    const handleChange2 = useCallback((_checked, newValue) => setValue2(newValue), []);

    const [description, setDescription] = useState('');
    const handleDescriptionChange = useCallback((value) => setDescription(value), [],);

    const [selected, setSelected] = useState('');

    const [imageId, setImageId] = useState('');
    const [imageInfo, setImageInfo] = useState('');
    const [uploading] = React.useState(false);

    const [legalDetailsTypeValues, setLegalDetailsTypeValues] = useState([]);
    const handleSelectChange = useCallback((value) => setSelected(value), []);

    // Start Date
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    // End Date
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (enddate: Date | null) => {
        setSelectedEndDate(enddate);
    };

    const [professionSummarySuccess, setprofessionSummarySuccess] = useState('');
    const [legalDetailsList, setLegalDetailsValues] = useState<any[]>([]);

    const closeSuccess = () => {
        setprofessionSummarySuccess('');
        setprofessionSummarySubmitError('');
        setlegaldetailsSubmitAPIRes('')
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    // Profession summary edit success
    const professionsuccessDiv = professionSummarySuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{professionSummarySuccess}</p>
        </Banner>
        : '';

    // professional summary edit error
    const [professionSummarySubmitError, setprofessionSummarySubmitError] = useState('');
    const professionSummarySubmitErrorDiv = professionSummarySubmitError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{professionSummarySubmitError}</p>
        </Banner>
        : '';

    const body = {
        isCasePending: value1,
        isDeathDueToNegligence: value2,
        description: description,
        legaltypeId: selected,
        fromDate: selectedDate,
        toDate: selectedEndDate,
        supportdocId: imageId
    };

    const closeerror = () => {
        setError('')
    }

    // add api error banner
    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    // add api success banner
    const [legaldetailsSubmitAPIRes, setlegaldetailsSubmitAPIRes] = useState('');
    const successDiv = legaldetailsSubmitAPIRes
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{legaldetailsSubmitAPIRes}</p>
        </Banner>
        : '';

    const [selectedError, setSelectedError] = useState('');
    const selectedErrordiv = selectedError
        ? <TextStyle variation="negative">{selectedError}</TextStyle>
        : '';

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };

    // Image upload Body
    const uploadbody = {
        'typeName': 'doctor-legal-details'
    };

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const [addLegalDetailsModalActive, setAddLegalDetailsModalActiveModalActive] = useState(false);
    const [legalDetailsId, setlegalDetailsId] = useState('');
    // Modal on function
    function addLegal() {
        setAddLegalDetailsModalActiveModalActive((addLegalDetailsModalActive) => !addLegalDetailsModalActive);
        setValue1('False');
        setValue2('False');
        setDescription('');
        setSelected('');
        setImageId('');
        setImageInfo('');
        setSelectedDate(new Date(),);
        setSelectedEndDate(null);
        setlegalDetailsId('');
        setlegaldetailsSubmitAPIRes("");
        setError("");
    }

    function editLegalDetails(response: any) {
        setAddLegalDetailsModalActiveModalActive((addLegalDetailsModalActive) => !addLegalDetailsModalActive);
        setlegaldetailsSubmitAPIRes("");
        setError("");
        setprofessionSummarySubmitError("");
        setlegalDetailsId(response.id);
        setSelected((response.legalType == null) ? '' : response.legalType.value);
        setDescription(response.description);
        setSelectedDate(response.fromDate);
        setSelectedEndDate(response.toDate);
        setValue1(response.isCasePending ? 'True' : 'False');
        setValue2(response.isDeathDueToNegligence ? 'True' : 'False');
        let supportingDocumentsId = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].id : '';
        setImageId(supportingDocumentsId);
        let supportingDocuments = '';
        (response.supportingDocuments.length > 0) ? supportingDocuments = response.supportingDocuments[0].userFile.document : supportingDocuments = 'document.png';
        // (response.supportingDocuments) ? supportingDocuments = response.supportingDocuments[0].userFile.document : supportingDocuments = 'document.png';
        setImageInfo(supportingDocuments);
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Get Education Summary
    const getLegalSummary = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_legal_summary', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let a = res.data.legalSummaryDetails.noOfNoticeReceived;
                let b = a.toString();
                seNoOfNoticeReceived(b);
                let c = res.data.legalSummaryDetails.noOfCasesPending;
                let d = c.toString();
                setNoOfCasesPending(d);
                let e = res.data.legalSummaryDetails.noOfDeathDueToNegligence;
                let f = e.toString();
                setNoOfDeathDueToNegligence(f);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const professionSummabody = JSON.stringify({
        noOfNoticeReceived: noOfNoticeReceived,
        noOfCasesPending: noOfCasesPending,
        noOfDeathDueToNegligence: noOfDeathDueToNegligence
    });

    const professionSummarySubmit = () => {
        console.log(professionSummabody)
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_legal_summary',
            professionSummabody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setprofessionSummarySubmitError('');
                    setprofessionSummarySuccess(result.data.message);
                    getLegalSummary();
                }
            })
            .catch((error) => {
                console.log(error.response);
                setprofessionSummarySuccess('');
                setprofessionSummarySubmitError(error.response.data.message);
                setIsLoading(false);
            })
    }

    const getLegaldetailsList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_legal_details_by_doctor',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.LegalList;
                let legalDetailsList = [];
                for (var i = 0; i < response.length; i++) {
                    let legaltype = (response[i].legalType === null) ? '' : response[i].legalType.label;
                    let casePending = response[i].isCasePending === true ? "Yes" : "No";
                    console.log(casePending);
                    let deathDueToNegligence = response[i].isDeathDueToNegligence === true ? "Yes" : "No";
                    let fromDate = (response[i].fromDate == null) ? '' : response[i].fromDate;
                    let todate = (response[i].toDate === null) ? '' : response[i].toDate;
                    let description = (response[i].description == null || response[i].description === "") ? "" : truncate(response[i].description);
                    let selectedResponse = response[i];
                    let supportingDocuments = (response[i].supportingDocuments.length > 0) ? response[i].supportingDocuments[0].userFile.document : '';
                    legalDetailsList.push([
                        <Button icon={NoteMinor} size="slim" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        legaltype,
                        casePending,
                        deathDueToNegligence,
                        (new Date(fromDate)).toLocaleDateString('en-US', DATE_OPTIONS),
                        (new Date(todate)).toLocaleDateString('en-US', DATE_OPTIONS),
                        description,
                        <Button icon={EditMinor} size="slim" onClick={() => editLegalDetails(selectedResponse)}></Button>
                    ]);
                    setLegalDetailsValues(legalDetailsList);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    // Legal details typpe API
    const getLegalDetailsTypesApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/legal_type_list',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setLegalDetailsTypeValues(res.data.legalTypeList);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.response.data.message);
            })
    }

    // Legal details submit API
    const legaldetailsSubmit = () => {
        setIsLoading(true);
        let url, legalSubmitBody;
        if (legalDetailsId) {
            url = baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_doctor_legalDetails';
            legalSubmitBody = Object.assign(body, { legalDetailsId: legalDetailsId });
            console.log("BODY==>", legalSubmitBody);
        }
        else {
            url = baseUrl.baseUrl + '/doctorapp/api/onboarding/add_legal_details';

        }
        axios.post(url, JSON.stringify(body), { headers }).then(result => {
            setIsLoading(false);
            if (result) {
                console.log("SUCCESS==>", result);
                setSelectedError('');
                setlegaldetailsSubmitAPIRes(result.data.message);
                setError('');
                getLegaldetailsList();
            }
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
            setlegaldetailsSubmitAPIRes('');
            setError(error.response.data.message);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getLegalSummary();
        getLegaldetailsList();
        getLegalDetailsTypesApi();
    }, []);

    const docViewermodal = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Legal details document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // ModalMarkUp
    const modalMarkup = (
        <Modal
            large
            open={addLegalDetailsModalActive}
            onClose={addLegal}
            title={legalDetailsId ? "Edit Legal Details" : "Add Legal Details"}
            primaryAction={{
                content: 'Submit',
                onAction: legaldetailsSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <label>Pending cases</label>
                                <RadioGroup aria-label="pending-cases" name="pending-cases" value={value1} onChange={handleChange1}>
                                    <div>
                                        <FormControlLabel value="True" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="False" control={<Radio />} label="No" />
                                    </div>
                                </RadioGroup>
                            </div>
                            <div>
                                <label>Death cases due to negligence</label>
                                <RadioGroup aria-label="death-cases" name="death-cases" value={value2} onChange={handleChange2}>
                                    <div>
                                        <FormControlLabel value="True" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="False" control={<Radio />} label="No" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Cases Pending at</p>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectChange}
                                        data={legalDetailsTypeValues}
                                        value={selected}
                                        id='legaltypes'
                                        menuStyle={{ color: "rgb(76, 2, 2) !important", zIndex: 1000 }}
                                    //disabled = {iseditable}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        label="Description"
                                        type="text"
                                        placeholder="Please enter legal details"
                                        id="legalDescription"
                                        multiline={2}
                                    //readOnly = {iseditable}
                                    />
                                    <div><InlineError message={selectedErrordiv} fieldID="legaltypes" /></div>
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload supporting document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Stack>
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                                id='imageUpload'
                                            //disabled = {iseditable}
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} alt="" width="100%" height="100%" />
                                                    ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                    )}
                                                </button>
                                            </Uploader>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>

                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Case Pending From</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        //readOnly = {iseditable}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>

                            <div>
                                <p style={{ marginBottom: "5px" }}>Case Pending Till</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        //readOnly = {iseditable}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>
                            {successDiv}
                            {errorDiv}
                        </div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const professionSummary = (
        <div>
            <Card
                primaryFooterAction={{ content: 'Submit Summary', onAction: professionSummarySubmit, disabled: isEnableSubmit }}
            >
                <Card.Header
                    actions={[{
                        content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                            setIsEdit((isEdit) => !isEdit);
                            setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                        }
                    }]}
                    title="Legal Details Summary"
                >
                </Card.Header>
                <Box p={3}>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <TextField
                                label="Number of notice received"
                                type="number"
                                value={noOfNoticeReceived}
                                onChange={handleNoOfNoticeReceivedChange}
                                placeholder="Enter no. of notice received"
                                readOnly={isEdit}
                            />
                            <TextField
                                label="Number of pending cases"
                                type="number"
                                value={noOfCasesPending}
                                onChange={handleNoOfCasesPendingChange}
                                placeholder="Enter no. of pending cases"
                                readOnly={isEdit}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="No. of death cases due to negligence"
                                    type="number"
                                    value={noOfDeathDueToNegligence}
                                    onChange={handleNoOfDeathDueToNegligenceChange}
                                    placeholder="Enter no. of death cases"
                                    readOnly={isEdit}
                                />
                            </div>
                                &nbsp;
                            </FormLayout.Group>
                        <div>{professionsuccessDiv}</div>
                        <div>{professionSummarySubmitErrorDiv}</div>
                    </FormLayout>
                </Box>
            </Card>
        </div>
    );

    const legalList = (
        <Card.Section>
            { legalDetailsList && legalDetailsList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                    ]}
                    headings={[
                        'Document',
                        'Cases Pending at',
                        'Pending cases',
                        'Death cases due to negligence',
                        'Case Pending From',
                        'Case Pending Till',
                        'Description',
                        'Edit',

                    ]}
                    rows={legalDetailsList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Legal Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const doctorAwardInfo = (
        <div>
            {loadingMarkup}
            <Card>
                <Card.Header
                    title="Legal Details"
                >
                    <Button primary={true} onClick={addLegal}>Add Legal Details</Button>
                </Card.Header>
                {legalList}
            </Card>
        </div>
    )
    return (
        <Page>
            {professionSummary}
            <br />
            {doctorAwardInfo}
            {modalMarkup}
            {docViewermodal}
        </Page>
    );
}