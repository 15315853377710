import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Banner,
    Button,
    FormLayout,
    InlineError,
    TextField,
    TextStyle,
    Loading,
    Form,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from 'ApiUrl';

export default function LoginWithOTP() {

    const [otpemail, setOtpEmail] = useState('');
    const [errorotpemail, setErrorOtpEmail] = useState('');
    const [isLoadingforotpgenerate, setIsLoadingforotpgenerate] = useState(false);
    const [isLoadingforotpvalidate, setIsLoadingforotpvalidate] = useState(false);
    const [isDisableOTPField, setIsDisableOTPField] = useState(true);
    const [otpsuccess, setOtpSuccess] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpinvlid, setOtpInvalid] = useState('');
    const [buttonText, setButtonText] = useState('Send OTP');
    const [validotp, setvalidOtp] = useState('');
    const [errorotp, setOtp] = useState('');

    const history = useHistory();

    const handleEmailChange = useCallback((value) => setOtpEmail(value), []);
    const handleValidOtpChange = useCallback((value) => setvalidOtp(value), []);

    const errorOtpEmail = errorotpemail
        ? <TextStyle variation="negative">{errorotpemail}</TextStyle>
        : '';

    const closeSuccessBanner = () => {
        setOtpSuccess('');
    }

    const closeErrorBanner = () => {
        setOtpInvalid('');
    }

    const OTPText = otpsuccess
        ? <Banner status="success" onDismiss={closeSuccessBanner}><p>{otpsuccess}</p></Banner>
        : '';
    const closeErrorOTPBanner = () => {
        setOtpError('');
    }
    const OTPTextFails = otpError
        ? <Banner status="critical" onDismiss={closeErrorOTPBanner}><p>{otpError}</p></Banner>
        : '';
    const errorOtp = errorotp
        ? <TextStyle variation="negative">{errorotp}</TextStyle>
        : '';

    const wrongOtp = otpinvlid
        ? <Banner status="critical" title={otpinvlid} onDismiss={closeErrorBanner}></Banner>
        : '';

    const sectionForm = isLoadingforotpgenerate ? <Loading /> : null;

    const sectionFormforvalidateotp = isLoadingforotpvalidate ? <Loading /> : null;

    const emailotpbody = JSON.stringify({
        email: otpemail.toLowerCase()
    });

    const otpbody = JSON.stringify({
        email: otpemail.toLowerCase(),
        otp: validotp
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    //handle button click of otp generation
    const handleLoginwithotp = () => {
        if (otpemail === '') {
            setErrorOtpEmail("Please enter email id or phone number");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(otpemail.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(otpemail.toLowerCase())) === false) {
            setErrorOtpEmail('Please enter valid email id or phone number');
        }
        else {
            setIsLoadingforotpgenerate(true);
            setErrorOtpEmail('');
            // setOtpEmail('');
            axios.post(baseUrl.baseUrl + '/doctorapp/email-otp-generation/',
                emailotpbody, { headers })
                .then(result => {
                    setIsLoadingforotpgenerate(false);
                    if (result) {
                        setOtpSuccess(result.data.message);
                        setOtpError("");
                        setIsDisableOTPField(false);
                        setButtonText("Resend OTP");
                    }
                    else {
                        history.push("/signin");
                    }
                })
                .catch((error) => {
                    // setOtpEmail(error.response.data.non_field_errors[0]);
                    setOtpError(error.response.data.message);
                    setIsLoadingforotpgenerate(false);
                    setButtonText("Send OTP");
                })
        }
    }

    // otp validation
    const handleLoginwithValidotp = () => {
        if (validotp === '') {
            setOtp("Please enter valid Otp");
            return;
        }
        else {
            setIsLoadingforotpvalidate(true);
            // setOtpEmail('');
            axios.post(baseUrl.baseUrl + '/doctorapp/email-otp-auth-login/',
                otpbody, { headers })
                .then(result => {
                    setOtpError("");
                    localStorage.setItem("onBoarding", result.data.onboardingstatus);
                    localStorage.setItem("token", result.data.token);
                    localStorage.setItem("email", result.data.user.email);
                    localStorage.setItem("name", result.data.user.username);
                    localStorage.setItem("firstname", result.data.user.first_name);
                    localStorage.setItem("lastname", result.data.user.last_name);
                    localStorage.setItem("id", result.data.user.id);
                    localStorage.setItem("onBoarding", result.data.onboardingstatus);
                    localStorage.setItem("onboardingrequestId", result.data.onboardingrequestId);
                    localStorage.setItem("onboardingrequestStatus", result.data.onboardingstatus);
                    localStorage.setItem("doctorID", result.data.doctorId);
                    setIsLoadingforotpvalidate(false);
                    if (result.data.onboardingstatus === 'incomplete') {

                        history.push("/userprofile");
                        window.location.reload();

                    }
                    else {
                        history.push("/dashboard");
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    setOtpInvalid(error.response.data.messageerror.response.data.message || error.response.data.non_field_errors[0]);
                    setIsLoadingforotpvalidate(false);
                })
        }
    }

    const otpText = buttonText;

    const actualPageMarkup = (
        <FormLayout>
            <Form onSubmit={handleLoginwithotp}>
                <FormLayout>
                    <TextField
                        value={otpemail}
                        label="Email or Phone Number"
                        placeholder="Enter your Email or Phone Number"
                        id="emailFieldID"
                        onChange={handleEmailChange}
                    />
                    <InlineError message={errorOtpEmail} fieldID="otpemailFieldID" />
                    <div>{OTPTextFails}</div>
                    <div>
                        <Button fullWidth primary submit>{otpText}</Button>
                    </div>
                </FormLayout>
            </Form>
            {/*............ for validate otp code .............. */}
            <Form onSubmit={handleLoginwithValidotp}>
                <FormLayout>
                    <TextField
                        value={validotp}
                        label="OTP"
                        placeholder="Enter provided OTP"
                        id="OTPFieldID"
                        onChange={handleValidOtpChange}
                        disabled={isDisableOTPField}
                    />
                    <InlineError message={errorOtp} fieldID="OTPFieldID" />
                    <Button fullWidth primary submit disabled={isDisableOTPField}>Log In</Button>
                    <div>{OTPText}</div>
                    <div>{wrongOtp}</div>
                </FormLayout>
            </Form>
        </FormLayout>
    );

    return (
        <div>
            {actualPageMarkup}
            {sectionForm}
            {sectionFormforvalidateotp}
        </div>
    );
}