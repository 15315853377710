import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    DescriptionList,
    ExceptionList,
    Loading,
    Page,
    Toast,
} from '@shopify/polaris';
import { useParams, useHistory } from 'react-router-dom';
import { NoteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../ApiUrl';
import { getDoctorTaskList } from 'redux/effects/ApiCall';
import { AppState } from 'redux/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { TaskList } from 'redux/interfaces/Model';

export default function TaskDetails() {
    const dispatch = useDispatch();
    const { patientId, caseId, index } = useParams() as {
        patientId: string,
        caseId: string,
        index: string
    };

    //let arrayIndex = Number(indexNo);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [successToastactive, setSuccessToastactive] = useState(false);
    const successToasttoggleActive = useCallback(() => setSuccessToastactive((successToastactive) => !successToastactive), []);
    const successToastMarkup = successToastactive ? (
        <Toast content={successToastMessage} onDismiss={successToasttoggleActive} />
    ) : null;

    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [errorToastactive, setErrorToastactive] = useState(false);
    const errorToasttoggleActive = useCallback(() => setErrorToastactive((errorToastactive) => !errorToastactive), []);
    const errorToastMarkup = errorToastactive ? (
        <Toast content={errorToastMessage} error onDismiss={errorToasttoggleActive} />
    ) : null;

    // Call Store
    const taskReduxValue = useSelector((state: AppState) => state.taskValue);
    const taskDetails: TaskList = taskReduxValue.taskValue[Number(index)];

    function gotoCaseDetails() {
        history.push(`/appointmentdetails/${taskDetails.patientId}/${taskDetails.caseId}/${index}`);
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const acceptBooking = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment',
            { "caseId": caseId, "requestStatus": Number(1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }
    const rejectBooking = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment',
            { "caseId": caseId, "requestStatus": Number(-1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }

    const taskDetailsView = (
        <Card>
            <Card.Header title={(taskDetails === undefined) ? "" : (taskDetails.patientName)}>
            <Button primary={true} onClick={gotoCaseDetails}>View Case Details</Button>
                <Button primary= {false} onClick={acceptBooking}>Accept Appointment</Button>
                <Button onClick={rejectBooking}>Reject Appointment</Button>
            </Card.Header>
            <Card.Section>
                { taskDetails ? (
                    <DescriptionList
                    items={[
                        {
                            term: 'Title',
                            description:`${taskDetails.title}`
                        },
                        {
                            term: 'Second Opinion',
                            description:`${taskDetails.whyWant2ndOpinion}`
                        },
                        {
                            term: '2ndOpinion Reason ',
                            description:`${taskDetails.secondOpinionReasons}`
                        },
                        {
                            term: 'Consultation Type',
                            description:`${taskDetails.consultationType}`
                        },
                        {
                            term: 'Note',
                            description:`${taskDetails.note}` 
                        },
                        {
                            term: 'Create Date',
                            description:`${taskDetails.created_date}` 
                        },
                    ]}
                />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Task Details is available!',
                            },
                        ]}
                    />)}
                
                
            </Card.Section>
        </Card>
    )
    useEffect(() => {
        if(taskDetails === undefined){
            dispatch(getDoctorTaskList());
        }
    }, [dispatch, taskDetails]);
    return (
        <Page fullWidth title="Task Details"  breadcrumbs={[{content: 'TaskList', url: '/dashboard'}]}>
            {taskDetailsView}
            {successToastMarkup}
            {errorToastMarkup}
            {loadingMarkup}
        </Page>
    );
}