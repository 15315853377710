import React, { Component } from 'react';
import {
    FormLayout,
    Page,
} from '@shopify/polaris';
import Task from './Task';
class Dashboard extends Component {

    render() {
        const currentUserId = localStorage.getItem("id"); // Get this from your auth system
        console.log("Device token api call");
        let idStr = String(currentUserId);
        console.log('userIdStr= ' + idStr);

        let doctorName = localStorage.getItem("firstname") + ' ' + localStorage.getItem("lastname");
        console.log(doctorName);

        const pageBody = (
            <div>
                <FormLayout.Group condensed>
                    <Task />
                </FormLayout.Group>
            </div>
        )
        return (
            <Page title="Doctor's Dashboard" fullWidth separator>
                {pageBody}
            </Page>
        )
    }
}

export default Dashboard;