import React from 'react';
import { } from 'react-router-dom';
import {
    Card,
    DisplayText,
    List,
    Page,
    TextStyle
} from '@shopify/polaris';

interface AccountProps {
    onAction(): void;
}

export default function CodeOfConduct() {

    const pagebody = (
        <div>
            <Card title="Code of Conduct for Doctors on AIMS 2Health Platform" sectioned>
                <p>To get the best out of healthcare, telemedicine can construe to high-value care if it succeeds in delivering the right care, to the right patient, at the right time, in the right setting. Patient’s access to care, clinical outcomes, patient satisfaction, and expenditure, while preserving interoperability and continuity of care, are central to success of telemedicine. Keeping in view the Telemedicine Practice Guidelines  of the MCI/Ministry of Health & Family Welfare, the follow guidance is provided to the Specialist doctors on the AIMS2Health Web Platform:</p>
            </Card>

            <Card title="Insurance cover" sectioned>
                <List>
                    <List.Item>Please ensure that your professional medical indemnity cover includes cover for telemedicine, including professional indemnity for telehealth video consultations.</List.Item>
                </List>
            </Card>

            <Card title="Fidelity" sectioned>
                <List>
                    <List.Item>The obligation to put patient interests first requires a doctor who participates in telehealth activities or telemedicine programs take steps to minimize conflicts of interest and bias. It is important that you disclose financial or other interests that may influence you in your roles with commercial health websites and services and take active steps to manage or eliminate conflicts of interest.</List.Item>
                </List>
            </Card>

            <Card title="Training to use the AIMS2Health Platform" sectioned>
                <p>The art of clinical practice comes from honing practice over time to meet the many and varied needs of patients, yet there is precious little training on how to incorporate the telemedicine technologies, such as the AIMS2Health platform,  into clinical encounters. You therefore agree to undergo initial training in the proper, efficient and effective use of the AIMS2Health Platform. In fact, all doctors who are to use the AIMS2Health platform are required to undergo a successful training program and they should therefore plan for it; it may be in a number of stages or steps on different days. The basic training could include:</p>
                <br />
                <Card.Section >
                    <List>
                        <List.Item>Videoconferencing technology</List.Item>
                        <List.Item>Clinical technology</List.Item>
                        <List.Item>Diagnostic device user training for both send and receive sites</List.Item>
                        <List.Item>Workflow and protocols of care and procedures for use of devices Documentation</List.Item>
                        <List.Item>Troubleshooting and access to product and technical support</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Timeliness and punctuality" sectioned>
                <List>
                    <List.Item>Ensure timeliness and punctuality (at least five minutes early) in online appointments with patients or a member of their team or your professional colleagues in multispecialty (tumour) boards.</List.Item>
                    <List.Item>Maintain a reminder diary for all telemedicine appointments</List.Item>
                    <List.Item>Pre-test video conference connectivity with the patient team</List.Item>
                </List>
            </Card>

            <Card title="Physical appearance" sectioned>
                <List>
                    <List.Item>A doctor should be appropriately attired and groomed for video consultations.</List.Item>
                </List>
            </Card>

            <Card title="Identification" sectioned>
                <p>Ensure that your identify yourself appropriately to the patient and/or members/representatives of the patient. </p>
                <br />
                <List>
                    <List.Item>Doctor should begin by providing and validating his/her own identity and credentials to the patient, i.e., medical license or other clinical qualifications.</List.Item>
                    <List.Item>He/she should also verify the location and identity of the patient to the best extent possible.</List.Item>
                </List>
            </Card>

            <Card title="Telemedicine video consultation etiquette" sectioned>
                <List>
                    <List.Item>In oral, video or written interactions, a doctor should display courteous behaviour, respectful demeanour and professional conduct.</List.Item>
                    <List.Item>To avoid any direct or indirect comment or criticism of professional colleagues on AIMS2health platform or elsewhere while providing second opinion.</List.Item>
                    <List.Item>Be aware that the microphones attached to the telemedicine camera picks up all sound in the room equally to the extent that even when whispering everything can be heard.  Use the “mute” button when necessary to hold a discussion not to be heard from the other telemedicine endpoint.</List.Item>
                    <List.Item>Always respect the autonomy of the patient and their ability to make choices about their treatment. Such respect is not simply a matter of attitude, but a way of acting so as to recognize rights of patients to self-determination and even promote the autonomous actions of the patient.</List.Item>
                    <List.Item>Cultural awareness (e.g., particular sensitivities about personal images and the recording of personal images)</List.Item>
                    <List.Item>In the case of a child, the principle of avoiding the harm of death, and the principle of providing a medical benefit that can restore the child to health and life, would be given precedence over the autonomy of the child's parents as surrogate decision makers.</List.Item>
                </List>
                <br />
                <DisplayText size="small">Set up or obtain access to consultation space that:</DisplayText>
                <br />
                <List>
                    <List.Item>is quiet and fit for purpose, visual and audio privacy of the practice’s teleconferencing room.</List.Item>
                    <List.Item>has arrangements to protect the privacy and dignity of patients who may be required to remove clothing for a physical examination (e.g., a screen in the room or a separate private area where patients can remove clothing and be suitably covered with a gown or drape ahead of the video consultation)</List.Item>
                    <List.Item>has plain decor that will not distract from visual images on the screen</List.Item>
                    <List.Item>has good lighting, avoiding high-intensity light (e.g., a window) behind the patient who is being viewed</List.Item>
                    <List.Item>has ready access to medical equipment that may be required during a video consultation</List.Item>
                    <List.Item>has systems to prevent interruptions (e.g., a ‘do not disturb’ sign to indicate that a video consultation is in progres</List.Item>
                    <List.Item>has access to a phone as a back-up if the video call fails.</List.Item>
                    <List.Item>develop and maintain documented contingency plans for managing technical problems during a video consultation (e.g., completing interrupted consultations by telephone)</List.Item>
                    <List.Item>ensure all participants are aware of the contingency plan (e.g., who will call whom)</List.Item>
                </List>
            </Card>

            <Card title="Personal and comforting touch" sectioned>
                <p>When the doctor and patient are not in the same room, as when they interact through the AIMS2Health platform, the personal touch cannot be taken for granted. As such, many patients may still prefer an in-person to an online consultation. It is, therefore, critically important that you, as the doctor, do everything within your means to provide a personal and comforting touch to a patient and his/her family even in an online consultation. Otherwise, use of AIMS2Health platform  or any other technology, merely for the sake of using a new technology, would be a pure folly.</p>
            </Card>

            <Card title="In person visit, teleconsultation or combination" sectioned>
                <List>
                    <List.Item>The patient team should be able to choose between a traditional appointment or a teleconsultation.  Or combine the two in the most suitable manner keeping the patient’s interest supreme. There is a distinct possibility that the best use of health information technology platforms, such as AIMS2Health, is to find new ways to listen to patients and enhance the doctor-patient relationship rather than dictate or placate it. At the same time,  exercise your professional autonomy in deciding whether a telemedicine versus face-to-face consultation is appropriate.</List.Item>
                    <List.Item>If, for any reason, during a telemedicine consultation you as a physician/surgeon believe the evaluation, diagnosis or treatment will be too complicated for telemedicine, you must request an in-person appointment before any medical advice is given.</List.Item>
                    <List.Item>A physician should not to participate in telemedicine if it violates the legal or ethical framework of India.</List.Item>
                </List>
            </Card>

            <Card title="Informed consent" sectioned>
                <p>Reconfirm before starting the substantive discussion during a phone or video-consultation session if the patient team has well understood the key provision of the informed consent on the AIMS2Health platform as it is a critical part of the telemedicine onboarding process.</p>
            </Card>

            <Card title="Duty and standard of care" sectioned>
                <p>The duty and standard of care required of doctors is also applicable to telemedicine as the standard of care for practicing telemedicine in India is the same as that of traditional in-person medicine. Under ordinary circumstances, a doctor who renders medical advice to a patient has a clear duty of care to the patient. The existence of the same duty of care by a telemedicine doctor is not as well-defined, given the lack of a face-to-face interaction between the telemedicine doctor and the patient, as well as the absence of the opportunity for the telemedicine doctor to conduct a physical examination of the patient. A doctor’s breach of the duty of care may give rise to administrative, civil, and/or criminal liability. Such breach constitutes medical malpractice or negligence. Be aware of the limitations of telemedicine to avoid obvious and common limitations of telemedicine.</p>
                <br />
                <DisplayText size="small">Follow the recognized and accepted standards of medical/surgical practice in India</DisplayText>
                <Card.Section>
                    <List>
                        <List.Item>The delivery of telemedicine services must follow evidence-based practice guidelines to the degree they are available, to ensure patient safety, quality of care and positive health outcomes.</List.Item>
                        <List.Item>In all situations, any treatment or therapy you recommend must be in accordance with a recognized and accepted standard of practice and should conform to the applicable medical regulatory authority guidelines.</List.Item>
                    </List>
                </Card.Section>
                <p>Match and ensure accessibility of the correct medical record in your doctor portal on AIMS2Health prior to start of the tele- or video-consultant which you consult as and when needed during the consultation.</p>
                <p>Ensure that your interaction, evaluation, consultation and prescribed treatment are all thoroughly documented in the patient’s medical record in the AIMS2Health platform. In particular, summarise follow-up actions for both patient team and yourself sand document each video consultation.  Keep a detailed record of the advice delivered as well as the information received (on which the advice was based) to ensure traceability. The medical record keeping for telemedicine should be consistent with standards required for documentation in traditional in-person consultation.</p>
                <Card.Section>
                    <List>
                        <List.Item>It should include copies of all patient-related electronic communications, laboratory tests and results, evaluations and consultation notes, prescriptions, records of past care and any instructions produced in connection with telemedicine.</List.Item>
                        <List.Item>Proper and timely documentation of the discussion of all oral consultations in the medical record.</List.Item>
                    </List>
                </Card.Section>
                <p>Discuss with patients or their surrogates the importance of preserving information for future episodes of care. In particular, discuss with patient team, whether it prefers to take responsibility for this or want the AIMS2Health to do so or share the information directly with their primary care doctor. If there is systematic collaboration amongst the physician, patient, or surrogate and the patient’s primary care doctor this may not be important, but if a patients’ primary care doctor is not a member of the patient’s team, you should write a detailed note after each clinical encounter for the patient in his/her medical record with AIMS2Health.</p>
            </Card>

            <Card title="Protection of privacy, personal data and confidentiality" sectioned>

                <List>
                    <List.Item>Be present in a quiet, well-lit and secluded space in a professional setting while interacting with the patient team</List.Item>
                    <List.Item>Take permission from the patient team if another person/doctor is assisting you especially in a video-consultation, or would be analysing an image or commenting on a report of the patient.</List.Item>
                    <List.Item>Apply, as far as practicable, the same standards, procedures, and confidentiality practices in a face-to-face examination of a patient as are applied to a telemedicine examination. </List.Item>
                    <List.Item>All members of the doctor’s team should take all reasonable measures during a telemedicine examination to maintain usual and customary patient confidentiality. </List.Item>
                    <List.Item>All AIMS2Health policies and appropriate state and national laws and professional society requirements regarding patient and examination privacy/confidentiality should be followed.</List.Item>
                    <List.Item>Telemedicine examinations, like any other patient examination, should be conducted in appropriate locations that maintain the usual and customary level of privacy/confidentiality.</List.Item>
                    <List.Item>Inform patient team that personal health information in medical records, including images, that has been anonymised so that it cannot be used to identify a person either directly or indirectly is no longer subject to the requirement of privacy/confidentiality.</List.Item>
                    <List.Item>To allows people to opt out of their confidential patient information being used for purposes beyond their individual care and treatment.</List.Item>
                    <List.Item>Assure patients that their data is managed safely and securely and their rights to privacy and confidentiality are upheld.</List.Item>
                </List>
            </Card>

            <Card title="Check patient team’s skills to participate in video-conferencing" sectioned>
                <p>Before starting a video-consultation ascertain whether the patient team has the skills needed to participate in a telemedicine encounters, especially when telehealth websites or mobile health applications connect doctors and patients with whom there is no prior relationship and or expectation of follow-up.</p>
            </Card>

            <Card title="Follow the recognized and accepted standards of medical/surgical practice in India">
                <Card.Section>
                    <List>
                        <List.Item>The delivery of telemedicine services must follow evidence-based practice guidelines to the degree they are available, to ensure patient safety, quality of care and positive health outcomes</List.Item>
                        <List.Item>In all situations, any treatment or therapy you recommend must be in accordance with a recognized and accepted standard of practice and should conform to the applicable medical regulatory authority guidelines.</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Follow established procedures and any oral or written directives issued periodically by the AIMS2Health management" sectioned>
                <List>
                    <List.Item>The same standards, procedures, and confidentiality practices in a face-to-face examination of a patient should be applied to a telemedicine examination. Reasonable efforts shall be made by the physician and all other parties associated with a telemedicine examination to maintain usual and customary patient confidentiality. All AIMS2Health policies and appropriate state and national laws and professional society requirements regarding patient and examination confidentiality should be followed. Telemedicine examinations, like any other patient examination, should be conducted in appropriate locations that maintain the usual and customary level of confidentiality.</List.Item>
                    <List.Item>As far as possible, follow standard protocols for use of telemedicine peripherals/devices, examination and documentation to ensure greater comfort for patients and caregivers and to get more consistent clinical results.</List.Item>
                </List>
            </Card>

            <Card title="Medical records" sectioned>
                <List>
                    <List.Item>The medical record for telemedicine should be consistent with standards required for documentation in traditional medicine.</List.Item>
                    <List.Item>It should include copies of all patient-related electronic communications, laboratory tests and results, evaluations and consultation notes, prescriptions, records of past care and any instructions produced in connection with telemedicine.</List.Item>
                    <List.Item>Proper and timely documentation of the discussion of all oral consultations in the medical record.</List.Item>
                    <List.Item>Continuity of Care: Follow-up care should be readily available to the patient, either from the AIMS2Health, the specialist conducting telemedicine consultation or a provider designated by the specialist/patient team,, as the case may be.</List.Item>
                    <List.Item>All medical documentation must be accessible to the patient through the AIMS2Health portal.</List.Item>
                    <List.Item>Any patients who requests that the information be provided to their other healthcare providers should be advised to contact the staff concerned of AIMS2Health or the specialist may do so on behalf the patient, preferably the latter.</List.Item>
                </List>
            </Card>

            <Card sectioned>
                <TextStyle variation="strong">Record any technical malfunctions during the video-conference session and share the details promptly with AIMS2Health</TextStyle>
            </Card>

            <Card title="e-Prescribing" sectioned>
                <List>
                    <List.Item>When prescribing via telemedicine, precautions should be taken to ensure patient safety in the absence of a traditional physical examination.</List.Item>
                    <List.Item>Measures taken should guarantee patient safety through informed, accurate and error-prevention practices.</List.Item>
                    <List.Item>Medications prescribed using telemedicine technologies should only include medications permitted by the Telemedicine Practice Guidelines. In addition, any medications prescribed must be done at the professional discretion of the physician.</List.Item>
                    <List.Item>Healthcare providers who engage in telemedicine should have knowledge of the availability medications in the patient’s geographic location and the other providers caring for the patient.</List.Item>
                    <List.Item>Caution should be exercised in prescribing medications that require close monitoring or that could lead to acute changes in a patient’s condition, particularly if the patient is not in close proximity to a health facility or healthcare provider.</List.Item>
                </List>
            </Card>
        </div>
    )

    return (
        <div>
            <Page fullWidth>
                {pagebody}
            </Page>
        </div>
    );
}