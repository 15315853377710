import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
    Page,
    Layout,
    Card,
    TextStyle,
    Stack,
} from '@shopify/polaris';
import { makeStyles, Theme, createStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import CallIcon from '@material-ui/icons/Call';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Box, Flex } from '@primer/components';
import AppoinmentBooking from './AppoinmentBooking';
import AddlHealthRecord from './FilePreparation/AddHealthRecord';
import AddDoctorTeam from './AddDoctorTeam';
import FinalOpinion from './FinalOpinion';
import DoctorPatientInteraction from './DoctorPatientInteraction';
import { getPatientCaseList } from 'redux/effects/ApiCall';
import { AppState } from 'redux/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { CaseList } from 'redux/interfaces/Model';
import * as _ from 'lodash';
import { StepButton } from '@material-ui/core';


const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(222, 9,26) 0%,rgb(216, 15, 15) 50%,rgb(228, 149, 149) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(222, 9,26) 0%,rgb(216, 15, 15) 50%,rgb(228, 149, 149) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(175, 62, 9) 0%,rgb(208, 109, 78) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(140, 26, 26) 60%,rgb(179, 51, 14) 100%)',
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#330101',
        }
    },
    typography: {
        fontSize: 20,
    }
});

function ColorlibStepIcon(props: StepIconProps) {
    const history = useHistory();
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <GroupIcon />,
        3: <ContactPhoneIcon />,
        4: <FileCopyIcon />,
        5: <GroupAddIcon />,
        6: <PostAddIcon />,
        7: <PaymentIcon />,
        8: <EmojiPeopleIcon />,
        9: <CallIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

export default function AppoinmentDetails() {
    const history = useHistory();
    const { caseid } = useParams() as {
        caseid: string;
    };

    const { index } = useParams() as {
        index: string;
    };
    const { patientid } = useParams() as {
        patientid: string;
    };

    const dispatch = useDispatch();
    // Call Store
    const taskReduxValue = useSelector((state: AppState) => state.caseValue);

    const taskDetails: CaseList = _.filter(taskReduxValue.caseValue, ['caseId', Number(caseid)])[0];

    const [toSignin] = useState(false);
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(() => {
        if (localStorage.getItem("patientCaseDetailsStep") !== null) {
            return Number(localStorage.getItem("patientCaseDetailsStep"));
        } else {
            return 0;
        }
    });
    const steps = getSteps();
    function getSteps() {
        return ['Doctor-Patient Interaction', 'File Preparation', 'Add Doctor Team', 'Appointment Booking', 'Final Opinion'];
    }
    const handleNext = () => {
        if (localStorage.getItem("consultationopt") === 'Quick Video Consultation') {
            setActiveStep(3);
            localStorage.setItem("consultationopt", '');
        }
        else if (activeStep === steps.length - 1) {
            localStorage.setItem("patientCaseDetailsStep", "0");
            history.goBack();
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    const [completed, setCompleted] = React.useState(new Set<number>());
    function isStepComplete(step: number) {
        // const newCompleted = new Set(completed);
        // newCompleted.add(activeStep);
        // setCompleted(newCompleted);
        // setCompleted(step);
        return completed.has(step);
    }
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 55) + "..." : str;
    }
    const getStepContent = (stepper: number) => {
        localStorage.setItem("patientCaseDetailsStep", stepper.toString());
        switch (stepper) {
            case 0:
                return (
                    <DoctorPatientInteraction />
                );
            case 1:
                return (
                    // <FilePreparation />
                    <AddlHealthRecord />
                );
            case 2:
                return (
                    <AddDoctorTeam />
                );
            case 3:
                return (
                    <AppoinmentBooking />
                );
            case 4:
                return (
                    <FinalOpinion />

                );
            default:
                return 'Unknown step';
        }
    };

    useEffect(() => {
        dispatch(getPatientCaseList());
    }, [dispatch]);

    const pagebody = (
        <Card>
            <div className={classes.root}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton onClick={handleStep(index)} completed={isStepComplete(index)}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                {/* <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper> */}
                <Box p={3}>
                    {activeStep === steps.length ? (
                        ''
                    ) : (
                        <Layout>
                            <Layout.Section>
                                <TextStyle>{getStepContent(activeStep)}</TextStyle>
                            </Layout.Section>
                            <Layout.Section>
                                <Stack>
                                    <Stack.Item fill>
                                        &nbsp;
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Flex justifyContent='end'>
                                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep === steps.length - 1 ? 'Close' : 'Next'}
                                            </Button>
                                        </Flex>
                                    </Stack.Item>
                                </Stack>
                            </Layout.Section>
                        </Layout>
                    )}
                </Box>
            </div>
        </Card>
    );

    return (
        <Page
            fullWidth
            breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
            title={`${(taskDetails === undefined) ? "" : taskDetails.patientName + "'s Details"}`}
            subtitle={`${(taskDetails === undefined) ? "" : (taskDetails.whyWant2ndOpinion)}`}
        >
            {toSignin ? <Redirect to="/signin" /> : null}
            <ThemeProvider theme={defaultMaterialTheme}>
                {pagebody}
            </ThemeProvider>
        </Page>
    );
}