import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    Stack,
    FormLayout,
    TextField,
    Banner,
    Thumbnail,
    Button,
    Loading,
    RadioButton,
    DataTable,
    Caption,
    Modal,
    TextContainer,
    InlineError,
    Page,
    ExceptionList
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, SelectPicker, Uploader } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function DoctorEducationalPage() {
    const [imageId, setImageId] = useState('');
    const [open, setOpen] = useState(false);
    console.log(open);

    const [institute, setInstitute] = useState('');
    const handleInstituteChange = useCallback((value) => setInstitute(value), [],);
    const [institutteError, setInstituteError] = useState('');
    const institutteErrordiv = institutteError
        ? <InlineError message={institutteError} fieldID="awardId" />
        : '';

    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date(),);
    const handleDateChange = (date: Date | null) => { setSelectedDate(date); };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date(),);
    const handleEndDateChange = (enddate: Date | null) => { setSelectedEndDate(enddate); };

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [isAIIMSValue, setIsAIIMSValue] = useState('true');
    const isAIIMSValueChange = useCallback((_checked, newValue) => setIsAIIMSValue(newValue), []);

    const [submitDegreeList, setsubmitDegreeListValues] = useState([]);
    const [degreeName, setDegreeName] = useState("");
    const handleSelectDegreeListChange3 = useCallback((value) => setDegreeName(value), []);
    const [degreeNameError, setDegreeNameError] = useState('');
    const degreeNameErrordiv = degreeNameError
        ? <InlineError message={degreeNameError} fieldID="awardId" />
        : '';
    const [description, setDescription] = useState('');
    const handleDescriptionChange = useCallback((value) => setDescription(value), [],);
    const [modalEducationalActive, setEducationalModalActive] = useState(false);
    const handleEducationalToggle = () => {
        setEducationalModalActive((modalEducationalActive) => !modalEducationalActive);
        setInstitute('');
        setInstitute('');
        setDegreeName("");
        setImageInfo('');
        setDescription('');
        setIsAIIMSValue("true");
        setSelectedDate(new Date(),);
        setSelectedEndDate(null);
        setDegreeNameError("");
        setAddEducationSuccess('');
        setAddEducationDetailsError('');
        setEditEducationalId("");
    }
    const [editEducationId, setEditEducationalId] = useState('');

    // Add Degree List API
    const getAddDegreeList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/degree_list_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setsubmitDegreeListValues(res.data.degreeList);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    const educationalBody = {
        institutename: institute,
        degreeId: degreeName,
        fromDate: selectedDate,
        toDate: selectedEndDate,
        isAIIMS: isAIIMSValue,
        documentId: imageId,
        description: description
    };


    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeAddEducationDetailsError = () => {
        setAddEducationDetailsError('')
    }

    const [addEducationDetailsError, setAddEducationDetailsError] = useState('');
    const errorAddEducationDetailsDiv = addEducationDetailsError
        ? <Banner
            onDismiss={closeAddEducationDetailsError}
            status="critical">
            <p>{addEducationDetailsError}</p>
        </Banner>
        : '';

    const [addEducationSuccess, setAddEducationSuccess] = useState('');
    const closeAddEducationSuccess = () => {
        setAddEducationSuccess('');
    }
    const successAddEducationDiv = addEducationSuccess
        ? <Banner
            onDismiss={closeAddEducationSuccess}
            status="success">
            <p>{addEducationSuccess}</p>
        </Banner>
        : '';


    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const uploadbody = {
        'typeName': 'doctor-educational-supporting-doc'
    };
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }
    // Add Education Details
    const educationSubmit = () => {
        console.log(educationalBody);
        if (degreeName === '' || degreeName === null) {
            setDegreeNameError("Degree name should not be empty");
        }
        else {
            setIsLoading(true);
            let url, educationalSubmitBody;
            console.log("EducationSubmitBody===>",educationalSubmitBody);
            if (editEducationId) {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_education_detail';
                educationalSubmitBody = Object.assign(educationalBody, { educationId: editEducationId })
            }
            else {
                url = baseUrl.baseUrl + '/doctorapp/api/onboarding/add_education_detail';

            }
            axios.post(url, JSON.stringify(educationalBody), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setInstituteError("");
                    setDegreeNameError("");
                    setAddEducationSuccess('');
                    setAddEducationSuccess(result.data.message);
                    getEducationDetailsApi();
                    setOpen((open) => !open);
                }
            }).catch((error) => {
                setAddEducationSuccess('');
                setDegreeNameError("");
                setAddEducationDetailsError(error.message);
                setIsLoading(false);
            });
        }
    }

    function editEducationalDetails(response: any) {
        setOpen(true);
        setDegreeNameError("");
        setAddEducationSuccess('');
        setEducationalModalActive((modalEducationalActive) => !modalEducationalActive);
        setAddEducationSuccess("");
        setAddEducationDetailsError("");
        setIsAIIMSValue(response.isAIIMS === true ? 'true' : 'false');
        setEditEducationalId(response.id);
        let docURL = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.document : 'document.png';
        let imgId = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].userFile.id : '';
        setImageInfo(docURL);
        setImageId(imgId);
        setInstitute(response.name);
        setDegreeName(response.degree.id);
        setSelectedDate(response.fromDate);
        setSelectedEndDate(response.toDate);
        setDescription(response.description);
    }

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [degreeList, setRelationValues] = useState<any[]>([]);
    const getEducationDetailsApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/education_list', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.degreeList;
                let educationalList = [];
                for (var i = 0; i < response.length; i++) {
                    let selectedResponse = response[i];
                    let doctorsAiims = response[i].isAIIMS === true ? 'AIIMS' : 'NON AIIMS';
                    let name = response[i].name === "" ? "" : response[i].name;
                    let fromDate = response[i].fromDate === null ? '' : (new Date(response[i].fromDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let toDate = response[i].toDate === null ? '' : (new Date(response[i].toDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let description = response[i].description === "" ? '' : truncate(response[i].description);
                    let supportingDocuments = (response[i].supportingDocuments.length > 0) ? response[i].supportingDocuments[0].userFile.document : 'document.png';
                    educationalList.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        name,
                        response[i].degree.displayValue,
                        fromDate,
                        toDate,
                        doctorsAiims,
                        description,
                        <Button icon={EditMinor} size="slim" onClick={() => editEducationalDetails(selectedResponse)}></Button>
                    ]);

                }
                setRelationValues(educationalList);
            })
            .catch((error) => {
                setIsLoading(false);
                //setAddEducationDetailsError(error.response.data.message);
            })
    }
    useEffect(() => {
        getEducationDetailsApi();
        getAddDegreeList();
    }, []);

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Educational Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const educationTableList = (
        <Card.Section>
            {degreeList && degreeList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                        'text'
                    ]}
                    headings={[
                        'Document',
                        'Institute name',
                        'Degree name',
                        'Start date',
                        'End date',
                        'Category',
                        'Description',
                        'Edit'
                    ]}
                    rows={degreeList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Educational Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };

    const doctorEducationalDetails = (
        <div>
            {loadingMarkup}
            <Modal large
                open={modalEducationalActive}
                onClose={handleEducationalToggle}
                title={editEducationId ? "Edit Education Details" : "Add Education Details"}
                primaryAction={{
                    content: "Submit",
                    onAction: educationSubmit,
                }}
            >
                <Modal.Section>
                    <Page>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <RadioButton
                                    label="AIIMS"
                                    helpText="For those who were passed from AIIMS."
                                    checked={isAIIMSValue === 'true'}
                                    id="true"
                                    name="category"
                                    onChange={isAIIMSValueChange}
                                />
                                <RadioButton
                                    label="Non-AIIMS"
                                    helpText="For those who were passed from Other Institutes."
                                    id="false"
                                    name="category"
                                    checked={isAIIMSValue === 'false'}
                                    onChange={isAIIMSValueChange}
                                />
                            </FormLayout.Group>
                            <FormLayout.Group condensed>
                                <div>
                                    <div>
                                        <TextField
                                            value={institute}
                                            onChange={handleInstituteChange}
                                            label="Institute Name"
                                            type="text"
                                            placeholder="Please Enter Institute Name"
                                            id="Institutename"
                                        />
                                        <div>{institutteErrordiv}</div>
                                    </div>
                                    <br />
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Degree</p>
                                        <SelectPicker
                                            block
                                            onChange={handleSelectDegreeListChange3}
                                            data={submitDegreeList}
                                            value={degreeName}
                                            menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                                            id="degreeId"
                                        />
                                        <div>{degreeNameErrordiv}</div>
                                    </div>
                                </div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                    <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                        <Stack distribution="fill">
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} alt="" width="100%" height="100%" />
                                                    ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />
                                                    )}
                                                </button>
                                            </Uploader>
                                        </Stack>
                                    </Box>
                                    <Caption>Document size should be maximum 3MB.</Caption>
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Start Date</p>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                inputVariant="outlined"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={selectedDate}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={date => handleDateChange(date)}
                                                size="small"
                                                focused
                                                id="startDate"
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleDateChange(null)}>
                                                            <Icon icon="close" />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>End Date</p>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                inputVariant="outlined"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={selectedEndDate}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={enddate => handleEndDateChange(enddate)}
                                                size="small"
                                                focused
                                                id="endDate"
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEndDateChange(null)}>
                                                            <Icon icon="close" />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div>
                                    <TextField
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        label="Description"
                                        type="text"
                                        placeholder="Please Enter Description"
                                        id="description"
                                        multiline={2}
                                    />
                                </div>
                            </FormLayout.Group>
                            <div>{successAddEducationDiv}</div>
                            <div>{errorAddEducationDetailsDiv}</div>
                        </FormLayout>
                    </Page>
                </Modal.Section>
            </Modal>
        </div>
    )

    const actualPageMarkup = (
        <Box>
            <Card>
                <Card.Header
                    title="Educational Details"
                >
                    <Button primary={true} onClick={handleEducationalToggle}>Add Educational Details</Button>
                </Card.Header>
                {educationTableList}
            </Card>
            {doctorEducationalDetails}
        </Box>
    );
    return (
        <div>
            {actualPageMarkup}
            {docViewerComponent}
        </div>
    );
}