import React, { useState, useEffect } from 'react';
import {
    Card,
    Loading,
    Page,
    Layout,
    DisplayText
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiUrl'
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom';
import { Box, Flex} from '@primer/components';

interface AccountProps {
    onAction(): void;
}

export default function DoctorDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const { doctorid } = useParams() as {
        doctorid: string;
    };
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [doctorImage, setDoctorImage] = useState('');
    console.log("doctorID == " + doctorid);
    const token = function () {
        return localStorage.getItem("token");
    }

    let userToken: string = token()!
    if (userToken == null) {
    }

    // used to get doctor personal details
    const onboardingId = localStorage.getItem("onboardingrequestId");
    const personaldetailsBody = JSON.stringify({
        onboardingRequestId: onboardingId
    });
    const [firstName, setFirstName] = useState([]);
    const [lastName, setLastName] = useState([]);
    const [email, setEmail] = useState([]);
    const [genderget, setGendeget] = useState('');
    const [phoneget, setPhoneget] = useState([]);
    const [yearofexperienceget, setYearofExperienceget] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/personal_details_by_id',
            personaldetailsBody, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                setFirstName(res.data.personalDetails.firstname);
                setLastName(res.data.personalDetails.lastname);
                setEmail(res.data.personalDetails.email);
                //setGendeget(res.data.personalDetails.gender);
                let genderData = res.data.personalDetails.gender;
                if (genderData === 'f') {
                    setGendeget('Female')
                }
                else {
                    setGendeget('Male')
                }
                setPhoneget(res.data.personalDetails.phoneNumber);
                setYearofExperienceget(res.data.personalDetails.yearOfExperience);
                setDoctorImage(res.data.personalDetails.doctorImage.document);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })

    }, []);

    const doctorsName = firstName + " " + lastName;

    const actualPageMarkup = (
        <div>
            <Card>
                <Layout>
                    <Layout.Section secondary>
                        <Box p={2}>
                            <img
                                src={doctorImage}
                                alt=""
                                width="100%"
                                height="100%"
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center'
                                }}
                            />
                        </Box>
                    </Layout.Section>
                    <Layout.Section>
                        <Flex alignItems="center" justifyContent="left" style={{padding: '5px'}}>
                            <div>
                                <DisplayText size="medium">Name: {doctorsName}</DisplayText>
                                <DisplayText size="small">Email: {email}</DisplayText>
                                <DisplayText size="small">Gender: {genderget}</DisplayText>
                                <DisplayText size="small">Phone: {phoneget}</DisplayText>
                                <DisplayText size="small">Experience: {yearofexperienceget}</DisplayText>
                                <DisplayText size="small">Specialty: </DisplayText>   
                                <DisplayText size="small">Description: </DisplayText>                              
                            </div>
                        </Flex>
                    </Layout.Section>
                </Layout>
            </Card>
        </div>
    )


    return (
        <Page title="Doctor's Personal Details" fullWidth>
            {loadingMarkup}
            {actualPageMarkup}
        </Page>

    );
}
