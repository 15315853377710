import React from 'react';
import { } from 'react-router-dom';
import { useState, useCallback } from 'react';
import {
    Card,
    Form,
    FormLayout,
    Page,
    TextField,
    Button,
    Layout,
    Loading,
    Banner,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiUrl'

interface AccountProps {
    onAction(): void;
}

export default function NeedHelp() {

    const [helpPhoneNumber, setHelpPhoneNumber] = useState('');
    const [textValue, setTextValue] = useState('');
    const handleChanePhoneNumber = useCallback((value) => setHelpPhoneNumber(value), []);
    const handleChange = useCallback((newValue) => setTextValue(newValue), []);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const userToken = localStorage.getItem("token");
    const [error, setError] = useState('');

    const [submitquerySuccess, setSubmitquerySuccess] = useState('');
    const closeHelpSuccess = () => {
        setSubmitquerySuccess('');
    }
    const closeerror = () => {
        setError('')
    }

    const successDiv = submitquerySuccess
        ? <Banner
            onDismiss={closeHelpSuccess}
            status="success">
            <p>{submitquerySuccess}</p>
        </Banner>
        : '';
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';


    const body = JSON.stringify({
        phoneNumber: helpPhoneNumber,
        queryText: textValue
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const needHelp = () => {
        console.log("==helpPhoneNumber==" + helpPhoneNumber);
        console.log("==helptext==" + textValue);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/doctorhelp/submit_query',
            body, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setSubmitquerySuccess(result.data.message);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setError(error.response.data.message);
                setIsLoading(false);
            })
    }

    const pagebody = (
        <Layout>
            <Layout.AnnotatedSection
                title="Improving Health Outcomes Through Patient Empowerment"
                description="We create personalized user-friendly experiences to meet each patient’s unique health needs.We carefully review the credinatials of the specialist doctors made available to you on this platform."
            >
                <Card sectioned>
                    <Form onSubmit={needHelp}>
                        <FormLayout>
                            <div>
                                <TextField
                                    label="Phone Number"
                                    value={helpPhoneNumber}
                                    placeholder="Please enter your phone number"
                                    id="doctorPhone"
                                    onChange={handleChanePhoneNumber} />
                                <br />
                                <TextField
                                    label="Description (optional)"
                                    value={textValue}
                                    onChange={handleChange}
                                    placeholder="Please describe your problem."
                                    multiline={4} />
                            </div>
                            <Button primary submit>Submit</Button>
                            <div>{successDiv}</div>
                            <div>{errorDiv}</div>
                        </FormLayout>
                    </Form>

                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    )

    return (
            <Page
                fullWidth
                title="Need Help"
            >
                {loadingMarkup}
                {pagebody}
            </Page>
    );
}