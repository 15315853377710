import React, { useCallback, useState } from 'react';
import { Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { PrivateRoute } from 'routes/PrivateRoute';
import { PublicRoute } from 'routes/PublicRoute';
import axios from 'axios';
import baseUrl from './ApiUrl';
import {
  Button,
  Frame,
  Heading,
  Icon,
  Loading,
  Navigation,
  Page,
  Scrollable,
  Sheet,
  TopBar,
  VisuallyHidden
} from '@shopify/polaris';
import {
  LogOutMinor,
  CustomersMajor,
  CalendarMajor,
  CustomersMinor,
  CalendarTickMajor,
  UnfulfilledMajor,
  QuestionMarkMajor,
  MobileCancelMajor,
  PhoneOutMajor,
  PhoneMajor,
  PaymentsMajor,
} from '@shopify/polaris-icons';

import Login from 'components/pages/UserOnboarding/Login';
import SignUp from 'components/pages/UserOnboarding/SignUp';
import Experience from 'components/pages/UserOnboarding/Experience';
import Dashboard from 'components/pages/Dashboard';
import Schedule from 'components/pages/Schedule/Schedule';
import WorkSchedule from 'components/pages/Schedule/WorkSchedule';
import Doctor from 'components/pages/Doctor';
import PatientCaseList from 'components/pages/PatientCaseList';
import NotFound from 'components/pages/NotFound';
import HelpCenter from 'components/pages/HelpCenter';
import CodeOfConduct from 'components/pages/CodeOfConduct';
import AppoinmentDetails from 'components/pages/AppoinmentDetails';
import Appointments from 'components/pages/Appointments';
import TaskDetails from 'components/pages/TaskDetails';
import Task from 'components/pages/Task';
import DoctorPatientInteraction from 'components/pages/DoctorPatientInteraction';
import AppoinmentBooking from 'components/pages/AppoinmentBooking';
import FilePreparation from 'components/pages/FilePreparation/FilePreparation';
import AddDoctorTeam from 'components/pages/AddDoctorTeam';
import FinalOpinion from 'components/pages/FinalOpinion';
import UserProfile from 'components/pages/UserProfile';
import DoctorDetails from 'components/pages/DoctorDetails';
import NeedHelp from 'components/pages/NeedHelp';
import DoctorTeleHealth from 'components/pages/Telehealth/DoctorTeleHealth';
import DevDoc from 'components/pages/Devdocs';
import Payment from 'components/pages/Payment';
import DoctorCalendar from 'components/pages/DoctorCalendar';
import DoctorAppointment from 'components/pages/DoctorAppointment';
import AccountDetails from 'components/pages/UserOnboarding/AccountDetails';
import Preview from 'components/pages/FilePreparation/Preview';

import 'rsuite/dist/styles/rsuite-default.css';

export default function App() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);

  const toggleSheetActive = useCallback(
    () => setSheetActive((sheetActive) => !sheetActive),
    [],
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );
  let usernameCheck: string;
  const [islineLoading, setislineLoading] = useState(false);
  const loadingMarkup = islineLoading ? <Loading /> : null;

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const userMenuActions = [
    {
      items: [
        {
          content: 'User Profile',
          url: "/userprofile",
        },
        {
          content: 'Code of Conduct',
          url: "/codeofconduct",
        },
        {
          content: 'Account Details',
          url: "/accountdetails",
        }
      ],
    },
  ];

  const userFirstname = function () {
    return localStorage.getItem("firstname");
  }

  let Firstname: string = userFirstname()!

  const userLastname = function () {
    return localStorage.getItem("lastname");
  }

  let Lastname: string = userLastname()!

  if (Firstname === '') {
    usernameCheck = "Unknown"
  }
  else {
    usernameCheck = Firstname + " " + Lastname
  }

  const userName = function () {
    return localStorage.getItem("name");
  }

  let username: string = userName()!
  const userEmail = function () {
    return localStorage.getItem("email");
  }

  let useremail: string = userEmail()!
  const token = function () {
    return localStorage.getItem("token");
  }

  let userToken: string = token()!
  if (userToken == null) {
    //  history.push("/signin"); 
  }

  const body = JSON.stringify({
    token: userToken,
  });

  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }

  const handleLogout = () => {
    setislineLoading(true);
    localStorage.removeItem("token");
    axios.post(baseUrl.baseUrl + '/profileapp/logout/',
      body, { headers })
      .then(_result => {
        setislineLoading(false);
        localStorage.removeItem("email");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("onBoarding");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("id");
        localStorage.removeItem("onBoarding");
        localStorage.removeItem("onboardingrequestId");
        localStorage.removeItem("onboardingrequestStatus");
        localStorage.removeItem("doctorID");
        window.location.reload();
      })
      .catch((error) => {
        setislineLoading(false);
        console.log("Login API Error");
        console.log(error);

      })
  }

  const Token = localStorage.getItem("token");
  const userMenuMarkup = Token ? (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={usernameCheck}
      detail={useremail}
      initials=""
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : null;

  const helpTopBarHandler = useCallback(() => { }, []);

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <span onClick={toggleSheetActive}>
          <Icon source={QuestionMarkMajor} />
          <VisuallyHidden>Secondary menu</VisuallyHidden>
        </span>
      }
      open={false}
      onOpen={helpTopBarHandler}
      onClose={helpTopBarHandler}
      actions={[]}

    />
  );

  const topBarMarkup = Token ? (
    (
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        secondaryMenu={secondaryMenuMarkup}
        onNavigationToggle={toggleMobileNavigationActive}
      />
    )
  ) : null;
  const onBoardingStatus = localStorage.getItem("onBoarding") === 'approved';

  const isActiveNav = onBoardingStatus ? (
    <Navigation.Section
      items={[
        {
          label: 'Work Schedule',
          icon: CalendarMajor,
          onClick: toggleIsLoading,
          url: "/schedule",
        },
        {
          label: 'Appointments',
          icon: CalendarTickMajor,
          onClick: toggleIsLoading,
          url: "/appointments",
        },
        {
          label: 'Patient',
          icon: CustomersMinor,
          onClick: toggleIsLoading,
          url: "/patientcaselist",
        },
        {
          label: 'Telehealth',
          icon: PhoneMajor,
          onClick: toggleIsLoading,
          url: "/telehealth",
        },
        {
          label: 'Payment',
          icon: PaymentsMajor,
          onClick: toggleIsLoading,
          url: "/payment",
        }
      ]}
      separator
    />
  ) : null;

  const navigationMarkup = Token ? (
    <Navigation location="/">
      <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ marginBottom: 'auto', width: '100%' }}>
          <Navigation.Section
            items={[
              {
                label: 'Dashboard',
                icon: UnfulfilledMajor,
                url: "/dashboard",
              },
            ]}
          />
          {isActiveNav}
        </div>
        <div style={{ width: '100%' }}>
          <Navigation.Section
            items={[
              {
                label: 'Call Us',
                icon: PhoneOutMajor,
                onClick: () => window.open('tel:+91-8047165657')
              },
              {
                label: 'Need Help',
                icon: QuestionMarkMajor,
                onClick: toggleIsLoading,
                url: "/needhelp",
              },
              {
                label: 'User Profile',
                icon: CustomersMajor,
                onClick: toggleIsLoading,
                url: "/userprofile",
              },
              {
                label: 'Log Out',
                icon: LogOutMinor,
                onClick: handleLogout,
              },
            ]}
            separator
          />
        </div>
      </div>
    </Navigation>
  ) : null;

  const helpCentermarkup = (
    <Page narrowWidth>
      <Sheet open={sheetActive} onClose={toggleSheetActive}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              borderBottom: '1px solid #DFE3E8',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.6rem',
              width: '100%',
            }}
          >
            <Heading>AIMS2Health Help Center</Heading>
            <Button
              accessibilityLabel="Cancel"
              icon={MobileCancelMajor}
              onClick={toggleSheetActive}
              plain
            />
          </div>
          <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
            <HelpCenter />
          </Scrollable>
          <div
            style={{
              alignItems: 'center',
              borderTop: '1px solid #DFE3E8',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.6rem',
              width: '100%',
            }}
          >
            <Button onClick={toggleSheetActive}>Cancel</Button>
            <Button primary onClick={toggleSheetActive}>
              Done
            </Button>
          </div>
        </div>
      </Sheet>
    </Page>
  );

  return (
    <div>
      <Frame topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {islineLoading}
        {islineLoading}
        {loadingMarkup}
        {helpCentermarkup}
        <Router>
          <Switch>
            <PublicRoute exact path="/" component={Login} />
            <PublicRoute exact path="/signin" component={Login} />
            <PublicRoute exact path="/signup" component={SignUp} />
            <PublicRoute exact path="/experience" component={Experience} />

            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/appointments" component={Appointments} />
            <PrivateRoute path="/schedule" component={WorkSchedule} />
            <PrivateRoute path="/doctor" component={Doctor} />
            <PrivateRoute path="/doctordetails/:doctorid" component={DoctorDetails} />
            <PrivateRoute path="/tasks" component={PatientCaseList} />
            <PrivateRoute path="/userprofile" component={UserProfile} />
            <PrivateRoute path="/needhelp" component={NeedHelp} />
            <PrivateRoute path="/codeofconduct" component={CodeOfConduct} />
            <PrivateRoute path="/appointmentdetails/:patientid?/:caseid?/:index?" component={AppoinmentDetails} />
            <PrivateRoute path="/patientcaselist" component={PatientCaseList} />
            <PrivateRoute path="/task" component={Task} />
            <PrivateRoute path="/taskdetails/:patientId?/:caseId?/:index?" component={TaskDetails} />
            <PrivateRoute path="/doctorpatientinteraction/:caseId?" component={DoctorPatientInteraction} />
            <PrivateRoute path="/telehealth" component={DoctorTeleHealth} />
            <PrivateRoute path="/appoinmentbooking/:caseId?" component={AppoinmentBooking} />
            <PrivateRoute path="/filepreparation/:caseId?/:patientName?/:whyWant2ndOpinion?" component={FilePreparation} />
            <PrivateRoute path="/adddoctorteam/:caseId?" component={AddDoctorTeam} />
            <PrivateRoute path="/finalopinion/:patientName?/:caseId?" component={FinalOpinion} />
            <PrivateRoute path="/documentation" component={DevDoc} />
            <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/calendar" component={DoctorCalendar} />
            <PrivateRoute path="/doctorappointment" component={DoctorAppointment} />
            <PrivateRoute path="/accountdetails" component={AccountDetails} />
            <PrivateRoute path="/preview/:patientid?/:caseid?" component={Preview} />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Frame>
    </div>
  )
}