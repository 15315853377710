import { Page } from '@shopify/polaris';
import * as React from 'react';
import { useState } from 'react';

import Player from "./Player";

export default function DevDoc() {
    const [players, setPlayers] = useState([
        {
            name: "LaMarcus Aldridge",
            yearsPro: 14,
            position: "Center-Forward"
        },
        {
            name: "Marco Belinelli",
            yearsPro: 13,
            position: "Guard"
        },
        {
            name: "DeMar DeRozan",
            yearsPro: 11,
            position: "Guard-Forward"
        }
    ]);

    const playersList = players.map((player) => (
        <li key={player.name.replace(" ", "").toLowerCase()}>
            <Player
                player={player}
            />
        </li>
    ));
    return (
        <Page fullWidth>
            <h1>Team Members ({players.length})</h1>
            <ul className="List">{playersList}</ul>
        </Page>
    );
}