import * as React from 'react';
import { useEffect } from 'react';
import {
  TextStyle,
  Card,
  ResourceItem,
  ResourceList,
  Page,
  Stack,
  Badge,
  Icon,
  Layout,
  DisplayText,
  Loading,
} from '@shopify/polaris';
import { Box, Flex } from '@primer/components';
import { ChevronRightMinor, AccessibilityMajor } from '@shopify/polaris-icons';
import FeatureImg from '../image/featureimage-doctor.png';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientCaseList } from 'redux/effects/ApiCall';
import { AppState } from 'redux/store/Store';

export default function PatientCaseList() {
  const loadingMarkup = <Loading />;
  const dispatch = useDispatch();

  // encounter list api call ...
  useEffect(() => {
    dispatch(getPatientCaseList());
  }, [dispatch]);

  const caseListValue = useSelector((state: AppState) => state.caseValue);
  console.log("DOCTORCASELISTREDUX==>", caseListValue);

  const emptyPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "50vh" }} alignItems="center" justifyContent="center">
          <Box pt={5} mt={5}>
            <DisplayText size="medium">No Case History Available</DisplayText>
          </Box>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <div><img
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',

            }} src={FeatureImg} alt="" /></div>
        </Box>
      </Layout.Section>
    </Layout>
  );

  const pagebody = (
    <Card>
      {caseListValue && caseListValue.caseValue.length ? (
        <ResourceList
          items={caseListValue.caseValue}
          renderItem={(item) => {
            const { patientId, caseId, secondOpinionReasons, whyWant2ndOpinion, consultationType, patientName } = item;
            return (
              <ResourceItem
                id={`${patientId}-${caseId}`}
                url={`/appointmentdetails/${patientId}/${caseId}`}
                accessibilityLabel={`View details for ${whyWant2ndOpinion}`}
                persistActions
              >
                <Stack alignment="center">
                  <Stack.Item>
                    <Icon source={AccessibilityMajor} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Box>
                      <h5>
                        <TextStyle variation="strong">{whyWant2ndOpinion}</TextStyle>
                      </h5>
                      <Box maxWidth="40vw"><em>{secondOpinionReasons}</em></Box>
                      {consultationType ? (<div><Badge progress="complete">{consultationType}</Badge>&nbsp;&nbsp;<Badge status="warning">{patientName}</Badge></div>) : ''}
                    </Box>
                  </Stack.Item>
                  <Stack.Item>
                    <Icon source={ChevronRightMinor} />
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      ) : (
            <div>{emptyPageMarkup}</div>
        )}
    </Card>
  );

  return (
    <Page fullWidth title="Patient Case List">
      { caseListValue.loading ? loadingMarkup : '' }
      {pagebody}
    </Page>
  );
}