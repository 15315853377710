import React, { useState, useCallback } from 'react';
import { Box, Flex } from '@primer/components';
import {
  Tabs,
  Page,
  Layout,
  DisplayText,
  Caption,
  Stack,
  TextStyle,
  Link,
  Button
} from '@shopify/polaris';
import LoginWithOTP from 'components/pages/UserOnboarding/LoginWithOTP';
import LoginWithPassword from 'components/pages/UserOnboarding/LoginWithPassword';
import AimsLogo from 'components/image/logo/aims2health-with-caption.png';
import FeatureImg from 'components/image/featureimage-doctor.png';
import { Divider } from 'rsuite';

export default function Login() {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [

    {
      id: 'emaillogin',
      content: 'Sign in with OTP',
      body: (
        <LoginWithOTP />
      )
      ,
      panelID: 'emaillogin-content',
    },
    {
      id: 'passwordlogin',
      content: 'Sign in with Password',
      body: (
        <LoginWithPassword />

      ),
      accessibilityLabel: 'All customers',
      panelID: 'passwordlogin-content',
    },
  ];

  const actualPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "90vh" }} alignItems="center" justifyContent="center">
          <div>
            <Link url="https://aims2health.com">
              <img src={AimsLogo} alt="Logo" height="60" />
            </Link>
            <Box my={3}>
              <DisplayText size="large">Welcome to AIMS2Health</DisplayText>
              <p>2<sup>nd</sup> opinion from Ex-AIIMS Specialist Doctors</p>
            </Box>
            <Divider></Divider>
            <TextStyle variation="strong">New User?{' '}
              <Button url='/signup'>Register</Button>
            </TextStyle>
            <Divider><b>Old User?</b></Divider>
            <Box my={3}  style={{ height: "350px" }}>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                <Box py={3}>{tabs[selected].body}</Box>
              </Tabs>
            </Box>
          </div>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <img
            src={FeatureImg}
            alt=""
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </Box>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item fill>
            <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
          </Stack.Item>
          <Stack.Item>
            <Caption>
              <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;
            <Link url="https://aims2health.com/privacy-statement.html" external>Privacy & Cookies</Link>
            </Caption>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </Layout>
  );

  return (
    <Page fullWidth>
      {actualPageMarkup}
    </Page>

  );
}