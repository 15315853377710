import React, { useState, useCallback } from 'react';
import { Box, Flex } from '@primer/components';
import {
    Tabs,
    Page,
    Layout,
} from '@shopify/polaris';
// import DoctorCalendar from './DoctorCalendar';
import DoctorAppointment from './DoctorAppointment';

export default function Appointments() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

    const tabs = [

        {
            id: 'drappointment',
            content: 'Doctor Appointments',
            body: (
                <DoctorAppointment />
            )
            ,
            panelID: 'doctor-appointment',
        },
        // {
        //     id: 'doctorcalendar',
        //     content: 'Calendar',
        //     body: (
        //         <DoctorCalendar />

        //     ),
        //     //accessibilityLabel: 'All customers',
        //     panelID: 'doctor-calendar',
        // },
    ];



    return (
        <Page fullWidth title="Appointment Details">
            <Layout>
                <Box my={3} style={{ height: "350px", width: "100%" }} >
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <Box py={3}>{tabs[selected].body}</Box>
                    </Tabs>
                </Box>
            </Layout>
        </Page>

    );
}