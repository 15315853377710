import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@primer/components';
import baseUrl from 'ApiUrl';
import axios from 'axios';
import AimsLogo from 'components/image/logo/aims2health-with-caption.png';
import FeatureImg from 'components/image/featureimage-doctor.png';
import {
  Layout,
  Link,
  Button,
  FormLayout,
  TextField,
  DisplayText,
  Caption,
  Banner,
  TextStyle,
  Page,
  Loading,
  Stack,
  Toast,
  Form,
  InlineError
} from '@shopify/polaris';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#DE3618",
      outlineWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderColor: "#DE3618",
      outlineWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderColor: "#DE3618",
      outlineWidth: 0,
    },
    height: "38px",
    background: "white"
  },
  input: {
    "&::placeholder": {
      color: "#DE3618"
    },
    color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
  },
  focused: {},
  notchedOutline: {}
}));


export default function SignUp() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#DE3618",
      },
      secondary: {
        main: '#330101',
      },
      text: {
        primary: "#000000",
        secondary: "#DE3618"
      }
    },
    typography: {
      fontSize: 22,
    },
    overrides: {
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  });
  const outlinedInputClasses = useOutlinedInputStyles();

  const history = useHistory();

  const [genMessage, setGennarateSuccess] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [buttonText, setButtonText] = useState('Send OTP');

  const [issignupbutton, setSetupButton] = useState(true);

  const [email, setEmail] = useState('');
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const [emailInlineError, setEmailInlineError] = useState('');
  const emailInlineErrorDiv = emailInlineError
    ? <InlineError message={emailInlineError} fieldID="myFieldID" />
    : '';
  const closeEmailError = () => {
    setEmailerror('')
  }
  const [emailerror, setEmailerror] = useState('');
  const emailerrorDiv1 = emailerror
    ? <Banner
      onDismiss={closeEmailError}
      status="critical">
      <p>{emailerror}</p>
    </Banner>
    : '';

  const [otp, setOTP] = useState('');
  const handleValidOtpChange = useCallback((value) => setOTP(value), []);
  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [otpError, setOTPError] = useState('');
  const otpErrorDiv = otpError
    ? <InlineError message={otpError} fieldID="otpID" />
    : '';

  const [firstname, setFirstname] = useState('');
  const handleFirstnameChange = useCallback((value) => setFirstname(value), []);
  const [isDisableFirstname, setIsDisableFirstname] = useState(true);
  const [firstnameError, setFirstnameError] = useState('');
  const firstnameErrorDiv = firstnameError
    ? <InlineError message={firstnameError} fieldID="firstNameID" />
    : '';

  const [lastname, setLastname] = useState('');
  const handleLastnameChange = useCallback((value) => setLastname(value), []);
  const [isDisableLastname, setIsDisableLastname] = useState(true);
  const [lastnameError, setLastnameError] = useState('');
  const lastnameErrorDiv = lastnameError
    ? <InlineError message={lastnameError} fieldID="lastNameID" />
    : '';
    
  const [isDisablePassword, setIsDisablePassword] = useState(true);

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: '',
    showPassword: false,
  });
  console.log("Password", password);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const closeerror = () => {
    setError('')
  }

  const [error, setError] = useState('');
  const errorDiv = error
    ? <Banner
      onDismiss={closeerror}
      status="critical">
      <p>{error}</p>
    </Banner>
    : '';

  const closeSuccess = () => {
    setGennarateSuccess('');
  }

  const successDiv = genMessage
    ? <Banner
      onDismiss={closeSuccess}
      status="success">
      <p>{genMessage}</p>
    </Banner>
    : '';

  const [signupsuccess, setSignupsuccess] = useState('');
  const closeSignupsuccess = () => {
    setSignupsuccess('');
    history.push("/signin");
  }

  const signupSuccessDiv = signupsuccess ? (
    <Toast content={signupsuccess} onDismiss={closeSignupsuccess} />
  ) : null;

  const generateotpbody = JSON.stringify({
    email: email.toLowerCase(),
  });

  const generateOTP = () => {
    if (email === "") {
      setEmailInlineError("Enter email or phone number");
    }
    else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase())) === false) {
      setEmailerror('Please enter valid email id or phone number');
    }
    else {
      setIsLoading(true)
      axios.post(baseUrl.baseUrl + '/doctorapp/email-otp-generation/',
        generateotpbody, { headers })
        .then((res) => {
          setGennarateSuccess(res.data.message);
          setEmailerror("");
          setEmailInlineError("");
          setButtonText("Resend OTP");
          setIsDisableOTPField(false);
          setIsDisablePassword(false);
          setIsDisableFirstname(false);
          setIsDisableLastname(false);
          setSetupButton(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setEmailInlineError("")
          setEmailerror(error.response.data.message);
          setGennarateSuccess("");
          setIsLoading(false);
        })
    }
  }

  const body = JSON.stringify({
    email: email.toLowerCase(),
    password: password.password,
    first_name: firstname,
    otp: otp,
    last_name: lastname
  });

  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }

  //handle button click of Signup form
  const handleSignup = () => {
    if (email === '') {
      setEmailInlineError("Enter email or phone number");
    }
    else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email)) === false) {
      setEmailInlineError('Enter valid email id or phone number');
    }
    else if (otp === '' && firstname === '' && lastname === "") {
      setEmailInlineError("");
      setOTPError("Enter otp");
      setFirstnameError("Enter first name");
      setLastnameError("Enter last name");
    }
    else if (otp === '') {
      setEmailInlineError("");
      setOTPError("Enter otp");
    }
    else if (firstname === '' && lastname === "") {
      setEmailInlineError("");
      setOTPError("");
      setFirstnameError("Enter first name");
      setLastnameError("Enter last name");
    }
    else if (firstname === '') {
      setEmailInlineError("");
      setOTPError("");
      setFirstnameError("Enter first name");
    }
    else if (lastname === "") {
      setEmailInlineError("");
      setOTPError("");
      setFirstnameError("");
      setLastnameError("Enter last name");
    }
    else {
      setIsLoading(true);
      axios.post(baseUrl.baseUrl + '/doctorapp/email-otp-auth-signup/',
        body, { headers })
        .then(result => {
          setIsLoading(false);
          if (result) {
            setEmailerror("");
            setEmailInlineError("");
            setOTPError("");
            setFirstnameError("");
            setLastnameError("");
            setSignupsuccess(result.data.message);
          }
        })
        .catch((error) => {
          setEmailerror("");
          setEmailInlineError("");
          setOTPError("");
          setFirstnameError("");
          setLastnameError("");
          setError(error.response.data.message);
          setIsLoading(false);
        })
    }
  }


  const actualPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "100vh" }} alignItems="center" justifyContent="center">
          <div>
            <Link url="https://aims2health.com"><img src={AimsLogo} alt="Logo" height="64" /></Link>
            <Box my={3}>
              <DisplayText size="large">Register to AIMS2Health</DisplayText>
              <p>One-stop solution for 2<sup>nd</sup> opinion</p>
            </Box>
            <br />
            <Box my={3}>
              <FormLayout>
                <Form onSubmit={generateOTP}>
                  <FormLayout>
                    <TextField
                      value={email}
                      label="Email or Phone Number"
                      placeholder="We would like to verify your email or phone number first"
                      id="emailFieldID"
                      onChange={handleEmailChange}
                    />
                    <div>{emailInlineErrorDiv}</div>
                    <div>{emailerrorDiv1}</div>
                    <div>
                      <Button fullWidth submit primary>{buttonText}</Button>
                    </div>
                    <div>{successDiv}</div>
                  </FormLayout>
                </Form>
                <Form onSubmit={handleSignup}>
                  <div>
                    <FormLayout>
                      <div>
                        <TextField
                          value={otp}
                          label="OTP"
                          placeholder="Enter otp"
                          onChange={handleValidOtpChange}
                          id="otpID"
                          disabled={isDisableOTPField}
                          maxLength={6}
                        />
                        <div>{otpErrorDiv}</div>
                      </div>
                      <FormLayout.Group condensed>
                        <div>
                          <TextField
                            value={firstname}
                            label="First Name"
                            placeholder="Enter first name"
                            onChange={handleFirstnameChange}
                            id="firstNameID"
                            disabled={isDisableFirstname}
                          />
                          <div>{firstnameErrorDiv}</div>
                        </div>
                        <div>
                          <TextField
                            value={lastname}
                            label="Last Name"
                            placeholder="Enter last name"
                            onChange={handleLastnameChange}
                            id="lastNameID"
                            disabled={isDisableLastname}
                          />
                          <div>{lastnameErrorDiv}</div>
                        </div>

                      </FormLayout.Group>
                      <label>Password (Optional)</label>
                      <ThemeProvider theme={theme}>
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          placeholder="Enter your password"
                          type={password.showPassword ? 'text' : 'password'}
                          value={password.password}
                          onChange={handleChange('password')}
                          disabled={isDisablePassword}
                          fullWidth
                          classes={outlinedInputClasses}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={0}
                        />
                      </ThemeProvider>
                      <div>
                        <Caption>&nbsp;&nbsp;&nbsp;&nbsp;By clicking Register, you agree to our &nbsp;&nbsp;
                          <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;and &nbsp;&nbsp;
                          <Link url="https://aims2health.com/privacy-statement.html" external>Privacy Policy</Link>
                        </Caption>
                      </div>
                      <Button fullWidth primary disabled={issignupbutton} submit>Register</Button>
                    </FormLayout>
                  </div>
                  <Box p={1}>{errorDiv}</Box>
                </Form>
              </FormLayout>
            </Box>
            <TextStyle variation="strong">Old User?{' '}&nbsp;&nbsp;</TextStyle><Button primary url="/signin">Log In</Button>
            <br />

          </div>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <div><img
            alt=""
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} src={FeatureImg} /></div>
        </Box>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item fill>
            <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
          </Stack.Item>
          <Stack.Item>
            <Caption>
              <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;
              <Link url="https://aims2health.com/privacy-statement.html" external>Privacy & Cookies</Link>
            </Caption>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </Layout>
  )

  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Page fullWidth>
      {loadingMarkup}
      {actualPageMarkup}
      {signupSuccessDiv}
    </Page>
  );
}