import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    Modal,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Loading,
    Stack,
    Page,
    Caption
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import 'react-dropzone-uploader/dist/styles.css'
import {
    Uploader,
    Icon,
    Loader,
    SelectPicker,
    CheckPicker
} from 'rsuite';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#DE3618",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
            color: "#DE3618"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
    },
    focused: {},
    notchedOutline: {}
}));


export default function DoctorPersonalDetails() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#DE3618",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#DE3618"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                },
              },
            },
          },

    });

    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [imageId, setImageId] = useState('');
    const [imageInfo, setImageInfo] = useState('');

    const [errorsupportnew, setErrorNewpassword] = useState('');
    const [modalActive, setModalActive] = useState(false);
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
    const [forgotPasswordError, setForgotPasswordError] = useState('');
    const [errorsupportold, setErrorOldpassword] = useState('');
    const [uploading] = React.useState(false);

    interface oldPasswordState {
        oldPassword: string;
        showPassword: boolean;
    }

    const [oldPassword, setOldPassword] = React.useState<oldPasswordState>({
        oldPassword: '',
        showPassword: false,
    });
    console.log("Password", oldPassword);

    const handleOldPasswordChange = (prop: keyof oldPasswordState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword({ ...oldPassword, [prop]: event.target.value });
    };

    const handleOldPasswordClickShowPassword = () => {
        setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
    };
    const handleOldMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    // New Password Field
    interface NewPasswordState {
        newPassword: string;
        showNewPassword: boolean;
    }

    const [newPassword, setNewPassword] = React.useState<NewPasswordState>({
        newPassword: '',
        showNewPassword: false,
    });
    console.log("Password", newPassword);

    const handleNewPasswordChange = (prop: keyof NewPasswordState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword({ ...newPassword, [prop]: event.target.value });
    };

    const handleNewPasswordClickShowPassword = () => {
        setNewPassword({ ...newPassword, showNewPassword: !newPassword.showNewPassword });
    };

    const handleNewPasswordMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    // const handleMessageChangeNew = useCallback((value) => setNewPassword(value), [],);

    const closeSuccess = () => {
        setEducationssuccess('');
        setForgotPasswordSuccess('');
    }
    const closeForgotPasswordError = () => {

    }

    const successForgotPasswordFiv = forgotPasswordSuccess
        ? <Banner status="success" onDismiss={closeSuccess}>{forgotPasswordSuccess}</Banner>
        : '';
    const errorForgotPasswordFiv = forgotPasswordError
        ? <Banner status="critical" onDismiss={closeForgotPasswordError}>{forgotPasswordError}</Banner>
        : '';

    const errorSupportold = errorsupportold
        ? <TextStyle variation="negative">{errorsupportold}</TextStyle>
        : '';


    const errorSupportnew = errorsupportnew
        ? <TextStyle variation="negative">{errorsupportnew}</TextStyle>
        : '';

    const resetPassword = () => {
        setModalActive((modalActive) => !modalActive)
        setOldPassword({
            oldPassword: '',
            showPassword: false,
        });
        setNewPassword({
            newPassword: '',
            showNewPassword: false,
        });
        setForgotPasswordSuccess('');
        setForgotPasswordError('');
        setErrorOldpassword('');
        setErrorNewpassword('');
    }

    const token = function () {
        return localStorage.getItem("token");
    }

    let userToken: string = token()!
    if (userToken == null) {
    }
    const resetbody = JSON.stringify({
        "old_password": oldPassword.oldPassword,
        "new_password": newPassword.newPassword
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const resetgetPasswordApicall = () => {
        if (oldPassword.oldPassword === '') {
            setErrorOldpassword('Please enter old password');
        }
        else if (newPassword.newPassword === '') {
            setErrorNewpassword('Please enter new password');
        }
        else if (oldPassword.oldPassword != newPassword.newPassword) {
            setErrorOldpassword('Old password does not matched with New password');
            setErrorNewpassword('New password does not matched with Old password');
        }
        else {
            setErrorOldpassword('');
            setErrorNewpassword('');
            axios.put(baseUrl.baseUrl + '/profileapp/reset-password/',
                resetbody, { headers })
                .then(result => {
                    setForgotPasswordSuccess(result.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    setForgotPasswordError(error.response.data.message);

                })
        }
    }

    const [userfirstName, setFirstName] = useState('');
    const handleuserFirstNameChange = useCallback((value) => setFirstName(value), [],);

    const [userlastName, setLastName] = useState('');
    const handleuserLastNameChange = useCallback((value) => setLastName(value), [],);

    const [userEmail, setEmail] = useState('');
    const handleUserEmailChange = useCallback((value) => setEmail(value), [],);

    const [userphone, setPhoneget] = useState('');
    const handleuserPhoneChange = useCallback((value) => setPhoneget(value), [],);

    const [genderList, setGenderValues] = useState([]);

    const [fMSName, setFMSName] = useState('');
    const handleFMSChange = useCallback((value) => setFMSName(value), [],);

    const [language, setLanguage] = useState([]);
    const [languageSelected, setLanguageSelected] = useState([]);
    const handlelanguageChange = useCallback((value) => setLanguageSelected(value), [],);

    const [getlanguagelist, setgetlanguagelist] = useState(["Please select language"]);
    console.log("Language List===> ", getlanguagelist);
    const onLanguageClear = () => {
        setLanguageSelected([]);  // this will reset the selected value
    };

    const [address, setAddress] = useState('');
    const handleAddressChange = useCallback((value) => setAddress(value), [],);

    const [city, setCity] = useState('');
    const handleCityChange = useCallback((value) => setCity(value), [],);

    const [state, setState] = useState('');
    const handleStateChange = useCallback((value) => setState(value), [],);

    const [pincode, setPincode] = useState('');
    const handlePincodeChange = useCallback((value) => setPincode(value), [],);

    const closeerror = () => {
        setError('')
    }
    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const [educationsuccess, setEducationssuccess] = useState('');


    const successDiv = educationsuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{educationsuccess}</p>
        </Banner>
        : '';

    //const [genderget, setGendeget] = useState('');

    const [selected1, setSelected1] = useState();
    const handleSelectnewChange = useCallback((value) => setSelected1(value), []);

    const gentderApicall = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/indivoapp/api/gender/dropdown_list',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setGenderValues(res.data.genderList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    const getlanguageAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/language_list_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setLanguage(res.data.languageList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const getPersonalDetailsApi = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/get_personal_details',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setFirstName(res.data.personalDetails.firstname);
                setLastName(res.data.personalDetails.lastname);
                setEmail(res.data.personalDetails.email);
                setSelected1(res.data.personalDetails.gender.value);
                setPhoneget(res.data.personalDetails.phoneNumber);
                setAddress(res.data.personalDetails.address);
                setFMSName(res.data.personalDetails.guardianName);
                setState(res.data.personalDetails.state);
                setCity(res.data.personalDetails.city);
                const a = (res.data.personalDetails.pincode);
                setPincode(a.toString());
                setgetlanguagelist([]);
                let getlanguageList = [] as any;
                let getlanguageValue = [] as any;
                let languagevalue = res.data.personalDetails.languageProficiency;
                for (var i = 0; i < languagevalue.length; i++) {
                    getlanguageList.push(languagevalue[i].label);
                    getlanguageValue.push(languagevalue[i].value);
                }
                setgetlanguagelist(getlanguageList.join(', '));
                setLanguageSelected(getlanguageValue);

                if (res.data.personalDetails.doctorImage) {
                    setImageInfo(res.data.personalDetails.doctorImage.document);
                }

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getPersonalDetailsApi();
        gentderApicall();
        getlanguageAPI();
    }, []);

    const body = JSON.stringify({
        firstname: userfirstName,
        lastname: userlastName,
        email: userEmail,
        guardianName: fMSName,
        phone: userphone,
        gender: selected1,
        doctorImageId: imageId,
        address: address,
        city: city,
        state: state,
        pincode: pincode,
        languageId: languageSelected
    });

    const editDoctorSubmit = () => {
        console.log(body)
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/onboarding/edit_personal_details',
            body, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setEducationssuccess(result.data.message)
                    getPersonalDetailsApi();
                }
            })
            .catch((error) => {
                setError(error.response.data.message);
                setIsLoading(false);
            })
    }

    // Reset Password Modal
    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={resetPassword}
            title="Reset Password"
            primaryAction={{
                content: 'Reset',
                onAction: resetgetPasswordApicall,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <div>
                            <label>Old Password</label>
                            <Box pt={2}>
                                <ThemeProvider theme={theme}>
                                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                                    <OutlinedInput
                                        id="oldpassword"
                                        placeholder="Enter your old password"
                                        type={oldPassword.showPassword ? 'text' : 'password'}
                                        value={oldPassword.oldPassword}
                                        onChange={handleOldPasswordChange('oldPassword')}
                                        fullWidth
                                        classes={outlinedInputClasses}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleOldPasswordClickShowPassword}
                                                    onMouseDown={handleOldMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {oldPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={0}
                                    />
                                </ThemeProvider>
                            </Box>
                            <InlineError message={errorSupportold} fieldID="oldpassword" />
                        </div>
                        <div>
                            <label>New Password</label>
                            <Box pt={2}>
                                <ThemeProvider theme={theme}>
                                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                                    <OutlinedInput
                                        id="newpassword"
                                        placeholder="Enter your password"
                                        type={newPassword.showNewPassword ? 'text' : 'password'}
                                        value={newPassword.newPassword}
                                        onChange={handleNewPasswordChange('newPassword')}
                                        fullWidth
                                        classes={outlinedInputClasses}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleNewPasswordClickShowPassword}
                                                    onMouseDown={handleNewPasswordMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {newPassword.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={0}
                                    />
                                </ThemeProvider>
                            </Box>
                            <InlineError message={errorSupportnew} fieldID="newpassword" />
                        </div>
                        <div>{successForgotPasswordFiv}</div>
                        <div>{errorForgotPasswordFiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const uploadbody = {
        'typeName': 'doctor-image'
    };

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const actualPageMarkup = (
        <div>
            <Card secondaryFooterActions={[{
                content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                    setIsEdit((isEdit) => !isEdit);
                    setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }
            }]}
                primaryFooterAction={{ content: 'Submit Details', onAction: editDoctorSubmit, disabled: isEnableSubmit }}
            >
                <Card.Header
                    actions={[
                        {
                            content: 'Reset Password',
                            onAction: resetPassword
                        },
                    ]}
                    title="Personal Details"
                >
                </Card.Header>
                <Box p={3}>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="First Name"
                                    value={userfirstName}
                                    onChange={handleuserFirstNameChange}
                                    placeholder="Please enter your first name"
                                    readOnly={isEdit}
                                />
                                <br />
                                <TextField
                                    label="Last Name"
                                    value={userlastName}
                                    onChange={handleuserLastNameChange}
                                    placeholder="Please enter your last name"
                                    readOnly={isEdit}
                                />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Doctor Image Upload</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageId(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                            disabled={isEdit}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Icon icon="avatar" size="3x" />
                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>Please upload profile picture of 400px (Width) X 400px (Height)</Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <TextField
                                label="Email"
                                type="email"
                                value={userEmail}
                                onChange={handleUserEmailChange}
                                placeholder="Modify your email"
                                readOnly={isEdit}
                            />
                            <div>
                                <p style={{ marginBottom: "5px" }}>Gender</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectnewChange}
                                    searchable={false}
                                    data={genderList}
                                    value={selected1}
                                    menuStyle={{ zIndex: 200, color: "rgb(76, 2, 2) !important" }}
                                    disabled={isEdit}
                                />
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <TextField
                                label="Father's / Mother's / Spouse Name"
                                value={fMSName}
                                onChange={handleFMSChange}
                                placeholder="Enter your father's / mother's / spouse Name"
                                readOnly={isEdit}
                            />
                            <TextField
                                label="Address"
                                value={address}
                                onChange={handleAddressChange}
                                placeholder="Enter your address"
                                readOnly={isEdit}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <TextField
                                label="Phone Number"
                                value={userphone}
                                onChange={handleuserPhoneChange}
                                placeholder="Modify your phone number"
                                readOnly={isEdit}
                                maxLength={10}
                                prefix="+91"
                            />
                            <TextField
                                label="City"
                                value={city}
                                onChange={handleCityChange}
                                placeholder="Enter your City"
                                readOnly={isEdit}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <TextField
                                label="State"
                                value={state}
                                onChange={handleStateChange}
                                placeholder="Enter your state"
                                readOnly={isEdit}
                            />
                            <TextField
                                label="Pincode"
                                value={pincode}
                                onChange={handlePincodeChange}
                                placeholder="Enter your pincode"
                                readOnly={isEdit}
                                maxLength={6}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Language Competence</p>
                                <CheckPicker
                                    sticky
                                    placeholder="Please select language"
                                    data={language}
                                    value={languageSelected}
                                    onSelect={handlelanguageChange}
                                    onClean={onLanguageClear}
                                    style={{ width: 500 }}
                                    menuStyle={{ color: "rgb(76, 2, 2) !important", zIndex: 1000 }}
                                    disabled={isEdit}
                                />
                            </div>
                            <div>
                                &nbsp;
                                </div>
                        </FormLayout.Group>
                        <Box my={3}>{successDiv}</Box>
                        <Box my={3}>{errorDiv}</Box>
                    </FormLayout>
                </Box>
            </Card>
        </div >
    )

    return (
        <Page>
            {loadingMarkup}
            {actualPageMarkup}
            {modalMarkup}
        </Page>

    );
}
