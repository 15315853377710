import { getAppointmentAction, getCaseAction, getTaskAction } from '../actions/Actions';
import { Dispatch } from 'redux';
import {
  CaseGetActionTypes, CASE_LIST_FAIL, CASE_LIST_LOADING, CASE_LIST_SUCCESS,
  TaskGetActionTypes, TASK_LIST_LOADING, TASK_LIST_SUCCESS, TASK_LIST_FAIL, GET_APPOINTMENT_LOADING, GET_APPOINTMENT_SUCCESS, GET_APPOINTMENT_FAIL, AppointmentGetActionTypes
} from '../types/ActionTypes';
import axios from 'axios';
import baseUrl from '../../ApiUrl';

const userToken = localStorage.getItem("token");
console.log("DOCTORCASELISTTOKEN=======>", userToken);
const headers = {
  'Accept': 'application/json',
  'Content-type': 'application/json',
  'Authorization': `Token ${userToken}`
};

export const getPatientCaseList = () => {
  return function (dispatch: Dispatch<CaseGetActionTypes>) {

    dispatch({
      type: CASE_LIST_LOADING,
    });

    axios.get(baseUrl.baseUrl + '/doctorapp/api/doctorcase/doctor_case_list', { headers })
      .then((res) => {
        let resData = res.data.patientCaseList;
        dispatch(getCaseAction(resData));
        console.log("DOCTORCASELIST=======>", resData);
        dispatch({
          type: CASE_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CASE_LIST_FAIL,
        });
      })
  };
};

// Get Doctor task List
export const getDoctorTaskList = () => {
  return function (dispatch: Dispatch<TaskGetActionTypes>) {

    dispatch({
      type: TASK_LIST_LOADING,
    });

    axios.get(baseUrl.baseUrl + '/doctorapp/api/doctorcase/doctor_task_list', { headers })
      .then((res) => {
        let resData = res.data.taskDataList;
        dispatch(getTaskAction(resData));
        console.log("TaskLIST=======>", resData);
        dispatch({
          type: TASK_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("Task List Error Record====", error);
        dispatch({
          type: TASK_LIST_FAIL,
        });
      })
  };
};

// Get Doctor task List
export const getDoctorAppointmentList = (selectionTypeBody: any) => {
  return function (dispatch: Dispatch<AppointmentGetActionTypes>) {

    dispatch({
      type: GET_APPOINTMENT_LOADING,
    });

    axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/get_appointment_list_for_doctor',
      selectionTypeBody, { headers })
      .then((res) => {
        let resData = res.data.appointmentRequestList;
        dispatch(getAppointmentAction(resData));
        console.log("APITaskIST=======>", resData);
        dispatch({
          type: GET_APPOINTMENT_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("Appointment List Error Record====", error);
        dispatch({
          type: GET_APPOINTMENT_FAIL,
        });
      })
  };
};