import React, { useState, useCallback, useEffect } from 'react';
import baseUrl from '../../../ApiUrl';
import {
    Card,
    Page,
    Button,
    Modal,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Spinner,
    DescriptionList,
    Loading,
    Caption,
    Toast,
    Stack,
} from '@shopify/polaris';
import axios from 'axios';
import { Box } from '@primer/components';
import { Uploader, Loader, Icon } from 'rsuite';
const styles = {
    width: 80,
    height: 80,
    borderRadius: 40
};
export default function AccountDetails() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [optFieldDisable, setOTPFieldDisable] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    // Close Email OTP Generation Success Banner
    const closeOTPGenerationSuccess = () => {
        setOtpGenerationSuccess('');
    }
    // Email OTP Generation Success Banner
    const [otpGenerationSuccess, setOtpGenerationSuccess] = useState('');
    const OTPGenerationSuccessDiv = otpGenerationSuccess
        ? <Banner status="success" onDismiss={closeOTPGenerationSuccess}><p>{otpGenerationSuccess}</p></Banner>
        : '';
    // Close Email OTP Generation Error Banner
    const closeOTPGenerationError = () => {
        setOtpGenerationError('');
    }
    // Email OTP Generation Error Banner
    const [otpGenerationError, setOtpGenerationError] = useState('');
    const OTPGenerationErrorDiv = otpGenerationError
        ? <Banner status="critical" onDismiss={closeOTPGenerationError}><p>{otpGenerationError}</p></Banner>
        : '';

    const [supportOldPassword, setOldPassword] = useState('');
    const handleMessageChangeOld = useCallback(
        (value) => setOldPassword(value),
        [],
    );
    const [errorsupportold, setErrorOldpassword] = useState('');
    const errorSupportold = errorsupportold
        ? <TextStyle variation="negative">{errorsupportold}</TextStyle>
        : '';

    const [supportNewPassword, setNewPassword] = useState('');
    const handleMessageChangeNew = useCallback(
        (value) => setNewPassword(value),
        [],
    );
    const [errorsupportnew, setErrorNewpassword] = useState('');
    const errorSupportnew = errorsupportnew
        ? <TextStyle variation="negative">{errorsupportnew}</TextStyle>
        : '';

    // Add Phone Number field
    const [addPhone, setAddPhone] = useState('');
    const handleAddPhoneChange = useCallback((newValue) => {
        setAddPhone(newValue);
    }, []);

    const [otp, setOTP] = useState('');
    const handleOTPChange = useCallback((newValue) => {
        setOTP(newValue);
    }, []);

    const [otpFieldError, setOTPFieldError] = useState('');
    const otpFieldErrorDiv = otpFieldError
        ? <TextStyle variation="negative">{otpFieldError}</TextStyle>
        : '';

    // Reset Password Modal
    const [resetPasswordModalActive, setResetPasswordModalActive] = useState(false);
    const resetPassword = () => {
        setResetPasswordModalActive((resetPasswordModalActive) => !resetPasswordModalActive)
        setOldPassword('');
        setNewPassword('');
        setForgotpasswordRes('');
        setErrorOldpassword('');
    }

    const [forgotpasswordRes, setForgotpasswordRes] = useState('');
    const ForgotpasswordRes = forgotpasswordRes
        ? <Banner>{forgotpasswordRes}</Banner>
        : '';

    const [isPopupLoading, setisPopupLoading] = useState(false);
    const sectionForm = isPopupLoading ? (
        <Spinner
            accessibilityLabel="Loading form field"
            hasFocusableParent={false}
            size="small"
        />
    ) : (
        null
    );

    const [addEmail, setAddEmail] = useState('');
    const handleAddEmailChange = useCallback((newValue) => {
        setAddEmail(newValue);
    }, []);
    const [addEmailError, setAddEmailError] = useState('');
    const addEmailErrordiv = addEmailError
        ? <TextStyle variation="negative">{addEmailError}</TextStyle>
        : '';

    // Add Phone Number Modal
    const [addPhoneModalactive, setAddPhoneModalactive] = useState(false);
    const handleAddPhoneModalChange = () => {
        setAddPhoneModalactive((addPhoneModalactive) => !addPhoneModalactive);
        setAddPhoneError('')
        setAddPhone('');
        setOTP('');
        setOtpGenerationSuccess('');
        setOtpGenerationError('');
        setAddPhoneAPISuccess('');
        setAddPhoneAPIError('');
    };

    const [addPhoneError, setAddPhoneError] = useState('');
    const addPhoneErrordiv = addPhoneError
        ? <TextStyle variation="negative">{addPhoneError}</TextStyle>
        : '';


    const emailotpbody = JSON.stringify({
        email: addEmail.toLowerCase()
    });

    const photpbody = JSON.stringify({
        email: addPhone.toLowerCase()
    });

    // Add Email Modal
    const [addEmailModalactive, setAddEmailModalactive] = useState(false);
    const handleAddEmailModalChange = () => {
        setAddEmailModalactive((addEmailModalactive) => !addEmailModalactive);
        setAddEmailError('');
        setOTP('');
        setOtpGenerationSuccess('');
        setOtpGenerationError('');
        setAddEmailAPISuccess('');
        setAddEmailAPIError('');
    };

    const [addEmailAPISuccess, setAddEmailAPISuccess] = useState('');
    const addEmailAPISuccessDiv = addEmailAPISuccess
        ? <Banner status="success"><p>{addEmailAPISuccess}</p></Banner>
        : '';
    const [addEmailAPIError, setAddEmailAPIError] = useState('');
    const addEmailAPIErrorDiv = addEmailAPIError
        ? <Banner status="critical"><p>{addEmailAPIError}</p></Banner>
        : '';

    const [addPhoneAPISuccess, setAddPhoneAPISuccess] = useState('');
    const addPhoneAPISuccessDiv = addPhoneAPISuccess
        ? <Banner status="success"><p>{addPhoneAPISuccess}</p></Banner>
        : '';
    const [addPhoneAPIError, setAddPhoneAPIError] = useState('');
    const addPhoneAPIErrorDiv = addPhoneAPIError
        ? <Banner status="critical"><p>{addPhoneAPIError}</p></Banner>
        : '';

    const token = function () {
        return localStorage.getItem("token");
    }
    let userToken: string = token()!
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Email OTP Generation API
    const emailOtpGeneration = () => {
        if (addEmail === '') {
            setAddEmailError("Please enter email id");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(addEmail.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(addEmail.toLowerCase())) === false) {
            setAddEmailError('Please enter valid email id');
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
                emailotpbody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setOtpGenerationSuccess(result.data.message);
                        setOTPFieldDisable(true);
                    }
                    else {
                        // history.push("/signin");
                    }
                })
                .catch((error) => {
                    setOtpGenerationError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Email Verification Body
    const emailVerificationBody = JSON.stringify({
        email: addEmail.toLowerCase(),
        otp: otp
    });

    // Email OTP Verification API
    const emailOtpVerification = () => {
        if (addEmail === '' && otp === '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("Please otp");
        }
        else if (addEmail === '' && otp != '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("");
        }
        else if (addEmail != '' && otp === '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("Please otp");
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            setOtpGenerationSuccess('');
            setOtpGenerationError('');
            axios.post(baseUrl.baseUrl + '/profileapp/api/userDetails/set_email_or_phone',
                emailVerificationBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setAddEmailAPISuccess(result.data.message);
                    getUserProfile();
                    handleAddEmailModalChange();
                })
                .catch((error) => {
                    setAddEmailAPIError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Phone OTP Generation API
    const phoneOtpGeneration = () => {
        if (addPhone === '') {
            setAddPhoneError("Please enter phone number");
        }
        else {
            setIsLoading(true);
            setAddPhoneError('');
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
                photpbody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setOtpGenerationSuccess(result.data.message);
                        setOTPFieldDisable(false);
                    }
                    else {
                        // history.push("/signin");
                    }
                })
                .catch((error) => {
                    setOtpGenerationError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Phone Verification Body
    const phoneverificationBody = JSON.stringify({
        email: addPhone,
        otp: otp
    });

    // Phone OTP Verification API
    const phoneOtpVerification = () => {
        if (addPhone === '' && otp === '') {
            setAddPhoneError("Please enter email id");
            setOTPFieldError("Please otp");
        }
        else if (addPhone === '' && otp != '') {
            setAddPhoneError("Please enter email id");
            setOTPFieldError("");
        }
        else if (addPhone != '' && otp === '') {
            setAddPhoneError("");
            setOTPFieldError("Please otp");
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            setOtpGenerationSuccess('');
            setOtpGenerationError('');
            axios.post(baseUrl.baseUrl + '/profileapp/api/userDetails/set_email_or_phone',
                phoneverificationBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setAddPhoneAPISuccess(result.data.message);
                    getUserProfile();
                    handleAddPhoneModalChange();
                })
                .catch((error) => {
                    setAddPhoneAPIError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Reset Password Body
    const resetbody = JSON.stringify({
        "old_password": supportOldPassword,
        "new_password": supportNewPassword
    });

    // Reset Password API
    const resetgetPasswordApicall = () => {
        setisPopupLoading(true);
        if (supportOldPassword === '') {
            setErrorOldpassword('Please enter old password');
        }
        else if (supportNewPassword === '') {
            setErrorNewpassword('Please enter new password');
        }
        else {
            setErrorOldpassword('');
            setErrorNewpassword('');
            axios.put(baseUrl.baseUrl + '/profileapp/reset-password/',
                resetbody, { headers })
                .then(result => {
                    setisPopupLoading(false);
                    setForgotpasswordRes(result.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    setForgotpasswordRes(error.response.data.message);

                })
        }
    }

    // Get userprofile API
    const getUserProfile = () => {
        axios.get(baseUrl.baseUrl + '/profileapp/api/userDetails/get_details', { headers })
            .then(result => {
                //setIsLoading(false);
                let userDetails = result.data.userDetails;
                setUsername(userDetails.username);
                setFirstName(userDetails.firstName);
                setLastName(userDetails.lastName);
                setEmail(userDetails.email);
                setPhoneNumber(userDetails.phone);
            })
            .catch((error) => {

                //setIsLoading(false);
            })
    }
    // signeture section start .....
    const doctorID = localStorage.getItem("doctorID");

    const [imageId, setImageId] = useState('');
    const [imageInfo, setImageInfo] = useState('');
    const [signetureImageInfo, setSignetureImageInfo] = useState('');
    const [uploading] = React.useState(false);
    const uploadbody = {
        'typeName': 'doctorSignature'
    };

    const closeImageUploadError = () => {
        setImageUploadError('');
    }

    const [imageUploadError, setImageUploadError] = useState('');
    const imageUploadErrorDiv = imageUploadError
        ? <Banner
            onDismiss={closeImageUploadError}
            status="critical">
            <p>{imageUploadError}</p>
        </Banner>
        : '';

    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [successToastactive, setSuccessToastactive] = useState(false);
    const successToasttoggleActive = useCallback(() => setSuccessToastactive((successToastactive) => !successToastactive), []);
    const successToastMarkup = successToastactive ? (
        <Toast content={successToastMessage} onDismiss={successToasttoggleActive} />
    ) : null;

    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [errorToastactive, setErrorToastactive] = useState(false);
    const errorToasttoggleActive = useCallback(() => setErrorToastactive((errorToastactive) => !errorToastactive), []);
    const errorToastMarkup = errorToastactive ? (
        <Toast content={errorToastMessage} error onDismiss={errorToasttoggleActive} />
    ) : null;

    // Add Signature Body
    const addSignatureBody = {
        "doctorId": doctorID,
        "signatureId": imageId
    }
    // Add signeture api call...
    const addSigneture = () => {
        if (imageId === '') {
            setImageUploadError("You do not select any image for upload")
        }
        else {
            setIsLoading(true);
            setImageUploadError('');
            axios.post(baseUrl.baseUrl + '/doctorapp/api/sign/add_signature',
                addSignatureBody, { headers })
                .then((res) => {
                    setIsLoading(false);
                    setSuccessToastMessage(res.data.message);
                    successToasttoggleActive();
                    getDocSigneture();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setErrorToastMessage(error.response.data.message);
                    errorToasttoggleActive();
                })
        }

    }

    // list signeture api call
    const getDocSigneture = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/doctorapp/api/sign/get_signature',
            { "doctorId": doctorID }, { headers })
            .then((res) => {
                setIsLoading(false);
                // setSuccessToastMessage(res.data.message);
                if (res.data.Signature.doctorSignature) {
                    setSignetureImageInfo(res.data.Signature.doctorSignature.document);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }


    useEffect(() => {
        getUserProfile();
        getDocSigneture();
    }, []);

    // Reset Password Modal
    const modalMarkup = (
        <Modal
            open={resetPasswordModalActive}
            onClose={resetPassword}
            title="Reset Password"
            footer={ForgotpasswordRes}
            primaryAction={{
                content: 'Send',
                onAction: resetgetPasswordApicall,
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField
                        label="Old password"
                        placeholder="Enter old password"
                        value={supportOldPassword}
                        onChange={handleMessageChangeOld}
                        id="oldpassword"
                    />
                    <InlineError message={errorSupportold} fieldID="oldpassword" />
                    <TextField
                        label="New password"
                        placeholder="Enter new password"
                        value={supportNewPassword}
                        onChange={handleMessageChangeNew}
                        id="newpassword"
                    />
                    <InlineError message={errorSupportnew} fieldID="newpassword" />
                </FormLayout>
                <div style={{ textAlign: 'center' }}>{sectionForm}</div>
            </Modal.Section>
        </Modal>
    );

    // Add Email Modal
    const addEmailModalMarkup = (
        <Modal
            open={addEmailModalactive}
            onClose={handleAddEmailModalChange}
            title="Add Email"
            primaryAction={{
                content: 'Add',
                onAction: emailOtpVerification,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleAddEmailModalChange,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="Email"
                            value={addEmail}
                            onChange={handleAddEmailChange}
                            placeholder="Enter email"
                            id="emailID"
                        />
                        <InlineError message={addEmailErrordiv} fieldID="emailID" />
                    </div>
                    <div>
                        <br />
                        <Button onClick={emailOtpGeneration}>Generate OTP</Button>
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{OTPGenerationSuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{OTPGenerationErrorDiv}</FormLayout.Group>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter OTP"
                            id="otpID"
                            disabled={optFieldDisable}
                        />
                        <InlineError message={otpFieldErrorDiv} fieldID="otpID" />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{addEmailAPISuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{addEmailAPIErrorDiv}</FormLayout.Group>
            </Modal.Section>
        </Modal>
    )

    // Add Phone Modal
    const addPhoneModalMarkup = (
        <Modal
            open={addPhoneModalactive}
            onClose={handleAddPhoneModalChange}
            title="Add Phone Number"
            primaryAction={{
                content: 'Add',
                onAction: phoneOtpVerification,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleAddPhoneModalChange,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="Phone Number"
                            value={addPhone}
                            onChange={handleAddPhoneChange}
                            placeholder="Enter Phone Number"
                            id="phoneID"
                        />
                        <InlineError message={addPhoneErrordiv} fieldID="phoneID" />
                    </div>
                    <div>
                        <br />
                        <Button onClick={phoneOtpGeneration}>Generate OTP</Button>
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{OTPGenerationSuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{OTPGenerationErrorDiv}</FormLayout.Group>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter OTP"
                            id="otpID"
                            disabled={optFieldDisable}
                        />
                        <InlineError message={otpFieldErrorDiv} fieldID="otpID" />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{addPhoneAPISuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{addPhoneAPIErrorDiv}</FormLayout.Group>
            </Modal.Section>
        </Modal>
    )

    // Table Markup
    const userprofilePageMarkup = (
        <Card>
            <Card.Header>
                <Button onClick={getUserProfile}>Reload</Button>
                <Button onClick={resetPassword}>Reset Password</Button>
            </Card.Header>
            <Card.Section>
                <DescriptionList
                    items={[
                        {
                            term: 'Username',
                            description: userName
                        },
                        {
                            term: 'First Name',
                            description: firstName
                        },
                        {
                            term: 'Last Name',
                            description: lastName
                        },
                        {
                            term: 'Email',
                            description: email === "" ? (<Button onClick={handleAddEmailModalChange}>Add Email</Button>) : email
                        },
                        {
                            term: 'Phone Number',
                            description: phoneNumber === "" ? (<Button onClick={handleAddPhoneModalChange}>Add Phone Number</Button>) : phoneNumber
                        },
                    ]}
                />
            </Card.Section>
        </Card>
    );
    // Signature Upload Markup
    const userprofileSigneture = (
        <Card>
            <Card.Header title="Signature"></Card.Header>
            <Card.Section>
                <FormLayout.Group condensed>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Doctor Signature Upload</p>
                        <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={3}>
                            <Uploader
                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                draggable
                                data={uploadbody}
                                name="document"
                                listType="text"
                                onSuccess={(response: Object,) => {
                                    let res = JSON.stringify(response);
                                    setImageId(JSON.parse(res).image.id);
                                    setImageInfo(JSON.parse(res).image.document);
                                }}
                            >
                                <button style={styles} type="button">
                                    {uploading && <Loader backdrop center />}
                                    {imageInfo ? (
                                        <img src={imageInfo} alt="Uploaded Signature" width="100%" height="100%" />
                                    ) : (
                                        <Icon icon="file-text" size="3x" />
                                    )}
                                </button>
                            </Uploader>
                        </Box>
                        <Stack>
                            <Stack.Item fill>
                                <Caption>Please upload Signature picture of 400px (Width) X 200px (Height)</Caption>
                            </Stack.Item>
                            <Stack.Item>
                                <Box py={1}>
                                    <Button primary onClick={addSigneture} >Add Signature</Button>
                                </Box>
                            </Stack.Item>
                        </Stack>


                    </div>
                    <img src={signetureImageInfo} alt="" width="100%" height="100%" />
                </FormLayout.Group>
                <Box p={1}>
                    <FormLayout.Group condensed>
                        <div>{imageUploadErrorDiv}</div>
                        <div></div>
                    </FormLayout.Group>
                </Box>
            </Card.Section>
        </Card>
    );

    return (
        <Page fullWidth title="Account Details">
            {loadingMarkup}
            {userprofilePageMarkup}
            {modalMarkup}
            {addEmailModalMarkup}
            {addPhoneModalMarkup}
            {userprofileSigneture}
            {successToastMarkup}
            {errorToastMarkup}
        </Page>
    );
}
