import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    Stack,
    FormLayout,
    TextField,
    Banner,
    Thumbnail,
    Button,
    Loading,
    DataTable,
    Caption,
    Modal,
    TextStyle,
    TextContainer,
    Page,
    ExceptionList
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../ApiUrl'
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function ProfessionalMembership() {
    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');
    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);
    const [membershipName, setMembershipName] = useState('');
    const handleMembershipNameChange = useCallback((value) => setMembershipName(value), []);
    const [membershipNumber, setMembershipNumber] = useState('');
    const handleMembershipNumberChange = useCallback((value) => setMembershipNumber(value), []);
    const [editMembershipId, setEditMembershipId] = useState('');
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
    const handleDateChange = (date: Date | null) => { setSelectedDate(date); };
    const [description, setDescription] = useState('');
    const handleDescriptionChange = useCallback((value) => setDescription(value), []);
    const [membershipModalActive, setMembershipModalActive] = useState(false);

    const handleToggle = () => {
        setMembershipModalActive((membershipModalActive) => !membershipModalActive);
        setAddMembershipSuccess("");
        setEditMembershipId("");
        setImageInfo("");
        setImageId("");
        setSelectedDate(new Date(),);
        setMembershipNumber("");
        setMembershipName("");
        setDescription("");
    }


    const membershipBody = {
        name: membershipName,
        idNumber: membershipNumber,
        validityDate: selectedDate,
        supportdocId: imageId,
        description: description
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeAddEducationDetailsError = () => {
        setAddMembershipError('')
    }

    const [addMembershipError, setAddMembershipError] = useState('');
    const errorAddMembershipDiv = addMembershipError
        ? <Banner
            onDismiss={closeAddEducationDetailsError}
            status="critical">
            <p>{addMembershipError}</p>
        </Banner>
        : '';

    const [addMembershipSuccess, setAddMembershipSuccess] = useState('');
    const closeAddEducationSuccess = () => {
        setAddMembershipSuccess('');
    }
    const successAddMembershipDiv = addMembershipSuccess
        ? <Banner
            onDismiss={closeAddEducationSuccess}
            status="success">
            <p>{addMembershipSuccess}</p>
        </Banner>
        : '';

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const uploadbody = {
        'typeName': 'doctor-license'
    };

    function editMembership(response: any) {
        setMembershipModalActive((membershipModalActive) => !membershipModalActive);
        setEditMembershipId(response.id);
        let docURL = (response.supportingDocuments) ? response.supportingDocuments.userFile.document : 'document.png';
        let imgId = (response.supportingDocuments) ? response.supportingDocuments.userFile.id : '';
        setImageInfo(docURL);
        setImageId(imgId);
        setSelectedDate(response.validityDate);
        setMembershipNumber(response.idNumber);
        setMembershipName(response.name);
        setDescription(response.description);

    }

    // Get professional membership list
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [membershipList, setMembershipList] = useState<any[]>([]);
    const getProfessionalMembershipList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/doctormembership/get_member_details_by_doctor', { headers }).then((res) => {
            setIsLoading(false);
            let response = res.data.membershipList;
            let professionMembershipList = [];
            for (var i = 0; i < response.length; i++) {
                let selectedResponse = response[i];
                let docIDImg = (response[i].supportingDocuments) ? response[i].supportingDocuments.userFile.document : 'document.png';
                let membershipName = (response[i].name === null) ? '' : response[i].name;
                let membershipNumber = (response[i].idNumber === "") ? '' : response[i].idNumber;
                let membershipDescription = (response[i].description === "") ? '' : response[i].description;
                let membershipValidity = (response[i].validityDate === null) ? '' : response[i].validityDate;
                professionMembershipList.push([
                    <Button icon={NoteMinor} size="large" onClick={() => {
                        setDocViwerActive(true);
                        setImageInfo(docIDImg);
                    }}></Button>,
                    <TextStyle variation="strong" key="number">{membershipName}</TextStyle>,
                    <TextStyle variation="strong" key="number">{membershipNumber}</TextStyle>,
                    (new Date(membershipValidity)).toLocaleDateString('en-US', DATE_OPTIONS),
                    <TextStyle variation="strong" key="number">{truncate(membershipDescription)}</TextStyle>,
                    <Button icon={EditMinor} size="slim" onClick={() => editMembership(selectedResponse)}></Button>
                ]);
                setMembershipList(professionMembershipList);
            }

        })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Add membership Details
    const addMembership = () => {
        setIsLoading(true);
        let url, membershipSubmitBody;
        console.log(membershipSubmitBody);
        if (editMembershipId) {
            url = baseUrl.baseUrl + '/doctorapp/api/doctormembership/edit_doctor_membership';
            membershipSubmitBody = Object.assign(membershipBody, { membershipId: editMembershipId })
            console.log("Edit Membershipbody===> ",membershipSubmitBody);
        }
        else {
            url = baseUrl.baseUrl + '/doctorapp/api/doctormembership/add_doctor_membership';

        }
        axios.post(url, JSON.stringify(membershipBody), { headers }).then(result => {
            setIsLoading(false);
            if (result) {
                getProfessionalMembershipList();
                setAddMembershipSuccess(result.data.message);
            }
        }).catch((error) => {
            setAddMembershipError(error.response.data.message);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getProfessionalMembershipList();
    }, []);

    const professionalMembershipList = (
        <Card.Section>
            {membershipList && membershipList.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                    ]}
                    headings={[
                        'Document',
                        'Membership Name',
                        'Membership Number',
                        'Validity date',
                        'Description',
                        'Edit'
                    ]}
                    rows={membershipList}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Professional Membership Details is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    )

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };



    const addProfessionalMembership = (
        <Modal large
            open={membershipModalActive}
            onClose={handleToggle}
            title={editMembershipId ? "Edit professional Membership" : "Add professional Membership"}
            primaryAction={{
                content: 'Submit',
                onAction: addMembership,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Membership name"
                                        value={membershipName}
                                        onChange={handleMembershipNameChange}
                                        placeholder="Please enter Membership name "
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        label="Membership number"
                                        value={membershipNumber}
                                        onChange={handleMembershipNumberChange}
                                        placeholder="Please enter Membership number "
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #DE3618 solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageId(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Loader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Thumbnail source={NoteMinor} size="large" alt="Small document" />
                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <div style={{ color: 'gray' }}>Document size should be maximum 3MB.</div>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Validity Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>

                            <div>
                                <TextField
                                    label="Membership Description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Please enter Membership description"
                                    multiline={1}
                                />
                            </div>
                        </FormLayout.Group>
                        <div>{successAddMembershipDiv}</div>
                        <div>{errorAddMembershipDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal >
    );

    const doctorProfessionalMembership = (
        <Box>
            <Card>
                <Card.Header
                    title="Professional Membership"
                >
                    <Button onClick={handleToggle}>Add Professional Membership</Button>
                </Card.Header>
                {professionalMembershipList}
            </Card>
        </Box>
    );
    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title="Professional Membership Document" width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }
    return (
        <Box>
            {loadingMarkup}
            {doctorProfessionalMembership}
            {addProfessionalMembership}
            {docViewerComponent}
        </Box>
    );
}