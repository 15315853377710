import React from 'react';
import ReactDOM from 'react-dom';
import {AppProvider} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/dist/styles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import './style.css';
import { Provider } from 'react-redux';
import store from './redux/store/Store';

ReactDOM.render(
  <AppProvider i18n={{
    Polaris: {
      Avatar: {
        label: 'Avatar',
        labelWithInitials: 'Avatar with initials {initials}',
      },

    },
  }}
  features={{newDesignLanguage: true}}
  theme={theme}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </AppProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
